import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { apis } from '../../../api/aiblab';
import { FaAngleRight } from 'react-icons/fa';
import ReactPagination from '../../../components/ReactPagination';

function AccordionBody({ item }) {
  const [news, setNews] = useState({});

  const loadNewsById = () => {
    apis
      .getNewsById(item.newsId)
      .then((response) => {
        setNews(response);
      })
      .catch((error) => console.log(error.response));
  };

  useEffect(() => {
    loadNewsById();
  }, []);

  const contents = news.contents;

  return (
    <div className="accordion-body">
      <div dangerouslySetInnerHTML={{ __html: contents }}></div>
    </div>
  );
}

export default function EnNews() {
  const [keyword, setKeyword] = useState('');
  const [newsList, setNewsList] = useState([]);
  const [pageInfo, setPageInfo] = useState({
    pageNo: 1,
    pageSize: 10,
  });
  const { pageNo, pageSize } = pageInfo;

  const loadNewsList = () => {
    apis
      .getNewsList(pageNo, pageSize, keyword)
      .then((response) => {
        setNewsList(response.data.items);
        setPageInfo(response.data.pagination);
      })
      .catch((error) => console.log(error.response));
  };

  const handleChangePage = (pageNo) => {
    setPageInfo((prev) => ({
      ...prev,
      pageNo,
    }));
  };

  useEffect(() => {
    loadNewsList();
  }, [pageNo]);

  return (
    <>
      <div className="content_heading pg_pr">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="tit_xl">AIBLab News</h2>
              <ol className="breadcrumb">
                <li>Home</li>
                <li>PR</li>
                <li className="active">AIBLab News</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="tit_underline">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2 className="tit_lg">AIBLab News</h2>
                <p className="desc">This is the latest news from AIBLab</p>
              </div>
            </div>
          </div>
        </div>
        <div className="notice">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div id="newsAccordion">
                  {newsList.map((item, index) => (
                    <div className="accordion-item" key={item.newsId}>
                      <div className="accordion-header">
                        <a className="accordion-button collapsed" data-bs-target={`#newsArticle_${item.newsId}`} data-bs-toggle="collapse">
                          <p className="notice_num">{(pageNo - 1) * pageSize + (index + 1)}</p>
                          <p className="notice_tit">
                            <span>{item.title}</span>
                          </p>
                          <p className="notice_date">{item.createTime}</p>
                          <FaAngleRight />
                        </a>
                      </div>
                      <div id={`newsArticle_${item.newsId}`} className="accordion-collapse collapse" data-bs-parent="#newsAccordion">
                        <AccordionBody item={item} />
                      </div>
                    </div>
                  ))}
                </div>
                <ReactPagination pageInfo={pageInfo} handleChangePage={handleChangePage} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
