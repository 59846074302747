import React from 'react';
import { FaAngleDoubleDown } from 'react-icons/fa';
import { Link } from 'react-router-dom';

export default function Introduce() {
  return (
    <>
      <div className="content_heading bg_introduce">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="tit_xl">기업소개</h2>
              <ol className="breadcrumb">
                <li>Home</li>
                <li>회사소개</li>
                <li className="active">기업소개</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="section">
          <div className="container">
            <div className="row">
              <div className="col-12 tit_underline">
                <h2 className="tit_lg">아이브랩 소개</h2>
                <div className="desc">
                  <p>아이브랩은 인공지능, 사물인터넷 플랫폼 기술을 </p>
                  <p>활용하여 사람들이 보다 풍요로운 삶을 누릴 수 있도록</p>
                  <p>융합 기술개발에 선도 기업이 될 수 있도록 경쟁력을 키우며 다가올 미래를 준비하겠습니다.</p>
                </div>
              </div>
              <div className="col-12 img_area">
                <img src="/images/main_img-introduce.jpg" alt="회사소개 이미지" />
              </div>
              <div className="col-12 text_area">
                <h3 className="tit_md">아이브랩</h3>
                <div className="desc">
                  <p>(주)아이브랩은 AIoT 플랫폼 기반의 솔루션 및 서비스 개발을 전문으로 하는 기업입니다.</p>
                  <p>스마트 오피스, 스마트 헬스케어, 스마트 홈, 스마트 시티, 스마트 팩토리 등 다양한 분야의 기술과 경험을 활용하여 고객에게 최상의 솔루션과 서비스를 제공하고자 합니다.</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <table className="table_border_x">
                  <caption className="tit_md">COMPANY PROFILE</caption>
                  <tbody>
                    <tr>
                      <th scope="row">회사명</th>
                      <td>아이브랩</td>
                    </tr>
                    <tr>
                      <th scope="row">대표이사</th>
                      <td>서영주, 김용우</td>
                    </tr>
                    <tr>
                      <th scope="row">설립연도</th>
                      <td>2019년 1월</td>
                    </tr>
                    <tr>
                      <th scope="row">본사 위치</th>
                      <td>경기도 성남시 수정구 창업로 43 판교글로벌비즈센터 A701호</td>
                    </tr>
                    <tr>
                      <th scope="row">주요 서비스</th>
                      <td>사물인터넷 플랫폼</td>
                    </tr>
                    <tr>
                      <th scope="row">주요 사업</th>
                      <td>스마트 오피스, 사물인터넷 플랫폼 기반 솔루션 개발</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="row">
              <div className="col-12 ci">
                <h3 className="tit_md">CI</h3>
                <div className="img_area">
                  <p>
                    <img src="/images/ci__black.jpg" width="50%" alt="CI Black" />
                  </p>
                  <p>
                    <img src="/images/ci__cyan.jpg" width="50%" alt="CI Cyan" />
                  </p>
                </div>
                <div className="border_box_x">
                  <h4 className="tit_sm">아이브랩 CI</h4>
                  <p className="desc">
                    아이브랩의 AIB는 AI, IoT, Big Data, BlockChain을 의미하는 것으로 4차 산업혁명의 핵심기술을 이용하여 인류의 생활을 보다 편리하고 윤택하게 하고자 하는 비전을 담고 있습니다. 아이브랩 CI는 이러한 브랜드 성격을 담아 깨끗한 기업 이미지와 경쾌하고
                    스마트한 브랜드 시각 요소인 Cyan 컬러를 조합하여 다양한 매체에서 쉽게 활용 가능하도록 디자인 되었습니다.
                  </p>
                </div>
                <div className="btn_area">
                  <a href="/file/01. aiblab_logo.ai" className="btn btn_sm btn_primary" download={true}>
                    <strong>AI 다운로드</strong>
                    <FaAngleDoubleDown />
                  </a>
                  <a href="/file/aiblab_slogan_cyan.png" className="btn btn_sm btn_primary" download={true}>
                    <strong>PNG 다운로드</strong>
                    <FaAngleDoubleDown />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
