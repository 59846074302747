import React from 'react';
import { FaAngleDown, FaAngleUp, FaAngleRight, FaHome } from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';

export default function Aibworks() {
  const { state } = useLocation();

  return (
    <>
      <div className="content_heading bg_service">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="tit_xl">AIBworks</h2>
              <ol className="breadcrumb">
                <li>Home</li>
                <li>서비스</li>
                <li className="active">근태관리</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="nav">
          <ul id="aibworksTabs" className="nav-tabs">
            <li className="nav-item">
              <a className={`nav-link${state ? (state.activeTab === 'aibworks' ? ' active' : '') : ' active'}`} data-bs-target="#aibworks" data-bs-toggle="tab">
                근태관리
              </a>
            </li>
            <li className="nav-item">
              <a className={`nav-link${state ? (state.activeTab === 'smartToilet' ? ' active' : '') : ''}`} data-bs-target="#smartToilet" data-bs-toggle="tab">
                스마트 화장실
              </a>
            </li>
            <li className="nav-item">
              <a className={`nav-link${state ? (state.activeTab === 'monitoringEnv' ? ' active' : '') : ''}`} data-bs-target="#monitoringEnv" data-bs-toggle="tab">
                환경정보 모니터링
              </a>
            </li>
          </ul>
        </div>
        <div className="tab-content">
          <div id="aibworks" className={`tab-pane fade${state ? (state.activeTab === 'aibworks' ? ' show active' : '') : ' show active'}`}>
            <div className="container">
              <div className="row">
                <div className="col-12 tit_underline">
                  <h3 className="tit_lg">
                    <span className="sub_tit">2018 개정 근로기준법</span>
                    <span>주당 근로시간 52시간 근무제 도입</span>
                  </h3>
                  <div className="desc">
                    <p>
                      <span>주당 52시간 근무제를 안정적으로 도입하실 수 있도록 </span>
                      <span>아이브웍스 ‘근태관리’가 함께 하겠습니다.</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="section work_policy">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <img src="/images/aibworks_commute.png" width="600px" alt="52시간 근무제" />
                    <strong>주당 52시간 근무제</strong>
                  </div>
                  <div className="col-12 diagram">
                    <ul className="bar_list">
                      <li className="bar"></li>
                      <li className="bar">
                        <span className="flag">
                          <FaAngleDown />
                        </span>
                      </li>
                      <li className="bar">
                        <span className="flag">
                          <FaAngleUp />
                        </span>
                      </li>
                      <li className="bar">
                        <span className="flag">
                          <FaAngleDown />
                        </span>
                      </li>
                    </ul>
                    <ul className="desc_list">
                      <li className="flag_desc">
                        <p>2018.07</p>
                        <p>300인 이상 기업</p>
                      </li>
                      <li className="flag_desc">
                        <p>2020.01</p>
                        <p>50~300인 미만 기업</p>
                      </li>
                      <li className="flag_desc">
                        <p>2021.07</p>
                        <p>5~50인 미만 기업</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="section bg_g">
              <div className="container">
                <div className="row">
                  <div className="col-12 tit_underline">
                    <h3 className="tit_lg">사내 정책에 맞게 유연한 근무제 설정</h3>
                    <p className="desc">
                      <span>탄력근무제, 유연근무제, 시간선택제 등을 </span>
                      <span>사내에 도입하여 52시간 근무제를 </span>
                      <span>유연하게 대처할 수 있는 서비스 제공</span>
                    </p>
                  </div>
                  <div className="col-md-4">
                    <img src="/images/aibworks_commute_01.png" width="60%" alt="탄력 근무제" />
                    <h3 className="tit_sm">탄력 근무제</h3>
                  </div>
                  <div className="col-md-4">
                    <img src="/images/aibworks_commute_02.png" width="60%" alt="유연 근무제" />
                    <h3 className="tit_sm">유연 근무제</h3>
                  </div>
                  <div className="col-md-4">
                    <img src="/images/aibworks_commute_03.png" width="60%" alt="시간 선택제" />
                    <h3 className="tit_sm">시간 선택제</h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="section">
              <div className="container">
                <div className="row">
                  <div className="col-md-4">
                    <div className="thumb_gradient">
                      <a href="https://www.aibworks.com/">
                        <img src="/images/case_commute.png" alt="근태관리" />
                      </a>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <h3 className="tit_lg">14일 무료체험 시작!</h3>
                    <div className="desc">
                      <span>14일간 무료체험으로 근태관리 Basic의 모든 기능을 이용할 수 있습니다.</span>
                      <span>근태관리의 시작은 아이브웍스로! 지금 바로 시작하세요!</span>
                    </div>
                    <a className="more" href="http://www.aibworks.com/" target="_blank">
                      <FaHome />
                      <span>무료 이용하기</span>
                      <FaAngleRight />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="smartToilet" className={`tab-pane fade${state ? (state.activeTab === 'smartToilet' ? ' show active' : '') : ''}`}>
            <div className="container">
              <div className="row">
                <div className="col-12 tit_underline">
                  <h3 className="tit_lg">화장실 가기전에 앱으로 먼저 확인하세요!</h3>
                  <div className="desc">
                    <p>
                      <span>아이브랩의 AIBworks Toilet은 </span>
                      <span>스마트폰에 설치된 전용 애플리케이션을 통해</span>
                    </p>
                    <p>
                      <span>사무공간 내 화장실 사용 현황을 보여주는 서비스로 </span>
                      <span>화장실 사용 병목현상을 해결해줍니다.</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="section pt-0">
              <div className="container">
                <div className="row">
                  <div className="col-md-4">
                    <img src="/images/aibworks_toilet_01.png" width="70%" alt="로그인 화면" />
                    <h3 className="tit_sm">로그인</h3>
                    <div className="desc">
                      <p>기업 서비스 코드로</p>
                      <p>쉽게 로그인이 가능!</p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <img src="/images/aibworks_toilet_02.png" width="70%" alt="사용 현황 화면" />
                    <h3 className="tit_sm">사용 현황</h3>
                    <div className="desc">
                      <p>AIBworks Toilet은</p>
                      <p>업무의 귀중한 시간을 지켜드립니다!</p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <img src="/images/aibworks_toilet_03.png" width="70%" alt="설정 화면" />
                    <h3 className="tit_sm">설정</h3>
                    <div className="desc">
                      <p>내가 자주 가는 층의 화장실만</p>
                      <p>등록하여 사용현황 확인 가능!</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="section bg_g">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <h2 className="tit_lg">근무지 이탈로 발생되는 비용손실 최소화</h2>
                    <span className="desc">손실비용 환산시 연간 약 500만원 비용 발생</span>
                  </div>
                  <div className="col-12">
                    <img src="/images/aibworks_toilet.png" width="80%" alt="비용환산" />
                  </div>
                </div>
              </div>
            </div>
            <div className="section">
              <div className="container">
                <div className="row">
                  <div className="col-md-4">
                    <div className="thumb_gradient">
                      <img src="/images/case_toilet.png" alt="스마트 토일렛" />
                    </div>
                  </div>
                  <div className="col-md-8">
                    <h3 className="tit_lg">스마트 토일럿 이용</h3>
                    <p className="desc">
                      <span>LoRa 센서 및 게이트웨이를 활용하여 화장실 사용가능 유무를 </span>
                      <span>앱에서 확인할 수 있는 서비스입니다.</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="monitoringEnv" className={`tab-pane fade${state ? (state.activeTab === 'monitoringEnv' ? ' show active' : '') : ''}`}>
            <div className="container">
              <div className="row">
                <div className="col-12 tit_underline">
                  <h3 className="tit_lg">실시간 환경정보 모니터링으로 쾌적한 업무공간</h3>
                  <div className="desc">
                    <p>
                      <span>다양한 IoT센서를 통해 수집된 환경정보를 </span>
                      <span>분석하여 관리자에게 제공하며</span>
                    </p>
                    <p>
                      <span>직원에게 최적의 업무환경과 </span>
                      <span>생산성 향상, 안전한 업무환경을 제공합니다.</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="banner bg_environment">
              <div className="container">
                <div className="row">
                  <div className="col-lg-3 col-md-6">
                    <img src="/images/icon-temperature.png" width="40%" alt="온도" />
                    <h4 className="tit_md">온도</h4>
                    <p className="desc">
                      실시간 업무공간 온도 확인과
                      <br />
                      업무에 적합한 온도를 유지
                    </p>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <img src="/images/icon-humidity.png" width="40%" alt="습도" />
                    <h4 className="tit_md">습도</h4>
                    <p className="desc">
                      실시간 업무공간 습도 확인과
                      <br />
                      쾌적한 업무공간 유지
                    </p>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <img src="/images/icon-co2.png" width="40%" alt="CO2" />
                    <h4 className="tit_md">CO2</h4>
                    <p className="desc">
                      실시간 업무공간 CO2 확인과
                      <br />
                      위험 알림 서비스 제공
                    </p>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <img src="/images/icon-bulb.png" width="40%" alt="조도" />
                    <h4 className="tit_md">조도</h4>
                    <p className="desc">
                      실시간 업무공간 조도 확인과
                      <br />
                      편리한 조도 추천
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="section">
              <div className="container">
                <div className="row">
                  <div className="col-md-4">
                    <div className="thumb_gradient">
                      <img src="/images/case_environment.png" alt="환경정보 모니터링" />
                    </div>
                  </div>
                  <div className="col-md-8">
                    <h3 className="tit_lg">환경정보 모니터링 이용</h3>
                    <p className="desc">
                      <span>IoT 센서를 통해 사무실 환경을 최적화 하고 </span>
                      <span>쾌적한 환경으로 생산성을 향상 시킬수 있습니다.</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
