import React, { useEffect } from 'react';
import { Link, useLocation, useNavigation } from 'react-router-dom';
import { FaBars, FaAngleDown, FaCaretRight } from 'react-icons/fa';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { useLangContext } from '../context/AppContext';

export default function NavBar() {
  /* 메뉴 상단 고정 */
  let scrolled = false;
  window.addEventListener('scroll', () => {
    const navBar = document.getElementById('navBar');
    let scrollLocation = document.documentElement.scrollTop;
    if (120 < scrollLocation && !scrolled) {
      navBar.classList.add('sticky', 'animate__animated', 'animate__fadeInDown');
      scrolled = true;
    }
    if (120 >= scrollLocation && scrolled) {
      navBar.classList.remove('sticky', 'animate__animated', 'animate__fadeInDown');
      scrolled = false;
    }
  });

  const { lang } = useLangContext();

  const hideOffCanvas = () => {
    const targetOffCanvas = document.getElementById('staticBackdrop');
    targetOffCanvas.hideOffCanvas();
  };

  return lang === 'ko-KR' ? (
    <>
      <nav id="navBar" className="navbar" role="navigation">
        <div className="container">
          <Link to={lang === 'ko-KR' ? '/' : '/en'} className="navbar-brand">
            <img src="/images/logo.png" alt="아이브랩" />
          </Link>
          <ul className="navbar-nav">
            <li className="dropdown">
              <a className="nav-link" data-bs-toggle="dropdown">
                회사소개
                <FaAngleDown />
              </a>
              <div className="menu_wrapper">
                <div className="menu_inner">
                  <ul className="dropdown-menu">
                    <li>
                      <Link className="dropdown-item" to="/company/introduce">
                        기업소개
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/company/vision">
                        기업비전
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/company/greeting">
                        CEO Greeting
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/company/history">
                        회사연혁
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/company/location">
                        오시는길
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
            <li className="dropdown mega_fw">
              <a className="nav-link" data-bs-toggle="dropdown">
                <span>제품</span>
                <FaAngleDown />
              </a>
              <div className="menu_wrapper">
                <div className="menu_inner">
                  <ul className="dropdown-menu">
                    <li className="row">
                      <div className="col-sm-6">
                        <ul>
                          <li className="dropdown-header">AIBLab Platform</li>
                          <li>
                            <Link className="dropdown-item" to="/platform/iot">
                              IoT Platform
                            </Link>
                          </li>
                          <li>
                            <Link className="dropdown-item" to="/platform/aiBigdata">
                              AI·Bigdata Platform
                            </Link>
                          </li>
                        </ul>
                      </div>
                      <div className="col-sm-6">
                        <ul>
                          <li className="dropdown-header">구축사례</li>
                          <li>
                            <Link className="dropdown-item" to="/case/smartOffice">
                              스마트 오피스
                            </Link>
                          </li>
                          <li>
                            <Link className="dropdown-item" to="/case/smartHome">
                              스마트 홈
                            </Link>
                          </li>
                          <li>
                            <Link className="dropdown-item" to="/case/smartHealthcare">
                              스마트 헬스케어
                            </Link>
                          </li>
                          <li>
                            <Link className="dropdown-item" to="/case/smartBuilding">
                              스마트 빌딩관리
                            </Link>
                          </li>
                          <li>
                            <Link className="dropdown-item" to="/case/smartCar">
                              스마트 차량관리
                            </Link>
                          </li>
                          <li>
                            <Link className="dropdown-item" to="/case/smartFactory">
                              스마트 팩토리
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
            <li className="dropdown">
              <a className="nav-link" data-bs-toggle="dropdown">
                <span>서비스</span>
                <FaAngleDown />
              </a>
              <div className="menu_wrapper">
                <div className="menu_inner">
                  <ul className="dropdown-menu">
                    <li>
                      <Link className="dropdown-item" to="/service/aibworks">
                        AIBworks
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/service/aibcare">
                        AIBCare
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/service/maintenance">
                        IT·운영서비스
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/service/smartWater">
                        스마트워터 AI
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
            {/* <li className="dropdown new">
              <span className="text">NEW</span>
              <a className="nav-link" data-bs-toggle="dropdown">
                <span>지원사업</span>
                <FaAngleDown />
              </a>
              <div className="menu_wrapper">
                <div className="menu_inner">
                  <ul className="dropdown-menu">
                    <li>
                      <Link className="dropdown-item" to="/voucher/aiVoucher">
                        AI 바우처
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/voucher/dataVoucher">
                        데이터 바우처
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </li> */}
            <li className="dropdown">
              <a className="nav-link" data-bs-toggle="dropdown">
                <span>PR</span>
                <FaAngleDown />
              </a>
              <div className="menu_wrapper">
                <div className="menu_inner">
                  <ul className="dropdown-menu">
                    <li>
                      <Link className="dropdown-item" to="/info/news">
                        아이브랩 소식
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/info/media">
                        언론홍보
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
            <li className="dropdown">
              <a className="nav-link" data-bs-toggle="dropdown">
                <span>채용</span>
                <FaAngleDown />
              </a>
              <div className="menu_wrapper">
                <div className="menu_inner">
                  <ul className="dropdown-menu">
                    <li>
                      <Link className="dropdown-item" to="/recruit/culture">
                        아이브랩 문화
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/recruit/recruitment">
                        채용공고
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>
          <button type="button" className="navbar-toggler" data-bs-toggle="offcanvas" data-bs-target="#staticBackdrop">
            <FaBars />
          </button>
        </div>
      </nav>
      <div id="staticBackdrop" className="offcanvas offcanvas-start" data-bs-backdrop="static" tabIndex="-1">
        <div className="offcanvas-header">
          <h5 id="staticBackdropLabel" className="offcanvas-title">
            아이브랩
          </h5>
          <button type="button" className="btn-close" data-bs-dismiss="offcanvas" tabIndex="-1">
            <AiOutlineCloseCircle />
          </button>
        </div>
        <div className="offcanvas-body">
          <ul className="snb_pc">
            <li>
              <h2 className="tit_sm">회사소개</h2>
              <ul className="depth_02">
                <li>
                  <Link to="/company/introduce" onClick={hideOffCanvas}>
                    기업소개
                  </Link>
                </li>
                <li>
                  <Link to="/company/vision" onClick={hideOffCanvas}>
                    기업비전
                  </Link>
                </li>
                <li>
                  <Link to="/company/greeting" onClick={hideOffCanvas}>
                    CEO Greeting
                  </Link>
                </li>
                <li>
                  <Link to="/company/history" onClick={hideOffCanvas}>
                    회사연혁
                  </Link>
                </li>
                <li>
                  <Link to="/company/location" onClick={hideOffCanvas}>
                    오시는길
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <h2 className="tit_sm">제품</h2>
              <ul className="depth_02">
                <li className="sub_tit">AIBLab Platform</li>
                <li>
                  <Link to="/platform/iot" onClick={hideOffCanvas}>
                    IoT Platform
                  </Link>
                </li>
                <li>
                  <Link to="/platform/aiBigdata" onClick={hideOffCanvas}>
                    AI·Bigdata Platform
                  </Link>
                </li>
                <li className="sub_tit">구축사례</li>
                <li>
                  <Link to="/case/smartOffice" onClick={hideOffCanvas}>
                    스마트 오피스
                  </Link>
                </li>
                <li>
                  <Link to="/case/smartHome" onClick={hideOffCanvas}>
                    스마트 홈
                  </Link>
                </li>
                <li>
                  <Link to="/case/smartHealthcare" onClick={hideOffCanvas}>
                    스마트 헬스케어
                  </Link>
                </li>
                <li>
                  <Link to="/case/smartBuilding" onClick={hideOffCanvas}>
                    스마트 빌딩관리
                  </Link>
                </li>
                <li>
                  <Link to="/case/smartCar" onClick={hideOffCanvas}>
                    스마트 차량관리
                  </Link>
                </li>
                <li>
                  <Link to="/case/smartFactory" onClick={hideOffCanvas}>
                    스마트 팩토리
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <h2 className="tit_sm">서비스</h2>
              <ul className="depth_02">
                <li>
                  <Link to="/service/aibworks" onClick={hideOffCanvas}>
                    AIBworks
                  </Link>
                </li>
                <li>
                  <Link to="/service/aibcare" onClick={hideOffCanvas}>
                    AIBCare
                  </Link>
                </li>
                <li>
                  <Link to="/service/maintenance" onClick={hideOffCanvas}>
                    IT·운영서비스
                  </Link>
                </li>
                <li>
                  <Link to="/service/smartWater" onClick={hideOffCanvas}>
                    스마트워터 AI
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <h2 className="tit_sm">지원사업</h2>
              <ul className="depth_02">
                <li>
                  <Link to="/voucher/aiVoucher" onClick={hideOffCanvas}>
                    AI 바우처
                  </Link>
                </li>
                <li>
                  <Link to="/voucher/dataVoucher" onClick={hideOffCanvas}>
                    데이터 바우처
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <h2 className="tit_sm">PR</h2>
              <ul className="depth_02">
                <li>
                  <Link to="/info/news" onClick={hideOffCanvas}>
                    아이브랩 소식
                  </Link>
                </li>
                <li>
                  <Link to="/info/media" onClick={hideOffCanvas}>
                    언론홍보
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <h2 className="tit_sm">채용</h2>
              <ul className="depth_02">
                <li>
                  <Link to="/recruit/culture" onClick={hideOffCanvas}>
                    아이브랩 문화
                  </Link>
                </li>
                <li>
                  <Link to="/recruit/recruitment" onClick={hideOffCanvas}>
                    채용공고
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
          <ul className="snb_mb accordion">
            <div className="accordion-item">
              <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#mobileCompany">
                <span>회사소개</span>
                <FaCaretRight />
              </button>
              <div id="mobileCompany" className="accordion-collapse collapse">
                <ul>
                  <li>
                    <Link to="/company/introduce" onClick={hideOffCanvas}>
                      기업소개
                    </Link>
                  </li>
                  <li>
                    <Link to="/company/vision" onClick={hideOffCanvas}>
                      기업비전
                    </Link>
                  </li>
                  <li>
                    <Link to="/company/greeting" onClick={hideOffCanvas}>
                      CEO Greeting
                    </Link>
                  </li>
                  <li>
                    <Link to="/company/history" onClick={hideOffCanvas}>
                      회사연혁
                    </Link>
                  </li>
                  <li>
                    <Link to="/company/location" onClick={hideOffCanvas}>
                      오시는길
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="accordion-item">
              <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#mobileProduct">
                <span>제품</span>
                <FaCaretRight />
              </button>
              <div id="mobileProduct" className="accordion-collapse collapse">
                <ul>
                  <li>
                    <span className="sub_tit">AIBLab Platform</span>
                  </li>
                  <li>
                    <Link to="/platform/iot" onClick={hideOffCanvas}>
                      IoT Platform
                    </Link>
                  </li>
                  <li>
                    <Link to="/platform/aiBigdata" onClick={hideOffCanvas}>
                      AI·Bigdata Platform
                    </Link>
                  </li>
                  <li>
                    <span className="sub_tit">구축사례</span>
                  </li>
                  <li>
                    <Link to="/case/smartOffice" onClick={hideOffCanvas}>
                      스마트 오피스
                    </Link>
                  </li>
                  <li>
                    <Link to="/case/smartHome" onClick={hideOffCanvas}>
                      스마트 홈
                    </Link>
                  </li>
                  <li>
                    <Link to="/case/smartHealthcare" onClick={hideOffCanvas}>
                      스마트 헬스케어
                    </Link>
                  </li>
                  <li>
                    <Link to="/case/smartBuilding" onClick={hideOffCanvas}>
                      스마트 빌딩관리
                    </Link>
                  </li>
                  <li>
                    <Link to="/case/smartCar" onClick={hideOffCanvas}>
                      스마트 차량관리
                    </Link>
                  </li>
                  <li>
                    <Link to="/case/smartFactory" onClick={hideOffCanvas}>
                      스마트 팩토리
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="accordion-item">
              <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#mobileService">
                <span>서비스</span>
                <FaCaretRight />
              </button>
              <div id="mobileService" className="accordion-collapse collapse">
                <ul>
                  <li>
                    <Link to="/service/aibworks" onClick={hideOffCanvas}>
                      AIBworks
                    </Link>
                  </li>
                  <li>
                    <Link to="/service/aibcare" onClick={hideOffCanvas}>
                      AIBCare
                    </Link>
                  </li>
                  <li>
                    <Link to="/service/maintenance" onClick={hideOffCanvas}>
                      IT·운영서비스
                    </Link>
                  </li>
                  <li>
                    <Link to="/service/smartWater" onClick={hideOffCanvas}>
                      스마트워터 AI
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="accordion-item">
              <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#mobileVoucher">
                <span>지원사업</span>
                <FaCaretRight />
              </button>
              <div id="mobileVoucher" className="accordion-collapse collapse">
                <ul>
                  <li>
                    <Link to="/voucher/aiVoucher" onClick={hideOffCanvas}>
                      AI 바우처
                    </Link>
                  </li>
                  <li>
                    <Link to="/voucher/dataVoucher" onClick={hideOffCanvas}>
                      데이터 바우처
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="accordion-item">
              <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#mobileInfo">
                <span>PR</span>
                <FaCaretRight />
              </button>
              <div id="mobileInfo" className="accordion-collapse collapse">
                <ul>
                  <li>
                    <Link to="/info/news" onClick={hideOffCanvas}>
                      아이브랩 소식
                    </Link>
                  </li>
                  <li>
                    <Link to="/info/media" onClick={hideOffCanvas}>
                      언론홍보
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="accordion-item">
              <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#mobileIncruit">
                <span>채용</span>
                <FaCaretRight />
              </button>
              <div id="mobileIncruit" className="accordion-collapse collapse">
                <ul>
                  <li>
                    <Link to="/recruit/culture" onClick={hideOffCanvas}>
                      아이브랩 문화
                    </Link>
                  </li>
                  <li>
                    <Link to="/recruit/recruitment" onClick={hideOffCanvas}>
                      채용공고
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </ul>
        </div>
      </div>
    </>
  ) : (
    <>
      <nav id="navBar" className="navbar" role="navigation">
        <div className="container">
          <Link to={lang === 'ko-KR' ? '/' : '/en'} className="navbar-brand">
            <img src="/images/logo.png" alt="AIBLab" />
          </Link>
          <ul className="navbar-nav">
            <li className="dropdown">
              <a className="nav-link" data-bs-toggle="dropdown">
                Introduction
                <FaAngleDown />
              </a>
              <div className="menu_wrapper">
                <div className="menu_inner">
                  <ul className="dropdown-menu">
                    <li>
                      <Link className="dropdown-item" to="/en/company/introduce">
                        AIBLab
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/en/company/vision">
                        Vision
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/en/company/greeting">
                        CEO Greeting
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/en/company/history">
                        History
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/en/company/location">
                        Location Guide
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
            <li className="dropdown mega_fw">
              <a className="nav-link" data-bs-toggle="dropdown">
                <span>Product</span>
                <FaAngleDown />
              </a>
              <div className="menu_wrapper">
                <div className="menu_inner">
                  <ul className="dropdown-menu">
                    <li className="row">
                      <div className="col-sm-6">
                        <ul>
                          <li className="dropdown-header">AIBLab Platform</li>
                          <li>
                            <Link className="dropdown-item" to="/en/platform/iot">
                              IoT Platform
                            </Link>
                          </li>
                          <li>
                            <Link className="dropdown-item" to="/en/platform/aiBigdata">
                              AI·Bigdata Platform
                            </Link>
                          </li>
                        </ul>
                      </div>
                      <div className="col-sm-6">
                        <ul>
                          <li className="dropdown-header">Product</li>
                          <li>
                            <Link className="dropdown-item" to="/en/case/smartOffice">
                              Smart Office
                            </Link>
                          </li>
                          <li>
                            <Link className="dropdown-item" to="/en/case/smartHome">
                              Smart Home
                            </Link>
                          </li>
                          <li>
                            <Link className="dropdown-item" to="/en/case/smartHealthcare">
                              Smart Healthcare
                            </Link>
                          </li>
                          <li>
                            <Link className="dropdown-item" to="/en/case/smartBuilding">
                              Smart Building
                            </Link>
                          </li>
                          <li>
                            <Link className="dropdown-item" to="/en/case/smartCar">
                              Smart Car
                            </Link>
                          </li>
                          <li>
                            <Link className="dropdown-item" to="/en/case/smartFactory">
                              Smart Factory
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
            <li className="dropdown">
              <a className="nav-link" data-bs-toggle="dropdown">
                <span>Service</span>
                <FaAngleDown />
              </a>
              <div className="menu_wrapper">
                <div className="menu_inner">
                  <ul className="dropdown-menu">
                    <li>
                      <Link className="dropdown-item" to="/en/service/aibworks">
                        AIBworks
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/en/service/aibcare">
                        AIBCare
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/en/service/maintenance">
                        IT·Operation service
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
            <li className="dropdown">
              <a className="nav-link" data-bs-toggle="dropdown">
                <span>PR</span>
                <FaAngleDown />
              </a>
              <div className="menu_wrapper">
                <div className="menu_inner">
                  <ul className="dropdown-menu">
                    <li>
                      <Link className="dropdown-item" to="/en/info/news">
                        AIBLab News
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/en/info/media">
                        News
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>
          <button type="button" className="navbar-toggler" data-bs-toggle="offcanvas" data-bs-target="#staticBackdrop">
            <FaBars />
          </button>
        </div>
      </nav>
      <div id="staticBackdrop" className="offcanvas offcanvas-start" data-bs-backdrop="static" tabIndex="-1">
        <div className="offcanvas-header">
          <h5 id="staticBackdropLabel" className="offcanvas-title">
            AIBLab
          </h5>
          <button type="button" className="btn-close" data-bs-dismiss="offcanvas" tabIndex="-1">
            <AiOutlineCloseCircle />
          </button>
        </div>
        <div className="offcanvas-body">
          <ul className="snb_pc">
            <li>
              <h2 className="tit_sm">Introduction</h2>
              <ul className="depth_02">
                <li>
                  <Link to="/en/company/introduce" onClick={hideOffCanvas}>
                    AIBLab
                  </Link>
                </li>
                <li>
                  <Link to="/en/company/vision" onClick={hideOffCanvas}>
                    Vision
                  </Link>
                </li>
                <li>
                  <Link to="/en/company/greeting" onClick={hideOffCanvas}>
                    CEO Greeting
                  </Link>
                </li>
                <li>
                  <Link to="/en/company/history" onClick={hideOffCanvas}>
                    History
                  </Link>
                </li>
                <li>
                  <Link to="/en/company/location" onClick={hideOffCanvas}>
                    Location Guide
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <h2 className="tit_sm">Product</h2>
              <ul className="depth_02">
                <li className="sub_tit">AIBLab Platform</li>
                <li>
                  <Link to="/en/platform/iot" onClick={hideOffCanvas}>
                    IoT Platform
                  </Link>
                </li>
                <li>
                  <Link to="/en/platform/aiBigdata" onClick={hideOffCanvas}>
                    AI·Bigdata Platform
                  </Link>
                </li>
                <li className="sub_tit">Product</li>
                <li>
                  <Link to="/en/case/smartOffice" onClick={hideOffCanvas}>
                    Smart Office
                  </Link>
                </li>
                <li>
                  <Link to="/en/case/smartHome" onClick={hideOffCanvas}>
                    Smart Home
                  </Link>
                </li>
                <li>
                  <Link to="/en/case/smartHealthcare" onClick={hideOffCanvas}>
                    Smart Healthcare
                  </Link>
                </li>
                <li>
                  <Link to="/en/case/smartBuilding" onClick={hideOffCanvas}>
                    Smart Building
                  </Link>
                </li>
                <li>
                  <Link to="/en/case/smartCar" onClick={hideOffCanvas}>
                    Smart Car
                  </Link>
                </li>
                <li>
                  <Link to="/en/case/smartFactory" onClick={hideOffCanvas}>
                    Smart Factory
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <h2 className="tit_sm">Service</h2>
              <ul className="depth_02">
                <li>
                  <Link to="/en/service/aibworks" onClick={hideOffCanvas}>
                    AIBworks
                  </Link>
                </li>
                <li>
                  <Link to="/en/service/aibcare" onClick={hideOffCanvas}>
                    AIBCare
                  </Link>
                </li>
                <li>
                  <Link to="/en/service/maintenance" onClick={hideOffCanvas}>
                    IT·Operation service
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <h2 className="tit_sm">PR</h2>
              <ul className="depth_02">
                <li>
                  <Link to="/en/info/news" onClick={hideOffCanvas}>
                    AIBLab News
                  </Link>
                </li>
                <li>
                  <Link to="/en/info/media" onClick={hideOffCanvas}>
                    News
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
          <ul className="snb_mb accordion">
            <div className="accordion-item">
              <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#mobileCompany">
                <span>Introduction</span>
                <FaCaretRight />
              </button>
              <div id="mobileCompany" className="accordion-collapse collapse">
                <ul>
                  <li>
                    <Link to="/en/company/introduce" onClick={hideOffCanvas}>
                      AIBLab
                    </Link>
                  </li>
                  <li>
                    <Link to="/en/company/vision" onClick={hideOffCanvas}>
                      Vision
                    </Link>
                  </li>
                  <li>
                    <Link to="/en/company/greeting" onClick={hideOffCanvas}>
                      CEO Greeting
                    </Link>
                  </li>
                  <li>
                    <Link to="/en/company/history" onClick={hideOffCanvas}>
                      History
                    </Link>
                  </li>
                  <li>
                    <Link to="/en/company/location" onClick={hideOffCanvas}>
                      Location Guide
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="accordion-item">
              <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#mobileProduct">
                <span>Product</span>
                <FaCaretRight />
              </button>
              <div id="mobileProduct" className="accordion-collapse collapse">
                <ul>
                  <li>
                    <span className="sub_tit">AIBLab Platform</span>
                  </li>
                  <li>
                    <Link to="/en/platform/iot" onClick={hideOffCanvas}>
                      IoT Platform
                    </Link>
                  </li>
                  <li>
                    <Link to="/en/platform/aiBigdata" onClick={hideOffCanvas}>
                      AI·Bigdata Platform
                    </Link>
                  </li>
                  <li>
                    <span className="sub_tit">Product</span>
                  </li>
                  <li>
                    <Link to="/en/case/smartOffice" onClick={hideOffCanvas}>
                      Smart Office
                    </Link>
                  </li>
                  <li>
                    <Link to="/en/case/smartHome" onClick={hideOffCanvas}>
                      Smart Home
                    </Link>
                  </li>
                  <li>
                    <Link to="/en/case/smartHealthcare" onClick={hideOffCanvas}>
                      Smart Healthcare
                    </Link>
                  </li>
                  <li>
                    <Link to="/en/case/smartBuilding" onClick={hideOffCanvas}>
                      Smart Building
                    </Link>
                  </li>
                  <li>
                    <Link to="/en/case/smartCar" onClick={hideOffCanvas}>
                      Smart Car
                    </Link>
                  </li>
                  <li>
                    <Link to="/en/case/smartFactory" onClick={hideOffCanvas}>
                      Smart Factory
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="accordion-item">
              <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#mobileService">
                <span>Service</span>
                <FaCaretRight />
              </button>
              <div id="mobileService" className="accordion-collapse collapse">
                <ul>
                  <li>
                    <Link to="/en/service/aibworks" onClick={hideOffCanvas}>
                      AIBworks
                    </Link>
                  </li>
                  <li>
                    <Link to="/en/service/aibcare" onClick={hideOffCanvas}>
                      AIBCare
                    </Link>
                  </li>
                  <li>
                    <Link to="/en/service/maintenance" onClick={hideOffCanvas}>
                      IT·Operation service
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="accordion-item">
              <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#mobileInfo">
                <span>PR</span>
                <FaCaretRight />
              </button>
              <div id="mobileInfo" className="accordion-collapse collapse">
                <ul>
                  <li>
                    <Link to="/en/info/news" onClick={hideOffCanvas}>
                      AIBLab News
                    </Link>
                  </li>
                  <li>
                    <Link to="/en/info/media" onClick={hideOffCanvas}>
                      News
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </ul>
        </div>
      </div>
    </>
  );
}
