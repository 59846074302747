import axios from 'axios';

const httpClient = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api`,
});

const setContentLanguage = async (lang) => {
  httpClient.defaults.headers.common['Content-Language'] = lang;
};

const lang = localStorage.getItem('language') || 'ko-KR';
setContentLanguage(lang);

/* --------------------
# 뉴스
-------------------- */
const getNewsList = async (pageNo, pageSize, keyword) => {
  const response = await httpClient.get('/news/user', {
    params: {
      pageNo,
      pageSize,
      keyword,
    },
  });
  return response;
};

const getNewsById = async (newsId) => {
  const response = await httpClient.get(`news/${newsId}`);
  return response.data.data;
};

/* --------------------
# 언론홍보
-------------------- */
const getMediaList = async (pageNo, pageSize, keyword) => {
  const response = await httpClient.get('promotion/user', {
    params: {
      pageNo,
      pageSize,
      keyword,
    },
  });
  return response;
};

const getMediaById = async (promotionId) => {
  const response = await httpClient.get(`/promotion/${promotionId}`);
  return response.data.data;
};

/* --------------------
# 채용
-------------------- */
const getRecruitList = async (pageNo, pageSize, keyword) => {
  const response = await httpClient.get('announcement/user', {
    params: {
      pageNo,
      pageSize,
      keyword,
    },
  });
  return response;
};

const getRecruitById = async (announcementId) => {
  const response = await httpClient.get(`/announcement/${announcementId}`);
  return response.data.data;
};

export const apis = {
  setContentLanguage,
  getNewsList,
  getNewsById,
  getMediaList,
  getMediaById,
  getRecruitList,
  getRecruitById,
};
