import React from 'react';
import { Link } from 'react-router-dom';

export default function Aibcare() {
  return (
    <>
      <div className="content_heading bg_service">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="tit_xl">AIBCare</h2>
              <ol className="breadcrumb">
                <li>Home</li>
                <li>서비스</li>
                <li className="active">비대면 체온변화 관리 서비스</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="tit_underline">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h3 className="tit_lg">AIBCare</h3>
                <p className="desc">
                  <span>1회성 체온 측정관리를 벗어나 </span>
                  <span>AIBCare로 비대면 체온변화를 관리하세요</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="section bg_g">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="ratio ratio-16x9">
                  <iframe src="https://www.youtube.com/embed/geuOFNYdCfc" allowFullScreen></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section">
          <div className="container">
            <div className="row">
              <div className="col-12 tit_underline">
                <h3 className="tit_lg">아이브케어 서비스</h3>
                <p className="desc">비접촉식 체온 변화관리 및 인공지능 분석 서비스 제공</p>
              </div>
              <div className="col-12">
                <img src="/images/aibcare.png" width="80%" alt="비접촉식 체온 변화관리" />
              </div>
            </div>
          </div>
        </div>
        <div className="section bg_g">
          <div className="container">
            <div className="row">
              <div className="col-12 tit_underline">
                <h3 className="tit_lg">아이브케어 적용 분야</h3>
                <p className="desc">
                  <span>아이브케어는 다양한 분야에서 </span>
                  <span>안전하고 쾌적한 환경을 만들어 갑니다</span>
                </p>
              </div>
              <div className="col-12 row">
                <div className="col-lg-3 col-md-6">
                  <img src="/images/aibcare_01.png" width="60%" alt="사무실" />
                  <h4 className="tit_sm">사무실</h4>
                </div>
                <div className="col-lg-3 col-md-6">
                  <img src="/images/aibcare_02.png" width="60%" alt="크루즈선" />
                  <h4 className="tit_sm">크루즈선</h4>
                </div>
                <div className="col-lg-3 col-md-6">
                  <img src="/images/aibcare_03.png" width="60%" alt="공장" />
                  <h4 className="tit_sm">대규모 제조업</h4>
                </div>
                <div className="col-lg-3 col-md-6">
                  <img src="/images/aibcare_04.png" width="60%" alt="학교" />
                  <h4 className="tit_sm">학교</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
