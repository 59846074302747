import React from 'react';
import { Link } from 'react-router-dom';

export default function SmartBuilding() {
  return (
    <>
      <div className="content_heading bg_product">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="tit_xl">스마트 빌딩관리</h2>
              <ol className="breadcrumb">
                <li>Home</li>
                <li>솔루션</li>
                <li className="active">스마트 빌딩관리</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="tit_underline">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2 className="tit_lg">Smart Building</h2>
                <p className="desc">IoT 기반 스마트 빌딩 시스템</p>
              </div>
            </div>
          </div>
        </div>
        <div className="section bg_g">
          <div className="container">
            <div className="row">
              <div className="col-12 img_area">
                <img src="/images/main_img-building.jpg" alt="Smart Building" />
              </div>
              <div className="col-12 row text_area">
                <h3 className="col-lg-4 tit_md">Smart Building</h3>
                <p className="col-lg-8 desc">보안, 자동제어, 빌딩 관리 시스템을 통합하여, 스마트한 빌딩운영 및 관리를 실현하고 건물 운영 비용 절감을 제공합니다.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="section landing">
          <div className="container">
            <div className="row">
              <div className="col-md-6 img_area">
                <img src="/images/building_landing_01.jpg" alt="빌딩 자동 블라인드 제어" />
              </div>
              <div className="col-md-6 text_area">
                <h4 className="tit_area">
                  <p className="numbering">
                    <span>01</span>
                    <small>Features</small>
                  </p>
                  <p className="tit_lg">빌딩 자동 블라인드 제어 서비스</p>
                </h4>
                <ul className="dash_list">
                  <li>빌딩 내 블라인드 장치 자동 제어 가능</li>
                  <li>블라인드 자동제어로 사무실 내 조명, 채광, 보안관리</li>
                  <li>버튼 하나로 130여개 회의실 자동 제어 가능</li>
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 img_area">
                <img src="/images/building_landing_02.jpg" alt="빌딩 자동 온도 제어" />
              </div>
              <div className="col-md-6 text_area">
                <h4 className="tit_area">
                  <p className="numbering">
                    <span>02</span>
                    <small>Features</small>
                  </p>
                  <p className="tit_lg">빌딩 자동 온도 제어 서비스</p>
                </h4>
                <ul className="dash_list">
                  <li>적정 온도와 습도를 유지해 쾌적한 사무환경을 지속할 수 있도록 지원</li>
                  <li>자동 제어 기능으로 운영 비용 절감</li>
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 img_area">
                <img src="/images/building_landing_03.jpg" alt="실시간 모니터링" />
              </div>
              <div className="col-md-6 text_area">
                <h4 className="tit_area">
                  <p className="numbering">
                    <span>03</span>
                    <small>Features</small>
                  </p>
                  <p className="tit_lg">편리한 실시간 모니터링</p>
                </h4>
                <ul className="dash_list">
                  <li>관리자페이지를 통한 130개 이상의 회의실 동시 제어 가능</li>
                  <li>타블렛, 앱을 통한 근거리 제어 가능</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="section bg_g">
          <div className="container">
            <div className="row">
              <div className="col-12 tit_underline">
                <h2 className="tit_md">주요기능</h2>
              </div>
              <div className="col-lg-3 col-6">
                <div className="img_area">
                  <img src="/images/building-icon_01.png" width="30%" alt="실시간 모니터링" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">실시간 모니터링</h5>
                  <p className="desc">
                    타블렛과 웹, 앱을 통한
                    <br />
                    편리한 제어
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="img_area">
                  <img src="/images/building-icon_02.png" width="30%" alt="실시간 온습도 제어" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">실시간 온습도 제어</h5>
                  <p className="desc">쾌적한 근무 환경</p>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="img_area">
                  <img src="/images/building-icon_03.png" width="30%" alt="자동 블라인드 제어" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">자동 블라인드 제어</h5>
                  <p className="desc">원버튼 편리한 제어</p>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="img_area">
                  <img src="/images/building-icon_04.png" width="30%" alt="빌딩 운영 절감" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">빌딩 운영 절감</h5>
                  <p className="desc">자동화 시스템으로 운영 절감</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-6">
                <div className="thumb_gradient">
                  <Link to="/case/smartOffice">
                    <img src="/images/case_office.png" alt="스마트 오피스" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-6">
                <div className="thumb_gradient">
                  <Link to="/case/smartHome">
                    <img src="/images/case_home.png" alt="스마트 홈" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-6">
                <div className="thumb_gradient">
                  <Link to="/case/smartHealthcare">
                    <img src="/images/case_healthcare.png" alt="스마트 헬스케어" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-6">
                <div className="thumb_gradient">
                  <Link to="/case/smartBuilding">
                    <img src="/images/case_building.png" alt="스마트 빌딩" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-6">
                <div className="thumb_gradient">
                  <Link to="/case/smartCar">
                    <img src="/images/case_car.png" alt="스마트 카" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-6">
                <div className="thumb_gradient">
                  <Link to="/case/smartFactory">
                    <img src="/images/case_factory.png" alt="스마트 팩토리" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
