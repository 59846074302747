import React from 'react';
import styles from './ToTop.module.css';
import { FaAngleDoubleUp } from 'react-icons/fa';

export default function ToTop() {
  document.addEventListener('scroll', () => {
    const scrollToTopBtn = document.querySelector('#scrollToTopBtn');
    if (document.documentElement.scrollTop !== 0) {
      scrollToTopBtn.classList.add(`${styles.show}`);
    } else {
      scrollToTopBtn.classList.remove(`${styles.show}`);
    }
  });

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <button id="scrollToTopBtn" className={styles.scroll_to_top} onClick={scrollToTop}>
      <FaAngleDoubleUp />
    </button>
  );
}
