import React from 'react';
import { Link } from 'react-router-dom';

export default function EnSmartCar() {
  return (
    <>
      <div className="content_heading bg_product">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="tit_xl">Smart Car</h2>
              <ol className="breadcrumb">
                <li>Home</li>
                <li>Solution</li>
                <li className="active">Smart Car</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="tit_underline">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2 className="tit_lg">Smart Car</h2>
                <p className="desc">Same car, new experience makes your car smarter!</p>
              </div>
            </div>
          </div>
        </div>
        <div className="section bg_g">
          <div className="container">
            <div className="row">
              <div className="col-12 img_area">
                <img src="/images/main_img-car.jpg" alt="Smart Car" />
              </div>
              <div className="col-12 row text_area">
                <h3 className="col-lg-4 tit_md">Smart Car</h3>
                <p className="col-lg-8 desc">Convenient and smart car life with AIBLab. The AIBLab provides the key features of the connected car service you need.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="section landing">
          <div className="container">
            <div className="row">
              <div className="col-md-6 img_area">
                <img src="/images/car_landing_01.jpg" alt="Eco driving" />
              </div>
              <div className="col-md-6 text_area">
                <h4 className="tit_area">
                  <p className="numbering">
                    <span>01</span>
                    <small>Features</small>
                  </p>
                  <p className="tit_lg">Driving habit analysis for Eco driving</p>
                </h4>
                <ul className="dash_list">
                  <li>Check my driving habits through information such as sudden acceleration and sudden stop</li>
                  <li>Driving score provided</li>
                  <li>Fuel economy management</li>
                  <li>NO₂ emissions check (to be supported)</li>
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 img_area">
                <img src="/images/car_landing_02.jpg" alt="vehicle diagnostic function" />
              </div>
              <div className="col-md-6 text_area">
                <h4 className="tit_area">
                  <p className="numbering">
                    <span>02</span>
                    <small>Features</small>
                  </p>
                  <p className="tit_lg">Your virtual mechanic, vehicle diagnostic function</p>
                </h4>
                <ul className="dash_list">
                  <li>Vehicle fault diagnosis and fault history inquiry</li>
                  <li>Consumable replacement cycle alarm</li>
                  <li>Cooperation between vehicle remote diagnosis and repair shop (to be supported)</li>
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 img_area">
                <img src="/images/car_landing_03.jpg" alt="Real-time vehicle location tracking" />
              </div>
              <div className="col-md-6 text_area">
                <h4 className="tit_area">
                  <p className="numbering">
                    <span>03</span>
                    <small>Features</small>
                  </p>
                  <p className="tit_lg">Real-time vehicle location tracking</p>
                </h4>
                <ul className="dash_list">
                  <li>Query the current location of the vehicle</li>
                  <li>Anti-theft notification</li>
                  <li>Locate and ensure safety of families in use of the vehicle</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="section bg_g">
          <div className="container">
            <div className="row">
              <div className="col-12 tit_underline">
                <h2 className="tit_md">Main Function</h2>
              </div>
              <div className="col-lg-3 col-6">
                <div className="img_area">
                  <img src="/images/car-icon_01.png" width="30%" alt="Monitoring" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">Monitoring</h5>
                  <p className="desc">
                    Vehicle failure diagnosis <br />
                    and consumable replacement cycle alarm
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="img_area">
                  <img src="/images/car-icon_02.png" width="30%" alt="Accident detection" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">Accident detection</h5>
                  <p className="desc">Vehicle accident detection and emergency support</p>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="img_area">
                  <img src="/images/car-icon_03.png" width="30%" alt="Theft tracking" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">Theft tracking</h5>
                  <p className="desc">
                    Theft vehicle location <br />
                    tracking function
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="img_area">
                  <img src="/images/car-icon_04.png" width="30%" alt="Emergency dispatch" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">Emergency dispatch</h5>
                  <p className="desc">
                    Emergency Departure Service <br />
                    to Support Fault Vehicles
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="img_area">
                  <img src="/images/car-icon_05.png" width="30%" alt="Driving habits" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">Driving habits</h5>
                  <p className="desc">Detailed analysis of driving habits</p>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="img_area">
                  <img src="/images/car-icon_06.png" width="30%" alt="Customized Benefits" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">Customized Benefits</h5>
                  <p className="desc">Provide driver-tailored service benefits</p>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="img_area">
                  <img src="/images/car-icon_07.png" width="30%" alt="Vehicle value" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">Vehicle value</h5>
                  <p className="desc">Accurate vehicle value through vehicle diagnosis and history inquiry</p>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="img_area">
                  <img src="/images/car-icon_08.png" width="30%" alt="Vehicle location" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">Vehicle location</h5>
                  <p className="desc">Check vehicle location and driving history</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-6">
                <div className="thumb_gradient">
                  <Link to="/en/case/smartOffice">
                    <img src="/images/case_office.png" alt="Smart Office" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-6">
                <div className="thumb_gradient">
                  <Link to="/en/case/smartHome">
                    <img src="/images/case_home.png" alt="Smart Home" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-6">
                <div className="thumb_gradient">
                  <Link to="/en/case/smartHealthcare">
                    <img src="/images/case_healthcare.png" alt="Smart Healthcare" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-6">
                <div className="thumb_gradient">
                  <Link to="/en/case/smartBuilding">
                    <img src="/images/case_building.png" alt="Smart Building" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-6">
                <div className="thumb_gradient">
                  <Link to="/en/case/smartCar">
                    <img src="/images/case_car.png" alt="Smart Car" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-6">
                <div className="thumb_gradient">
                  <Link to="/en/case/smartFactory">
                    <img src="/images/case_factory.png" alt="Smart Factory" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
