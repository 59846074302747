import React from 'react';
import { Link } from 'react-router-dom';

export default function SmartHealthcare() {
  return (
    <>
      <div className="content_heading bg_product">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="tit_xl">스마트 헬스케어</h2>
              <ol className="breadcrumb">
                <li>Home</li>
                <li>솔루션</li>
                <li className="active">스마트 헬스케어</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="tit_underline">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2 className="tit_lg">Smart Healthcare</h2>
                <p className="desc">
                  <span>신체정보 모니터링, 건강관리 서비스를 위한 </span>
                  <span>IoT기반 헬스케어 시스템</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="section bg_g">
          <div className="container">
            <div className="row">
              <div className="col-12 img_area">
                <img src="/images/main_img-healthcare.jpg" alt="Smart Healthcare" />
              </div>
              <div className="col-12 row text_area">
                <h3 className="col-lg-4 tit_md">Smart Healthcare</h3>
                <p className="col-lg-8 desc">하드웨어와 소프트웨어의 융합 서비스 필요성이 증가함에 따라, 신뢰도 높은 클라우드 기반의 IoT 홈서비스를합리적인 가격으로 제공합니다.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="section landing">
          <div className="container">
            <div className="row">
              <div className="col-md-6 img_area">
                <img src="/images/healthcare_landing_01.jpg" alt="스마트 헬스케어 솔루션" />
              </div>
              <div className="col-md-6 text_area">
                <h4 className="tit_area">
                  <p className="numbering">
                    <span>01</span>
                    <small>Features</small>
                  </p>
                  <p className="tit_lg">스마트 헬스케어 솔루션</p>
                </h4>
                <ul className="dash_list">
                  <li>스마트 스포츠웨어를 활용한 생체정보 센싱, 모니터링, 건강관리</li>
                  <li>매일 사용자의 컨디션 지수를 활용한 스마트 건강관리 가능</li>
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 img_area">
                <img src="/images/healthcare_landing_02.jpg" alt="운동량 및 운동거리 측정" />
              </div>
              <div className="col-md-6 text_area">
                <h4 className="tit_area">
                  <p className="numbering">
                    <span>02</span>
                    <small>Features</small>
                  </p>
                  <p className="tit_lg">스마트 웨어러블을 활용한 운동량 및 운동거리 측정</p>
                </h4>
                <ul className="dash_list">
                  <li>심박수/운동량/체중관리를 통해 스마트한 운동 관리 가능</li>
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 img_area">
                <img src="/images/healthcare_landing_03.jpg" alt="스마트 헬스케어 생태계" />
              </div>
              <div className="col-md-6 text_area">
                <h4 className="tit_area">
                  <p className="numbering">
                    <span>03</span>
                    <small>Features</small>
                  </p>
                  <p className="tit_lg">스마트 헬스케어 생태계 제공</p>
                </h4>
                <ul className="dash_list">
                  <li>어플리케이션 개발자, 스마트헬스 서비스 제공자, 컨텐츠 제공자가 함께 참여하여 스마트 헬스케어 생태계 구성</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="section bg_g">
          <div className="container">
            <div className="row">
              <div className="col-12 tit_underline">
                <h2 className="tit_md">주요기능</h2>
              </div>
              <div className="col-lg-3 col-6">
                <div className="img_area">
                  <img src="/images/healthcare-icon_01.png" width="30%" alt="스마트의류, 센서" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">스마트의류, 센서</h5>
                  <p className="desc">
                    인간공학적 패턴설계와 <br />
                    ICT융합을 통한 <br />
                    기능성 바디웨어
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="img_area">
                  <img src="/images/healthcare-icon_02.png" width="30%" alt="스마트 디바이스" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">스마트 디바이스</h5>
                  <p className="desc">
                    바이오 센서(ECG-심박, 체온), <br />
                    NFC와 BT방식 지원 배터리, <br />
                    충전 모둘
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="img_area">
                  <img src="/images/healthcare-icon_03.png" width="30%" alt="헬스케어 어플리케이션" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">헬스케어 어플리케이션</h5>
                  <p className="desc">
                    심박수, 온도, 이너웨어 신체정보 <br />
                    센싱 모니터링, 건강 정보 서비스
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="img_area">
                  <img src="/images/healthcare-icon_04.png" width="30%" alt="헬스케어 분석 서비스" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">헬스케어 분석 서비스</h5>
                  <p className="desc">
                    10단계의 일일 컨디션지수 제공, <br />
                    건강이상징후 알림 서비스
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-6">
                <div className="thumb_gradient">
                  <Link to="/case/smartOffice">
                    <img src="/images/case_office.png" alt="스마트 오피스" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-6">
                <div className="thumb_gradient">
                  <Link to="/case/smartHome">
                    <img src="/images/case_home.png" alt="스마트 홈" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-6">
                <div className="thumb_gradient">
                  <Link to="/case/smartHealthcare">
                    <img src="/images/case_healthcare.png" alt="스마트 헬스케어" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-6">
                <div className="thumb_gradient">
                  <Link to="/case/smartBuilding">
                    <img src="/images/case_building.png" alt="스마트 빌딩" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-6">
                <div className="thumb_gradient">
                  <Link to="/case/smartCar">
                    <img src="/images/case_car.png" alt="스마트 카" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-6">
                <div className="thumb_gradient">
                  <Link to="/case/smartFactory">
                    <img src="/images/case_factory.png" alt="스마트 팩토리" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
