import React from 'react';
import { Link } from 'react-router-dom';

export default function EnSmartFactory() {
  return (
    <>
      <div className="content_heading bg_product">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="tit_xl">Smart Factory</h2>
              <ol className="breadcrumb">
                <li>Home</li>
                <li>Solution</li>
                <li className="active">Smart Factory</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="tit_underline">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2 className="tit_lg">Smart Factory</h2>
                <p className="desc">IoT-based Smart Factory</p>
              </div>
            </div>
          </div>
        </div>
        <div className="section bg_g">
          <div className="container">
            <div className="row">
              <div className="col-12 img_area">
                <img src="/images/main_img-factory.jpg" alt="Smart Factory" />
              </div>
              <div className="col-12 row text_area">
                <h3 className="col-lg-4 tit_md">Smart Factory</h3>
                <p className="col-lg-8 desc">
                  By applying ICT (Information Communication Technology) combined with digital automation solutions to production processes such as design, development, manufacturing, distribution, and logistics, Based on factory automation and information
                  service business, intelligent factories can be controlled.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="section landing">
          <div className="container">
            <div className="row">
              <div className="col-md-6 img_area">
                <img src="/images/factory_landing_01.jpg" alt="Real-time management and response" />
              </div>
              <div className="col-md-6 text_area">
                <h4 className="tit_area">
                  <p className="numbering">
                    <span>01</span>
                    <small>Features</small>
                  </p>
                  <p className="tit_lg">Real-time management and response</p>
                </h4>
                <ul className="dash_list">
                  <li>Manage entire production lines in real time</li>
                  <li>Advantages of fast and accurate decision making</li>
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 img_area">
                <img src="/images/factory_landing_02.jpg" alt="Safe working environment" />
              </div>
              <div className="col-md-6 text_area">
                <h4 className="tit_area">
                  <p className="numbering">
                    <span>02</span>
                    <small>Features</small>
                  </p>
                  <p className="tit_lg">Safe working environment</p>
                </h4>
                <ul className="dash_list">
                  <li>Securing facility safety through analysis of various sensors</li>
                  <li>Securing employee safety according to safe factory environment</li>
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 img_area">
                <img src="/images/factory_landing_03.jpg" alt="Manufacturing innovation" />
              </div>
              <div className="col-md-6 text_area">
                <h4 className="tit_area">
                  <p className="numbering">
                    <span>03</span>
                    <small>Features</small>
                  </p>
                  <p className="tit_lg">Manufacturing innovation</p>
                </h4>
                <ul className="dash_list">
                  <li>An intelligent production plant that improves quality production and customer satisfaction based on customer needs</li>
                  <li>Establishing measures to streamline factories from a mid-to long-term perspective</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="section bg_g">
          <div className="container">
            <div className="row">
              <div className="col-12 tit_underline">
                <h2 className="tit_md">Main function</h2>
              </div>
              <div className="col-lg-3 col-6">
                <div className="img_area">
                  <img src="/images/factory-icon_01.png" width="30%" alt="Monitoring of factory conditions" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">Monitoring of factory conditions</h5>
                  <p className="desc">Factory fault diagnosis and consumable replacement cycle alarm</p>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="img_area">
                  <img src="/images/factory-icon_02.png" width="30%" alt="Accident detection" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">Accident detection</h5>
                  <p className="desc">Factory Accident Detection and Emergency Support</p>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="img_area">
                  <img src="/images/factory-icon_03.png" width="30%" alt="Customized production" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">Customized production</h5>
                  <p className="desc">Production of customized products with the design desired by the customer</p>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="img_area">
                  <img src="/images/factory-icon_04.png" width="30%" alt="Reduce factory operating costs" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">Reduce factory operating costs</h5>
                  <p className="desc">Reduce operational costs with automation systems</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-6">
                <div className="thumb_gradient">
                  <Link to="/en/case/smartOffice">
                    <img src="/images/case_office.png" alt="Smart Office" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-6">
                <div className="thumb_gradient">
                  <Link to="/en/case/smartHome">
                    <img src="/images/case_home.png" alt="Smart Home" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-6">
                <div className="thumb_gradient">
                  <Link to="/en/case/smartHealthcare">
                    <img src="/images/case_healthcare.png" alt="Smart Healthcare" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-6">
                <div className="thumb_gradient">
                  <Link to="/en/case/smartBuilding">
                    <img src="/images/case_building.png" alt="Smart Building" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-6">
                <div className="thumb_gradient">
                  <Link to="/en/case/smartCar">
                    <img src="/images/case_car.png" alt="Smart Car" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-6">
                <div className="thumb_gradient">
                  <Link to="/en/case/smartFactory">
                    <img src="/images/case_factory.png" alt="Smart Factory" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
