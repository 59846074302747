import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { apis } from '../api/aiblab';
import { FaAngleLeft, FaAngleRight, FaPlus, FaArrowRight } from 'react-icons/fa';
import 'animate.css';
import Popup from '../components/popup/Popup';
import { usePopupContext } from '../context/AppContext';

export default function Home() {
  const [pageInfo, setPageInfo] = useState({
    pageNo: 1,
    pageSize: 10,
  });
  const { pageNo, pageSize } = pageInfo;
  const [keyword, setKeyword] = useState('');

  const [newsList, setNewsList] = useState([]);
  const loadNewsList = () => {
    apis
      .getNewsList(pageNo, pageSize, keyword)
      .then((response) => {
        setNewsList(response.data.items.slice(0, 3)); // 최근 3개 아이템만 호출
      })
      .catch((error) => console.log(error.response));
  };
  const [mediaList, setMediaList] = useState([]);
  const loadPrList = () => {
    apis
      .getMediaList(pageNo, pageSize, keyword)
      .then((response) => {
        setMediaList(response.data.items.slice(0, 3)); // 최근 3개 아이템만 호출
      })
      .catch((error) => console.log(error.response));
  };

  const { popupVisible, setPopupVisible } = usePopupContext();
  const closePopup = () => {
    setPopupVisible(false);
  };

  useEffect(() => {
    loadNewsList();
    loadPrList();
  }, []);

  return (
    <>
      {popupVisible && <Popup visible={popupVisible.toString()} closable={true} onClose={closePopup} />}
      <div id="homeCarousel" className="carousel slide home" data-bs-ride="true">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <div className="container">
              <div className="carousel-caption">
                <h1 className="animate__animated animate__lightSpeedInRight">스마트한 세상! 스마트한 아이브랩!</h1>
                <p className="animate__animated animate__bounceIn">
                  다양한 융합 서비스 구축 기술과 경험을 활용하여 <br />
                  생활에 꼭 필요한 서비스를 제공합니다.
                </p>
                <Link to="/company/introduce" className="btn btn_primary animate__animated animate__bounceIn">
                  <strong>자세히 보기</strong>
                </Link>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <div className="container">
              <div className="carousel-caption">
                <h1 className="animate__animated animate__lightSpeedInRight">AI, IoT, Bigdata 기술전문기업</h1>
                <p className="animate__animated animate__bounceIn">미래산업의 주역 아이브랩이 이끌어 갑니다.</p>
                <Link to="/company/introduce" className="btn btn_primary animate__animated animate__bounceIn">
                  <strong>자세히 보기</strong>
                </Link>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <div className="container">
              <div className="carousel-caption">
                <h1 className="animate__animated animate__lightSpeedInRight">Start with AIBLab!</h1>
                <p className="animate__animated animate__bounceIn ">
                  사람과 사람, 사람과 사물간 커뮤니케이션을 통해 <br />
                  Smart한 환경을 만들어 갑니다.
                </p>
                <Link to="/company/introduce" className="btn btn_primary animate__animated animate__bounceIn">
                  <strong>자세히 보기</strong>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#homeCarousel" data-bs-slide="prev">
          <FaAngleLeft />
          <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#homeCarousel" data-bs-slide="next">
          <FaAngleRight />
          <span className="visually-hidden">Next</span>
        </button>
        <ul className="carousel-indicators">
          <li data-bs-target="#homeCarousel" data-bs-slide-to="0" className="active"></li>
          <li data-bs-target="#homeCarousel" data-bs-slide-to="1"></li>
          <li data-bs-target="#homeCarousel" data-bs-slide-to="2"></li>
        </ul>
      </div>
      <div className="content">
        <div className="section introduce">
          <div className="container">
            <div className="row">
              <div className="col-12 tit_underline">
                <h2 className="tit_lg">아이브랩</h2>
                <p className="desc">아이브랩은 AI, IoT, Bigdata 기술전문기업입니다.</p>
              </div>
              <div className="col-md-4">
                <div className="thumb_gradient circle">
                  <Link to="/company/vision">
                    <img src="/images/vision_ai.jpg" alt="AI" />
                  </Link>
                </div>
                <div className="summary">
                  <h3 className="tit_sm">AI</h3>
                  <div className="desc">
                    <p>
                      <span>아이브랩 인공지능은 거대한 공간정보의 </span>
                      <span>효율적 관리를 위한 전문가 체계기법을 제공하며 </span>
                    </p>
                    <p>자동설계의 기능을 제공합니다.</p>
                  </div>
                </div>
                <Link to="/company/vision" className="more">
                  <span>자세히 보기</span>
                  <FaAngleRight />
                </Link>
              </div>
              <div className="col-md-4">
                <div className="thumb_gradient circle">
                  <Link to="/company/vision">
                    <img src="/images/vision_iot.jpg" alt="IoT" />
                  </Link>
                </div>
                <div className="summary">
                  <h3 className="tit_sm">IoT</h3>
                  <div className="desc">
                    <p>
                      <span>사용자가 쉽게 이용할 수 있도록 </span>
                      <span>실시간 센싱 정보 서비스를 제공하며 </span>
                      <span>다양한 디바이스를 활용한 </span>
                    </p>
                    <p>서비스 사업을 수행할 수 있습니다.</p>
                  </div>
                </div>
                <Link to="/company/vision" className="more">
                  <span>자세히 보기</span>
                  <FaAngleRight />
                </Link>
              </div>
              <div className="col-md-4">
                <div className="thumb_gradient circle">
                  <Link to="/company/vision">
                    <img src="/images/vision_bigdata.jpg" alt="Bigdata" />
                  </Link>
                </div>
                <div className="summary">
                  <h3 className="tit_sm">Bigdata</h3>
                  <div className="desc">
                    <p>
                      <span>아이브랩의 데이터는 사람들의 행동은 </span>
                      <span>물론 위치정보와 SNS를 통해 생각과 의견까지 </span>
                    </p>
                    <p>분석하고 예측할 수 있습니다.</p>
                  </div>
                </div>
                <Link to="/company/vision" className="more">
                  <span>자세히 보기</span>
                  <FaAngleRight />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="section product bg_g">
          <div className="container">
            <div className="row">
              <div className="col-12 tit_underline">
                <h2 className="tit_lg">아이브랩 제품 구축사례</h2>
                <p className="desc">다양한 아이브랩의 제품 구축사례입니다.</p>
              </div>
              <div className="col-md-4 col-6">
                <div className="thumb_plus">
                  <img src="/images/home_thumb_01.jpg" alt="스마트 오피스" />
                  <Link to="/case/smartOffice">
                    <FaPlus />
                  </Link>
                </div>
                <div className="summary">
                  <h3 className="tit_xs">스마트 오피스</h3>
                  <p className="desc">핸디소프트, 이노톤 스마트 오피스</p>
                </div>
              </div>
              <div className="col-md-4 col-6">
                <div className="thumb_plus">
                  <img src="/images/home_thumb_02.jpg" alt="스마트 홈" />
                  <Link to="/case/smartHome">
                    <FaPlus />
                  </Link>
                </div>
                <div className="summary">
                  <h3 className="tit_xs">스마트 홈</h3>
                  <p className="desc">레이캅, 퍼니스템 스마트베드</p>
                </div>
              </div>
              <div className="col-md-4 col-6">
                <div className="thumb_plus">
                  <img src="/images/home_thumb_03.jpg" alt="스마트 헬스케어" />
                  <Link to="/case/smartHealthcare">
                    <FaPlus />
                  </Link>
                </div>
                <div className="summary">
                  <h3 className="tit_xs">스마트 헬스케어</h3>
                  <p className="desc">좋은사람들 레이스 메이커</p>
                </div>
              </div>
              <div className="col-md-4 col-6">
                <div className="thumb_plus">
                  <img src="/images/home_thumb_04.jpg" alt="스마트 빌딩" />
                  <Link to="/case/smartBuilding">
                    <FaPlus />
                  </Link>
                </div>
                <div className="summary">
                  <h3 className="tit_xs">스마트 빌딩</h3>
                  <p className="desc">인도네시아 국영 통신사 텔콤셀</p>
                </div>
              </div>
              <div className="col-md-4 col-6">
                <div className="thumb_plus">
                  <img src="/images/home_thumb_05.jpg" alt="스마트 카" />
                  <Link to="/case/smartCar">
                    <FaPlus />
                  </Link>
                </div>
                <div className="summary">
                  <h3 className="tit_xs">스마트 카</h3>
                  <p className="desc">핸디카, ETRI 스마트 카</p>
                </div>
              </div>
              <div className="col-md-4 col-6">
                <div className="thumb_plus">
                  <img src="/images/home_thumb_06.jpg" alt="스마트 팩토리" />
                  <Link to="/case/smartFactory">
                    <FaPlus />
                  </Link>
                </div>
                <div className="summary">
                  <h3 className="tit_xs">스마트 팩토리</h3>
                  <p className="desc">ETRI 스마트 팩토리</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section service">
          <div className="container">
            <div className="row">
              <div className="col-12 tit_underline">
                <h2 className="tit_lg">아이브랩 서비스 </h2>
                <p className="desc">근태관리시스템 아이브웍스를 소개합니다.</p>
              </div>
              <div className="col-md-4">
                <div className="thumb_plus">
                  <img src="/images/home_thumb_07.jpg" alt="근태관리" />
                  <Link to="/service/aibworks" state={{ activeTab: 'aibworks' }}>
                    <FaPlus />
                  </Link>
                </div>
                <div className="summary">
                  <h3 className="tit_xs">근태관리</h3>
                  <p className="desc">
                    <span>사내 정책에 맞게 </span>
                    <span>유연한 근무제 설정이 가능합니다.</span>
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="thumb_plus">
                  <img src="/images/home_thumb_08.jpg" alt="스마트 화장실" />
                  <Link to="/service/aibworks" state={{ activeTab: 'smartToilet' }}>
                    <FaPlus />
                  </Link>
                </div>
                <div className="summary">
                  <h3 className="tit_xs">스마트 화장실</h3>
                  <p className="desc">
                    <span>아이브웍스 토일럿은 </span>
                    <span>귀중한 시간을 지켜드립니다.</span>
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="thumb_plus">
                  <img src="/images/home_thumb_09.jpg" alt="환경정보 모니터링" />
                  <Link to="/service/aibworks" state={{ activeTab: 'monitoringEnv' }}>
                    <FaPlus />
                  </Link>
                </div>
                <div className="summary">
                  <h3 className="tit_xs">환경정보 모니터링</h3>
                  <p className="desc">쾌적한 업무공간 확인이 가능합니다.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="banner aibworks">
          <div className="container">
            <div className="row">
              <p className="col-12 catchphrase">
                <span className="phrase_main">아이브 웍스를 시작해 보세요.</span>
                <span className="phrase_sub">스마트한 업무환경을 경험할 수 있습니다.</span>
              </p>
              <div className="col-12 btn_area">
                <Link to="/service/aibworks" className="btn btn_lg btn_outline btn_arrow">
                  <strong>자세히 보기</strong>
                  <FaArrowRight />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="section pr">
          <div className="container">
            <div className="row">
              <div className="col-12 tit_underline">
                <h2 className="tit_lg">아이브랩 소식</h2>
              </div>
              <div className="col-12">
                <div id="newsCarousel" className="carousel slide dashed" data-bs-ride="true">
                  <ul className="carousel-inner">
                    {newsList &&
                      newsList.map((item, index) => (
                        <li className={`carousel-item${index === 0 ? ' active' : ''}`} key={item.newsId}>
                          <Link to="/info/news" className="item_link">
                            <p>{item.title}</p>
                          </Link>
                          <p className="remark">{item.createTime}</p>
                        </li>
                      ))}
                  </ul>
                  <ol className="carousel-indicators">{newsList && newsList.map((item, index) => <li className={index === 0 ? 'active' : ''} data-bs-target="#newsCarousel" data-bs-slide-to={index} key={item.newsId}></li>)}</ol>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section pr">
          <div className="container">
            <div className="row">
              <div className="col-12 tit_underline">
                <h2 className="tit_lg">언론홍보</h2>
              </div>
              <div className="col-12">
                <div id="mediaCarousel" className="carousel slide dashed" data-bs-ride="true">
                  <ul className="carousel-inner">
                    {mediaList &&
                      mediaList.map((item, index) => (
                        <li className={`carousel-item${index === 0 ? ' active' : ''}`} key={item.newsId}>
                          <Link to="/info/media" className="item_link">
                            <p>{item.title}</p>
                          </Link>
                          <p className="remark">{item.createTime}</p>
                        </li>
                      ))}
                  </ul>
                  <ol className="carousel-indicators">{mediaList && mediaList.map((item, index) => <li className={index === 0 ? 'active' : ''} data-bs-target="#mediaCarousel" data-bs-slide-to={index} key={item.newsId}></li>)}</ol>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section partner">
          <div className="container">
            <div className="row">
              <div className="col-12 tit_underline">
                <h2 className="tit_lg">아이브랩 파트너</h2>
                <p className="desc">파트너사입니다.</p>
              </div>
              <div className="col-lg-3 col-sm-4 col-6">
                <img src="/images/partners/handysoft.jpg" alt="핸디소프트" />
              </div>
              <div className="col-lg-3 col-sm-4 col-6">
                <img src="/images/partners/dasannetworks.png" alt="다산네트웍스" />
              </div>
              <div className="col-lg-3 col-sm-4 col-6">
                <img src="/images/partners/tta.jpg" alt="한국정보통신기술협회" />
              </div>
              <div className="col-lg-3 col-sm-4 col-6">
                <img src="/images/partners/rockwon-it.jpg" alt="녹원정보기술" />
              </div>
              <div className="col-lg-3 col-sm-4 col-6">
                <img src="/images/partners/bizflow.jpg" alt="비즈플로우" />
              </div>
              <div className="col-lg-3 col-sm-4 col-6">
                <img src="/images/partners/aicar.jpg" alt="아이카" />
              </div>
              <div className="col-lg-3 col-sm-4 col-6">
                <img src="/images/partners/amotech.jpg" alt="아모텍" />
              </div>
              <div className="col-lg-3 col-sm-4 col-6">
                <img src="/images/partners/etri.jpg" alt="한국전자통신연구원" />
              </div>
              <div className="col-lg-3 col-sm-4 col-6">
                <img src="/images/partners/ese.jpg" alt="이에스이" />
              </div>
              <div className="col-lg-3 col-sm-4 col-6">
                <img src="/images/partners/alps.jpg" alt="알프스전기" />
              </div>
              <div className="col-lg-3 col-sm-4 col-6">
                <img src="/images/partners/csi.jpg" alt="건설공사 안전관리 종합정보망" />
              </div>
              <div className="col-lg-3 col-sm-4 col-6">
                <img src="/images/partners/nhn.png" alt="엔에이치엔" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
