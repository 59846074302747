import React from 'react';
import { FaAngleDown, FaAngleUp, FaAngleRight, FaHome } from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';

export default function EnAibworks() {
  const { state } = useLocation();

  return (
    <>
      <div className="content_heading bg_service">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="tit_xl">AIBworks</h2>
              <ol className="breadcrumb">
                <li>Home</li>
                <li>Service</li>
                <li className="active">Time and attendance management</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="nav">
          <ul id="aibworksTabs" className="nav-tabs">
            <li className="nav-item">
              <a className={`nav-link${state ? (state.activeTab === 'aibworks' ? ' active' : '') : ' active'}`} data-bs-target="#aibworks" data-bs-toggle="tab">
                Time and attendance management
              </a>
            </li>
            <li className="nav-item">
              <a className={`nav-link${state ? (state.activeTab === 'smartToilet' ? ' active' : '') : ''}`} data-bs-target="#smartToilet" data-bs-toggle="tab">
                Smart Toilet
              </a>
            </li>
            <li className="nav-item">
              <a className={`nav-link${state ? (state.activeTab === 'monitoringEnv' ? ' active' : '') : ''}`} data-bs-target="#monitoringEnv" data-bs-toggle="tab">
                Environmental Information Monitor
              </a>
            </li>
          </ul>
        </div>
        <div className="tab-content">
          <div id="aibworks" className={`tab-pane fade${state ? (state.activeTab === 'aibworks' ? ' show active' : '') : ' show active'}`}>
            <div className="container">
              <div className="row">
                <div className="col-12 tit_underline">
                  <h3 className="tit_lg">
                    <span className="sub_tit">2018 Revised Labor Standards Act</span>
                    <span>Working hours per week 52-hour work system</span>
                  </h3>
                  <div className="desc">
                    <p>
                      <span>AIBWorks 'Time and attendance management' </span>
                      <span>will help you adopt the 52-hour work week stably.</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="section work_policy">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <img src="/images/aibworks_commute__eng.png" width="600px" alt="52시간 근무제" />
                    <strong>52-hour workweek</strong>
                  </div>
                  <div className="col-12 diagram">
                    <ul className="bar_list">
                      <li className="bar"></li>
                      <li className="bar">
                        <span className="flag">
                          <FaAngleDown />
                        </span>
                      </li>
                      <li className="bar">
                        <span className="flag">
                          <FaAngleUp />
                        </span>
                      </li>
                      <li className="bar">
                        <span className="flag">
                          <FaAngleDown />
                        </span>
                      </li>
                    </ul>
                    <ul className="desc_list">
                      <li className="flag_desc">
                        <p>2018.07</p>
                        <p>Companies with 300 or more than 300 employees</p>
                      </li>
                      <li className="flag_desc">
                        <p>2020.01</p>
                        <p>Companies with less than 50 to 300 employees</p>
                      </li>
                      <li className="flag_desc">
                        <p>2021.07</p>
                        <p>Companies with less than 5 to 50 employees</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="section bg_g">
              <div className="container">
                <div className="row">
                  <div className="col-12 tit_underline">
                    <h3 className="tit_lg">Flexible work system setting according to company policy</h3>
                    <p className="desc">
                      <span>Flexible work system, Place-free work system, </span>
                      <span>Time Selection Work System, etc. are introduced </span>
                      <span>in-house to provide services that can flexibly cope with the 52-hour work system.</span>
                    </p>
                  </div>
                  <div className="col-md-4">
                    <img src="/images/aibworks_commute_01.png" width="60%" alt="Flexible work system" />
                    <h3 className="tit_sm">Flexible work system</h3>
                  </div>
                  <div className="col-md-4">
                    <img src="/images/aibworks_commute_02.png" width="60%" alt="Place-free work system" />
                    <h3 className="tit_sm">Place-free work system</h3>
                  </div>
                  <div className="col-md-4">
                    <img src="/images/aibworks_commute_03.png" width="60%" alt="Time Selection Work System" />
                    <h3 className="tit_sm">Time Selection Work System</h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="section">
              <div className="container">
                <div className="row">
                  <div className="col-md-4">
                    <div className="thumb_gradient">
                      <a href="https://www.aibworks.com/">
                        <img src="/images/case_commute.png" alt="Smart Commute" />
                      </a>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <h3 className="tit_lg">Use PC version free of charge just by signing up as a member</h3>
                    <div className="desc">
                      <span>If you sign up as a member, you can use the PC version for free (Basic). </span>
                      <span>Better experience for both administrators.</span>
                    </div>
                    <a className="more" href="http://www.aibworks.com/" target="_blank">
                      <FaHome />
                      Get started for free
                      <FaAngleRight />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="smartToilet" className={`tab-pane fade${state ? (state.activeTab === 'smartToilet' ? ' show active' : '') : ''}`}>
            <div className="container">
              <div className="row">
                <div className="col-12 tit_underline">
                  <h3 className="tit_lg">Use PC version free of charge just by signing up as a member</h3>
                  <div className="desc">
                    <p>
                      <span>If you sign up as a member, </span>
                      <span>you can use the PC version for free (Basic).</span>
                    </p>
                    <p>
                      <span>Better experience for both administrators.</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="section pt-0">
              <div className="container">
                <div className="row">
                  <div className="col-md-4">
                    <img src="/images/aibworks_toilet_01__eng.png" width="70%" alt="Login" />
                    <h3 className="tit_sm">Login</h3>
                    <div className="desc">
                      <p>Easy login </p>
                      <p>with corporate service code!</p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <img src="/images/aibworks_toilet_02__eng.png" width="70%" alt="Usage status" />
                    <h3 className="tit_sm">Usage status</h3>
                    <div className="desc">
                      <p>AIBworks Toilet </p>
                      <p>Protect valuable work hours!</p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <img src="/images/aibworks_toilet_03__eng.png" width="70%" alt="Setting" />
                    <h3 className="tit_sm">Setting</h3>
                    <div className="desc">
                      <p>You can register the toilet on the floor </p>
                      <p>that I go to frequently to check the usage status of the toilet!</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="section bg_g">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <h2 className="tit_lg">Minimize cost loss caused by leaving the workplace</h2>
                    <span className="desc">When converting loss cost, it costs about 5 million won per year</span>
                  </div>
                  <div className="col-12">
                    <img src="/images/aibworks_toilet__eng.png" width="80%" alt="비용환산" />
                  </div>
                </div>
              </div>
            </div>
            <div className="section">
              <div className="container">
                <div className="row">
                  <div className="col-md-4">
                    <div className="thumb_gradient">
                      <a href="https://www.aibworks.com/">
                        <img src="/images/case_toilet.png" alt="스마트 토일렛" />
                      </a>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <h3 className="tit_lg">Use Smart Toilet</h3>
                    <p className="desc">
                      <span>It is a service that can check the availability </span>
                      <span>of toilet in the app using LoRa sensor and gateway.</span>
                    </p>
                    <a className="more" href="http://www.aibworks.com/" target="_blank">
                      <FaHome />
                      <span>Get started for free</span>
                      <FaAngleRight />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="monitoringEnv" className={`tab-pane fade${state ? (state.activeTab === 'monitoringEnv' ? ' show active' : '') : ''}`}>
            <div className="container">
              <div className="row">
                <div className="col-12 tit_underline">
                  <h3 className="tit_lg">Comfortable workspace with real-time environmental information monitoring</h3>
                  <div className="desc">
                    <p>
                      <span>Environmental information collected through </span>
                      <span>various IoT sensors is analyzed and provided to managers, </span>
                    </p>
                    <p>
                      <span>and employees are provided with </span>
                      <span>optimal working environment, productivity improvement, and safe working environment.</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="banner bg_environment">
              <div className="container">
                <div className="row">
                  <div className="col-lg-3 col-md-6">
                    <img src="/images/icon-temperature.png" width="40%" alt="Temperature" />
                    <h4 className="tit_md">Temperature</h4>
                    <p className="desc">
                      Real-time workplace temperature <br />
                      check Maintains the right temperature for the job
                    </p>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <img src="/images/icon-humidity.png" width="40%" alt="Humidity" />
                    <h4 className="tit_md">Humidity</h4>
                    <p className="desc">
                      Real-time workspace humidity <br />
                      check pleasant work space
                    </p>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <img src="/images/icon-co2.png" width="40%" alt="CO2" />
                    <h4 className="tit_md">CO2</h4>
                    <p className="desc">
                      Check real-time workspace CO2 <br />
                      Risk Notification Service
                    </p>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <img src="/images/icon-bulb.png" width="40%" alt="Illumination" />
                    <h4 className="tit_md">Illumination</h4>
                    <p className="desc">
                      Real-time workspace illumination <br />
                      check Automatic illumination recommendation
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="section">
              <div className="container">
                <div className="row">
                  <div className="col-md-4">
                    <div className="thumb_gradient">
                      <a href="https://www.aibworks.com/">
                        <img src="/images/case_environment.png" alt="Smart environment" />
                      </a>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <h3 className="tit_lg">Use of environmental information monitoring</h3>
                    <p className="desc">
                      <span>With the IoT sensor, you can optimize your office environment </span>
                      <span>and improve productivity with a comfortable environment</span>
                    </p>
                    <a className="more" href="http://www.aibworks.com/" target="_blank">
                      <FaHome />
                      <span>Get started for free</span>
                      <FaAngleRight />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
