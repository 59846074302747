import { Outlet } from 'react-router-dom';
import QuickMenu from './components/QuickMenu/QuickMenu';
import Footer from './components/Footer';
import NavBar from './components/NavBar';
import Topbar from './components/Topbar';
import ToTop from './components/ToTop/ToTop';
import ScrollToTop from './components/ScrollToTop';
import Loading from './components/loading/Loading';

export default function App() {
  return (
    <>
      <Loading />
      <ScrollToTop />
      <header className="header">
        <Topbar />
        <NavBar />
      </header>
      <main id="main">
        <Outlet />
      </main>
      <Footer />
      <QuickMenu />      
      <ToTop />
    </>
  );
}
