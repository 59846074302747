import React from 'react';
import { Link } from 'react-router-dom';

export default function SmartHome() {
  return (
    <>
      <div className="content_heading bg_product">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="tit_xl">스마트 홈</h2>
              <ol className="breadcrumb">
                <li>Home</li>
                <li>솔루션</li>
                <li className="active">스마트 홈</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="tit_underline">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2 className="tit_lg">Smart Home</h2>
                <p className="desc">IoT 기반 스마트 홈 서비스</p>
              </div>
            </div>
          </div>
        </div>
        <div className="section bg_g">
          <div className="container">
            <div className="row">
              <div className="col-12 img_area">
                <img src="/images/main_img-home.jpg" alt="Smart Home" />
              </div>
              <div className="col-12 row text_area">
                <h3 className="col-lg-4 tit_md">Smart Home</h3>
                <p className="col-lg-8 desc">하드웨어와 소프트웨어의 융합 서비스 필요성이 증가함에 따라, 신뢰도 높은 클라우드 기반의 IoT 홈서비스를합리적인 가격으로 제공합니다.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="section landing">
          <div className="container">
            <div className="row">
              <div className="col-md-6 img_area">
                <img src="/images/home_landing_01.jpg" alt="스마트 베드" />
              </div>
              <div className="col-md-6 text_area">
                <h4 className="tit_area">
                  <p className="numbering">
                    <span>01</span>
                    <small>Features</small>
                  </p>
                  <p className="tit_lg">스마트 베드 (퍼니스템)</p>
                </h4>
                <ul className="dash_list">
                  <li>전동 침대 모델에 따른 구분된 제어 화면 지원으로 사용의 편리성제공</li>
                  <li>가족 회원의 전동 침대 장치 제어 지원</li>
                  <li>가족회원 열람 기능을 통해 가족의 수면 상태 확인</li>
                  <li>심박수, 호흡수 센서를 활용한 수면상태 모니터링</li>
                  <li>개인별 수면상태 데이터 기반의 맞춤형 수면관리 서비스</li>
                  <li>사용자 수면 설정 룰(이벤트) 설정에 의한 명령 수행</li>
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 img_area">
                <img src="/images/home_landing_02.jpg" alt="스마트 매트" />
              </div>
              <div className="col-md-6 text_area">
                <h4 className="tit_area">
                  <p className="numbering">
                    <span>02</span>
                    <small>Features</small>
                  </p>
                  <p className="tit_lg">스마트 매트 (레이캅)</p>
                </h4>
                <ul className="dash_list">
                  <li>수면케어로 매트의 송풍 설정, 온도 설정 가능</li>
                  <li>침구케어를 통한 깨끗한 침구 사용</li>
                  <li>침구 자동 케어 서비스의 편리함</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="section bg_g">
          <div className="container">
            <div className="row">
              <div className="col-12 tit_underline">
                <h2 className="tit_md">주요기능</h2>
              </div>
              <div className="col-lg-3 col-6">
                <div className="img_area">
                  <img src="/images/home-icon_01.png" width="30%" alt="제어기능" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">제어기능</h5>
                  <div className="desc">
                    다중접속 프로그램으로 <br />
                    최다 4명 제어 가능
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="img_area">
                  <img src="/images/home-icon_02.png" width="30%" alt="고장 자가진단 및 알림" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">고장 자가진단 및 알림</h5>
                  <div className="desc">자가 진단으로 신속한 후속조치</div>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="img_area">
                  <img src="/images/home-icon_03.png" width="30%" alt="스마트 모니터링" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">스마트 모니터링</h5>
                  <div className="desc">
                    스마트폰을 이용한 보일러, 침대 <br />
                    상태 및 정보 실시간 확인
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="img_area">
                  <img src="/images/home-icon_04.png" width="30%" alt="클라우드 전력 관리" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">클라우드 전력 관리</h5>
                  <div className="desc">
                    클라우드 기반 시스템을 통한 <br />
                    전력사용량 자료분석 기능
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-6">
                <div className="thumb_gradient">
                  <Link to="/case/smartOffice">
                    <img src="/images/case_office.png" alt="스마트 오피스" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-6">
                <div className="thumb_gradient">
                  <Link to="/case/smartHome">
                    <img src="/images/case_home.png" alt="스마트 홈" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-6">
                <div className="thumb_gradient">
                  <Link to="/case/smartHealthcare">
                    <img src="/images/case_healthcare.png" alt="스마트 헬스케어" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-6">
                <div className="thumb_gradient">
                  <Link to="/case/smartBuilding">
                    <img src="/images/case_building.png" alt="스마트 빌딩" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-6">
                <div className="thumb_gradient">
                  <Link to="/case/smartCar">
                    <img src="/images/case_car.png" alt="스마트 카" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-6">
                <div className="thumb_gradient">
                  <Link to="/case/smartFactory">
                    <img src="/images/case_factory.png" alt="스마트 팩토리" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
