import React, { useEffect } from 'react';
import { BsFillTelephoneFill } from 'react-icons/bs';
import { FaEnvelope } from 'react-icons/fa';
import { AiOutlineInstagram, AiFillFacebook } from 'react-icons/ai';
import { useLangContext } from '../context/AppContext';
import { Link } from 'react-router-dom';
import { apis } from '../api/aiblab';

export default function Topbar() {
  const { lang, setLang } = useLangContext();

  useEffect(() => {
    localStorage.setItem('language', lang);
  }, [lang]);

  return (
    <nav className="top_bar">
      <div className="container">
        <ul className="contact">
          <li>
            <p className="tel">
              <BsFillTelephoneFill />
              <span>070-8892-7160</span>
            </p>
          </li>
          <li>
            <a href="mailto:sales@aiblab.co.kr">
              <FaEnvelope />
              <span>sales@aiblab.co.kr</span>
            </a>
          </li>
        </ul>
        <ul className="sns">
          <li>
            <a href="https://www.instagram.com/aiblabkorea/" target="_blank">
              <AiOutlineInstagram />
            </a>
          </li>
          <li>
            <a href="https://www.facebook.com/AIIoTBigdata" target="_blank">
              <AiFillFacebook />
            </a>
          </li>
        </ul>
        <ul className="language">
          <li className={lang === 'ko-KR' ? 'active' : ''}>
            <Link
              to="/"
              onClick={() => {
                setLang('ko-KR');
                apis.setContentLanguage('ko-KR');
              }}
            >
              Kor
            </Link>
          </li>
          <li className={lang === 'en-US' ? 'active' : ''}>
            <Link
              to="/en"
              onClick={() => {
                setLang('en-US');
                apis.setContentLanguage('en-US');
              }}
            >
              Eng
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
}
