import React from 'react';
import { Link } from 'react-router-dom';
import { MdAdd } from 'react-icons/md';
import { useLangContext } from '../context/AppContext';

export default function Footer() {
  const { lang } = useLangContext();

  return lang === 'ko-KR' ? (
    <footer id="footer" className="footer">
      <div className="banner bg_b">
        <div className="container">
          <div className="row">
            <div className="col-12 catchphrase">
              <p className="phrase_main">아이브랩과 함께 하세요!</p>
              <p className="phrase_sub">스마트한 제품과 서비스를 경험할 수 있습니다.</p>
            </div>
            <div className="col-12 btn_area">
              <a className="btn btn_lg_min btn_primary btn_outline" href="/file/AIBLab소개서(202307).pdf" download={true}>
                <strong>회사소개서 다운로드</strong>
              </a>
              <Link to="/etc/ask" className="btn btn_lg_min btn_primary btn_outline">
                <strong>아이브랩 문의하기</strong>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <ul className="fnb">
          <li>
            <h5>회사소개</h5>
            <ul>
              <li>
                <Link to="/company/introduce">기업소개</Link>
              </li>
              <li>
                <Link to="/company/vision">기업비전</Link>
              </li>
              <li>
                <Link to="/company/greeting">CEO Greeting</Link>
              </li>
              <li>
                <Link to="/company/history">회사연혁</Link>
              </li>
              <li>
                <Link to="/company/location">오시는길</Link>
              </li>
            </ul>
          </li>
          <li>
            <h5>아이브랩 플랫폼</h5>
            <ul>
              <li>
                <Link to="/platform/iot">IoT Platform</Link>
              </li>
              <li>
                <Link to="/platform/aiBigdata">AI·Bigdata Platform</Link>
              </li>
            </ul>
          </li>
          <li>
            <h5>구축사례</h5>
            <ul>
              <li>
                <Link to="/case/smartOffice">스마트 오피스</Link>
              </li>
              <li>
                <Link to="/case/smartHome">스마트 홈</Link>
              </li>
              <li>
                <Link to="/case/smartHealthcare">스마트 헬스케어</Link>
              </li>
              <li>
                <Link to="/case/smartBuilding">스마트 빌딩관리</Link>
              </li>
              <li>
                <Link to="/case/smartCar">스마트 차량관리</Link>
              </li>
              <li>
                <Link to="/case/smartFactory">스마트 팩토리</Link>
              </li>
            </ul>
          </li>
          <li>
            <h5>서비스</h5>
            <ul>
              <li>
                <Link to="/service/aibworks">AIBworks</Link>
              </li>
              <li>
                <Link to="/service/aibcare">AIBCare</Link>
              </li>
              <li>
                <Link to="/service/maintenance">IT·운영서비스</Link>
              </li>
              <li>
                <Link to="/service/smartWater">스마트워터 AI</Link>
              </li>
            </ul>
          </li>
          <li>
            <h5>지원사업</h5>
            <ul>
              <li>
                <Link to="/voucher/aiVoucher">AI 바우처</Link>
              </li>
              <li>
                <Link to="/voucher/dataVoucher">데이터 바우처</Link>
              </li>
            </ul>
          </li>
          <li>
            <h5>PR</h5>
            <ul>
              <li>
                <Link to="/info/news">아이브랩 소식</Link>
              </li>
              <li>
                <Link to="/info/media">언론홍보</Link>
              </li>
            </ul>
          </li>
          <li>
            <h5>채용</h5>
            <ul>
              <li>
                <Link to="/recruit/culture">아이브랩 문화</Link>
              </li>
              <li>
                <Link to="/recruit/recruitment">채용공고</Link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div className="ft_info">
        <div className="container">
          <div className="policy">
            <ul>
              <li>
                <Link to="/etc/termsPrivacy">개인정보처리방침</Link>
              </li>
            </ul>
          </div>
          <div className="copyright">
            <p>
              <span>대표이사: 서영주, 김용우</span>
              <span>사업자등록번호: 545-81-01078</span>
            </p>
            <p className="txt">&copy;2024 AIBLab Inc. All Rights Reserved.</p>
          </div>
          <div className="family_site dropup">
            <button type="button" className="toggle_btn" data-bs-toggle="dropdown" aria-expanded="false">
              <span>Family Site</span>
              <MdAdd />
            </button>
            <ul className="dropdown-menu">
              <li>
                <a href="http://www.aibworks.com/" target="_blank">
                  AIBworks
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  ) : (
    <footer id="footer" className="footer">
      <div className="banner bg_b">
        <div className="container">
          <div className="row">
            <div className="col-12 catchphrase">
              <p className="phrase_main">Foundation of your business AIBLab!</p>
              <p className="phrase_sub">Experience smart products and services.</p>
            </div>
            <div className="col-12 btn_area">
              <a className="btn btn_lg_min btn_primary btn_outline"  href="/file/AIBLab소개서(202307).pdf" download={true}>
                <strong>Company Profile</strong>
              </a>
              <Link to="/en/etc/ask" className="btn btn_lg_min btn_primary btn_outline">
                <strong>AIBLab Contact</strong>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <ul className="fnb">
          <li>
            <h5>Introduction</h5>
            <ul>
              <li>
                <Link to="/en/company/introduce">AIBLab</Link>
              </li>
              <li>
                <Link to="/en/company/vision">Vision</Link>
              </li>
              <li>
                <Link to="/en/company/greeting">CEO Greeting</Link>
              </li>
              <li>
                <Link to="/en/company/history">History</Link>
              </li>
              <li>
                <Link to="/en/company/location">Location Guide</Link>
              </li>
            </ul>
          </li>
          <li>
            <h5>AIBLab Platform</h5>
            <ul>
              <li>
                <Link to="/en/platform/iot">IoT Platform</Link>
              </li>
              <li>
                <Link to="/en/platform/aiBigdata">AI·Bigdata Platform</Link>
              </li>
            </ul>
          </li>
          <li>
            <h5>Product</h5>
            <ul>
              <li>
                <Link to="/en/case/smartOffice">Smart Office</Link>
              </li>
              <li>
                <Link to="/en/case/smartHome">Smart Home</Link>
              </li>
              <li>
                <Link to="/en/case/smartHealthcare">Smart Healthcare</Link>
              </li>
              <li>
                <Link to="/en/case/smartBuilding">Smart Building</Link>
              </li>
              <li>
                <Link to="/en/case/smartCar">Smart Car</Link>
              </li>
              <li>
                <Link to="/en/case/smartFactory">Smart Factory</Link>
              </li>
            </ul>
          </li>
          <li>
            <h5>Service</h5>
            <ul>
              <li>
                <Link to="/en/service/aibworks">AIBworks</Link>
              </li>
              <li>
                <Link to="/en/service/aibcare">AIBCare</Link>
              </li>
              <li>
                <Link to="/en/service/maintenance">IT·Operation service</Link>
              </li>
            </ul>
          </li>
          <li>
            <h5>PR</h5>
            <ul>
              <li>
                <Link to="/en/info/news">AIBLab News</Link>
              </li>
              <li>
                <Link to="/en/info/media">News</Link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div className="ft_info">
        <div className="container">
          <div className="policy">
            <ul>
              <li>
                <Link to="/en/etc/termsPrivacy">Personal Information Processing Policy</Link>
              </li>
            </ul>
          </div>
          <div className="copyright">
            <p>
              <span>CEO: Young-Ju Seo, Yong-Woo Kim</span>
              <span>Corporate registration number: 545-81-01078</span>
            </p>
            <p className="txt">&copy;2024 AIBLab Inc. All Rights Reserved.</p>
          </div>
          <div className="family_site dropup">
            <button type="button" className="toggle_btn" data-bs-toggle="dropdown" aria-expanded="false">
              <span>Family Site</span>
              <MdAdd />
            </button>
            <ul className="dropdown-menu">
              <li>
                <a href="http://www.aibworks.com/" target="_blank">
                  AIBworks
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}
