import React from 'react';
import { Link } from 'react-router-dom';

export default function EnSmartHome() {
  return (
    <>
      <div className="content_heading bg_product">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="tit_xl">Smart Home</h2>
              <ol className="breadcrumb">
                <li>Home</li>
                <li>Solution</li>
                <li className="active">Smart Home</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="tit_underline">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2 className="tit_lg">Smart Home</h2>
                <p className="desc">IoT-based Smart Home Service</p>
              </div>
            </div>
          </div>
        </div>
        <div className="section bg_g">
          <div className="container">
            <div className="row">
              <div className="col-12 img_area">
                <img src="/images/main_img-home.jpg" alt="Smart Home" />
              </div>
              <div className="col-12 row text_area">
                <h3 className="col-lg-4 tit_md">Smart Home</h3>
                <p className="col-lg-8 desc">As the need for convergence services of hardware and software increases, we provide reliable cloud-based IoT home services at reasonable prices.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="section landing">
          <div className="container">
            <div className="row">
              <div className="col-md-6 img_area">
                <img src="/images/home_landing_01.jpg" alt="Smart bed" />
              </div>
              <div className="col-md-6 text_area">
                <h4 className="tit_area">
                  <p className="numbering">
                    <span>01</span>
                    <small>Features</small>
                  </p>
                  <p className="tit_lg">Smart bed (furnistem)</p>
                </h4>
                <ul className="dash_list">
                  <li>Provides ease of use by supporting a separate control screen according to the electric bed model</li>
                  <li>Supporting family members' control of electric bed devices</li>
                  <li>Check the sleep status of the family through the family member viewing function</li>
                  <li>Sleep state monitoring using heart rate and respiratory rate sensors</li>
                  <li>Personalized sleep management service based on individual sleep status data</li>
                  <li>Command execution by setting user sleep setting rule (event)</li>
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 img_area">
                <img src="/images/home_landing_02.jpg" alt="Smart mat" />
              </div>
              <div className="col-md-6 text_area">
                <h4 className="tit_area">
                  <p className="numbering">
                    <span>02</span>
                    <small>Features</small>
                  </p>
                  <p className="tit_lg">Smart mat (raycop)</p>
                </h4>
                <ul className="dash_list">
                  <li>Set the mat's ventilation and temperature with sleep management</li>
                  <li>Use clean bedding through bedding care</li>
                  <li>Convenience of automatic bedding care service</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="section bg_g">
          <div className="container">
            <div className="row">
              <div className="col-12 tit_underline">
                <h2 className="tit_md">Main function</h2>
              </div>
              <div className="col-lg-3 col-6">
                <div className="img_area">
                  <img src="/images/home-icon_01.png" width="30%" alt="Control function" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">Control function</h5>
                  <div className="desc">
                    Multiple access programs <br />
                    allow control of up to four people
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="img_area">
                  <img src="/images/home-icon_02.png" width="30%" alt="Fault self-diagnosis and notification" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">Fault self-diagnosis and notification</h5>
                  <div className="desc">
                    Rapid follow-up with <br />
                    self-diagnosis
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="img_area">
                  <img src="/images/home-icon_03.png" width="30%" alt="Smart monitoring" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">Smart monitoring</h5>
                  <div className="desc">
                    Real-time confirmation of boiler, <br />
                    bed condition and information using smartphone
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="img_area">
                  <img src="/images/home-icon_04.png" width="30%" alt="Cloud power management" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">Cloud power management</h5>
                  <div className="desc">
                    Power consumption data analysis <br />
                    function through cloud-based system
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-6">
                <div className="thumb_gradient">
                  <Link to="/en/case/smartOffice">
                    <img src="/images/case_office.png" alt="Smart Office" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-6">
                <div className="thumb_gradient">
                  <Link to="/en/case/smartHome">
                    <img src="/images/case_home.png" alt="Smart Home" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-6">
                <div className="thumb_gradient">
                  <Link to="/en/case/smartHealthcare">
                    <img src="/images/case_healthcare.png" alt="Smart Healthcare" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-6">
                <div className="thumb_gradient">
                  <Link to="/en/case/smartBuilding">
                    <img src="/images/case_building.png" alt="Smart Building" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-6">
                <div className="thumb_gradient">
                  <Link to="/en/case/smartCar">
                    <img src="/images/case_car.png" alt="Smart Car" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-6">
                <div className="thumb_gradient">
                  <Link to="/en/case/smartFactory">
                    <img src="/images/case_factory.png" alt="Smart Factory" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
