import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { apis } from '../../../api/aiblab';
import { FaAngleRight } from 'react-icons/fa';
import { BiLink } from 'react-icons/bi';
import ReactPagination from '../../../components/ReactPagination';

function AccordionBody({ item }) {
  const [pr, setPr] = useState({});

  const loadPrById = () => {
    apis
      .getMediaById(item.promotionId)
      .then((response) => {
        setPr(response);
      })
      .catch((error) => console.log(error.response));
  };

  useEffect(() => {
    loadPrById();
  }, []);

  const contents = pr.contents;

  return (
    <div className="accordion-body">
      <div dangerouslySetInnerHTML={{ __html: contents }}></div>
    </div>
  );
}

export default function EnMedia() {
  const [keyword, setKeyword] = useState('');
  const [prList, setPrList] = useState([]);
  const [pageInfo, setPageInfo] = useState({
    pageNo: 1,
    pageSize: 10,
  });
  const { pageNo, pageSize } = pageInfo;

  const loadPrList = () => {
    apis
      .getMediaList(pageNo, pageSize, keyword)
      .then((response) => {
        setPrList(response.data.items);
        setPageInfo(response.data.pagination);
      })
      .catch((error) => console.log(error.response));
  };

  const handleChangePage = (pageNo) => {
    setPageInfo((prev) => ({
      ...prev,
      pageNo,
    }));
  };

  useEffect(() => {
    loadPrList();
  }, [pageNo]);

  return (
    <>
      <div className="content_heading pg_pr">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="tit_xl">News</h2>
              <ol className="breadcrumb">
                <li>Home</li>
                <li>PR</li>
                <li className="active">News</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="tit_underline">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2 className="tit_lg">News</h2>
                <p className="desc">This is the latest news from AIBLab.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="notice">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div id="mediaAccordion">
                  {prList.map((item, index) => (
                    <div className="accordion-item" key={item.promotionId}>
                      <div className="accordion-header">
                        <a className="accordion-button collapsed" data-bs-target={`#mediaArticle_${item.promotionId}`} data-bs-toggle={item.directLink === 1 ? '' : 'collapse'} href={item.directLink === 1 ? item.linkUrl : ''} target="_blank">
                          <div className="notice_num">{(pageNo - 1) * pageSize + (index + 1)}</div>
                          <div className="notice_tit">
                            <span className="media">[{item.category}]</span>
                            <span>{item.title}</span>
                          </div>
                          <div className="notice_date">{item.createTime}</div>
                          {item.directLink === 1 ? <BiLink /> : <FaAngleRight />}
                        </a>
                      </div>
                      <div id={`mediaArticle_${item.promotionId}`} className="accordion-collapse collapse" data-bs-parent="#mediaAccordion">
                        {item.directLink === 0 ? <AccordionBody item={item} /> : ''}
                      </div>
                    </div>
                  ))}
                </div>
                <ReactPagination pageInfo={pageInfo} handleChangePage={handleChangePage} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
