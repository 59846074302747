import React from 'react';
import { Link } from 'react-router-dom';

export default function SmartOffice() {
  return (
    <>
      <div className="content_heading bg_product">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="tit_xl">스마트 오피스</h2>
              <ol className="breadcrumb">
                <li>Home</li>
                <li>솔루션</li>
                <li className="active">스마트 오피스</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="tit_underline">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h3 className="tit_lg">Smart Office</h3>
                <p className="desc">IoT 기반 스마트 오피스 서비스</p>
              </div>
            </div>
          </div>
        </div>
        <div className="section bg_g">
          <div className="container">
            <div className="row">
              <div className="col-12 img_area">
                <img src="/images/main_img-office.jpg" alt="Smart Office" />
              </div>
              <div className="col-12 row text_area">
                <h4 className="col-lg-4 tit_md">Smart Office</h4>
                <p className="col-lg-8 desc">비콘 기반으로 출퇴근 근태 자동관리 및 모바일 사원증, 비인가 지역 접근 모니터링, 스마트 회의실 예약이 가능하며, 사무실 환경 정보 모니터링, 화장실 사용여부 확인 등 지능형 스마트 오피스를 실현해 줍니다.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="section landing">
          <div className="container">
            <div className="row">
              <div className="col-md-6 img_area">
                <img src="/images/office_landing_01.jpg" alt="스마트 근태관리" />
              </div>
              <div className="col-md-6 text_area">
                <h4 className="tit_area">
                  <p className="numbering">
                    <span>01</span>
                    <small>Features</small>
                  </p>
                  <p className="tit_lg">스마트 근태관리</p>
                </h4>
                <ul className="dash_list">
                  <li>모바일 사원증</li>
                  <li>비콘 기반의 출퇴근 관리</li>
                  <li>기존 근태관리시스템 연계</li>
                  <li>모바일 서비스 제공</li>
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 img_area">
                <img src="/images/office_landing_02.jpg" alt="스마트 협업" />
              </div>
              <div className="col-md-6 text_area">
                <h4 className="tit_area">
                  <p className="numbering">
                    <span>02</span>
                    <small>Features</small>
                  </p>
                  <p className="tit_lg">스마트 협업</p>
                </h4>
                <ul className="dash_list">
                  <li>임직원 최근 위치정보 제공</li>
                  <li>스마트 회의실 예약</li>
                  <li>회의 참여 알림</li>
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 img_area">
                <img src="/images/office_landing_03.jpg" alt="스마트 오피스 환경" />
              </div>
              <div className="col-md-6 text_area">
                <h4 className="tit_area">
                  <p className="numbering">
                    <span>03</span>
                    <small>Features</small>
                  </p>
                  <p className="tit_lg">스마트 오피스 환경</p>
                </h4>
                <ul className="dash_list">
                  <li>사무실 환경정보 모니터링</li>
                  <li>스마트 화장실 서비스</li>
                  <li>환경정보 알람 서비스</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="section bg_g">
          <div className="container">
            <div className="row">
              <div className="col-12 tit_underline">
                <h4 className="tit_md">주요기능</h4>
              </div>
              <div className="col-lg-4 col-6">
                <div className="img_area">
                  <img src="/images/office-icon_01.png" width="20%" alt="출입 근태관리" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">출입 근태관리</h5>
                  <p className="desc">
                    비콘기반 자동 출입/근태 체크 관리, <br />
                    출퇴근시 일정 및 메세지 연동
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-6">
                <div className="img_area">
                  <img src="/images/office-icon_02.png" width="20%" alt="회의실 환경 자동제어" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">회의실 환경 자동제어</h5>
                  <p className="desc">
                    회의실 출입시 설비 자동제어, <br />
                    조명 및 프로젝터 자동제어
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-6">
                <div className="img_area">
                  <img src="/images/office-icon_03.png" width="20%" alt="회의실 예약" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">회의실 예약</h5>
                  <p className="desc">
                    회의실 모바일 앱(예약/참석/알림/메신저), <br />
                    비콘기반 참석 체크
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-6">
                <div className="img_area">
                  <img src="/images/office-icon_04.png" width="20%" alt="업무공간 환경관리" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">업무공간 환경관리</h5>
                  <p className="desc">
                    환경정보 연동을 통한 <br />
                    쾌적한 사무실 유지 (온도/습도/CO2/조도)
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-6">
                <div className="img_area">
                  <img src="/images/office-icon_05.png" width="20%" alt="이동경로 기반 서비스" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">이동경로 기반 서비스</h5>
                  <p className="desc">
                    비콘기반 근무지 위치 및 <br />
                    업무패턴 파악
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-6">
                <div className="img_area">
                  <img src="/images/office-icon_06.png" width="20%" alt="화장실 이용관리" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">화장실 이용관리</h5>
                  <p className="desc">
                    비콘, IR/접촉센서 등을 활용한 <br />
                    화장실 이용여부 제공 서비스
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-6">
                <div className="thumb_gradient">
                  <Link to="/case/smartOffice">
                    <img src="/images/case_office.png" alt="스마트 오피스" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-6">
                <div className="thumb_gradient">
                  <Link to="/case/smartHome">
                    <img src="/images/case_home.png" alt="스마트 홈" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-6">
                <div className="thumb_gradient">
                  <Link to="/case/smartHealthcare">
                    <img src="/images/case_healthcare.png" alt="스마트 헬스케어" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-6">
                <div className="thumb_gradient">
                  <Link to="/case/smartBuilding">
                    <img src="/images/case_building.png" alt="스마트 빌딩" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-6">
                <div className="thumb_gradient">
                  <Link to="/case/smartCar">
                    <img src="/images/case_car.png" alt="스마트 카" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-6">
                <div className="thumb_gradient">
                  <Link to="/case/smartFactory">
                    <img src="/images/case_factory.png" alt="스마트 팩토리" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
