import React from 'react';

export default function EnTermsPrivacy() {
  return (
    <>
      <div className="content_heading privacy">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="tit_xl">Personal Information Processing Policy</h2>
              <ol className="breadcrumb">
                <li>Home</li>
                <li className="active">Personal Information Processing Policy</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="section">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2 className="tit_lg text-center">Personal Information Processing Policy</h2>
                <ul className="privacy_list">
                  <li className="list-item">
                    <h3 className="title">Article 1 (General Rules)</h3>
                    <p>
                      The AIBworks service (hereinafter referred to as the'Service') of AIBLab (hereinafter referred to as the'Company') values ​​the protection of members' personal information, and always makes every effort to protect the member's personal
                      information.
                    </p>
                    <p>
                      The Company has established and complied with the “Personal Information Processing Policy” in accordance with the 「Act on Promotion of Information and Communication Network Utilization and Information Protection,」 and 「Personal
                      Information Protection Act」, and it complies with the AIBLab website <a href="http://www.aiblab.co.kr/">(www. .aiblab.co.kr)</a>
                      or AIBworks website<a href="http://www.aibworks.com/"> (www.aibworks.com)</a>, so it is released at the bottom of the first screen, so members can easily browse it whenever they want. The time and attendance record and the contents
                      derived therefrom are contents that do not belong to the provisions of Article 3 (Collection and Collection Method of Personal Information), and agree to the terms and conditions of smart attendance management,'paid service use and
                      payment' of the smart toilet, The company retains all ownership of the member data contracted with the company for a limited period of time or free of charge to the limited members by decision, and the authority to view, view, modify, and
                      delete T&A records and the content derived therefrom is also given to the company. Is granted. In addition, time and attendance records (hereinafter referred to as'time and attendance records') related to work and work generated using the
                      service, additional data generated during use of the service, and content derived therefrom are required by the administrator for separate or permanent deletion. It is retained for 3 years in the absence of performing its own work within
                      the service. Nevertheless, in accordance with Article 8 (retention and storage period of personal information), time and attendance records and contents derived therefrom are also completely deleted after a 30-day grace period.
                      Hereinafter, personal information provided through the AIBworks service will be deleted. We will provide information on the entire matters from collection, use, and destruction, and the contents of the AIBworks service's "Personal
                      Information Processing Policy" may change according to changes in applicable laws and company operating policies.
                    </p>
                  </li>
                  <li className="list-item">
                    <h3 className="title">Article 2 (Agreement to Collection and Use of Personal Information)</h3>
                    <p>
                      The company collects the minimum personal information necessary for the establishment and implementation of the service use contract in a lawful and fair manner, and provides information on the consent for the collection and use of
                      personal information when registering as a member in order to collect the personally identifiable information. When the member clicks or checks the'Agree' button and proceeds to the next state, it is deemed to have agreed to the
                      collection and use of personal information. The member may refuse to consent to the collection and use of personal information, but the information is the minimum personal information necessary for the provision of the service. In
                      addition, the company may link other companies' websites or materials to members through the homepage. In this case, other websites linked to other companies' websites are not related to the company's'personal information processing
                      policy', so please check the'personal information processing policy' of the newly visited site individually.
                    </p>
                  </li>
                  <li className="list-item">
                    <h3 className="title">Article 3 (Collection scope and collection method of personal information)</h3>
                    <ol>
                      <li>
                        The company can collect personal information as follows to provide services such as membership registration, consultation, service application, and contract execution. The range of personal information collected for free members of the
                        smart time and attendance management service is as follows.
                        <ul>
                          <li>– Required: ID (email address or mobile phone number), password, occupancy authentication information (verification via email address or mobile phone number), user country information</li>
                        </ul>
                      </li>
                      <li>
                        When a member wants to use paid/free service, the company can collect the information required for payment as follows at the time of payment or application for free service.
                        <ul>
                          <li>- Paid service: payment method owner information (name), credit card information, mobile phone number, landline phone number, CMS registration information, business registration certificate, passbook copy</li>
                          <li>- Free service: business registration certificate, business registration number, business/event, name, date of birth, gender, account number</li>
                        </ul>
                      </li>
                      <li>
                        The company may collect the following information when a member participates in an event or promotion.
                        <ul>
                          <li>– Name, email, address, mobile phone number</li>
                        </ul>
                      </li>
                      <li>
                        The following information may be collected when personal authentication is required to verify age or comply with applicable laws.
                        <ul>
                          <li>- Name, date of birth, gender, presence/absence, i-pin number (for i-pin users), mobile phone number, linkage information (CI), and duplicate identification information (DI)</li>
                          <li>- However, minors under the age of 14 (hereinafter referred to as “children”) cannot register for membership, consult, or apply for services.</li>
                        </ul>
                      </li>
                      <li>
                        In the process of using the service, the following information may be generated and collected.
                        <ul>
                          <li>– PC: browser information, program version information used when using other services</li>
                          <li>– Mobile phone (smartphone) Mobile device with smart OS (Tablet PC, etc.)</li>
                          <li>– Other information: service use (stop) record, access log, cookie, access IP information</li>
                        </ul>
                      </li>
                      <li>
                        The Company may collect personal information in the following ways.
                        <ul>
                          <li>– Use of homepage and written form, application for prizes, request for delivery</li>
                          <li>– Offer from affiliates</li>
                          <li>– Collection through creation information collection tool</li>
                        </ul>
                      </li>
                      <li>
                        Personal information (racial and ethnic, ideological and creed, origin and place of origin, political orientation and criminal record, health and sexual life, etc.) that is feared to violate basic human rights are not required and are
                        not collected or used for any purposes other than the above.
                      </li>
                    </ol>
                  </li>
                  <li className="list-item">
                    <h3 className="title">Article 4 (Purpose of Collection and Use of Personal Information)</h3>
                    <h5>The company utilizes the collected personal information for the following purposes:</h5>
                    <ol>
                      <li>
                        Implementing a contract for service provision. Calculation of charges based on the provision of paid/free services.
                        <ul>
                          <li>
                            When using the service, it will collect the subscriber's mobile phone number, subscriber's performance name, smartphone, etc. terminal information (OS version and device information), e-mail, and passwords, and use, use, use, or use
                            the app for the first time. In this process, mobile phone numbers are collected through direct input using identification information (CI) and service identification number (DI), and only directly entered e-mail information is
                            collected when authenticating e-mail. It also optionally collects photos (including meta information) and location information of registered businesses. In addition, when using paid services, location information (whether the device
                            is in a Bluetooth signal area using beacons) or cookie information can be automatically generated and collected during service use or business processing.
                            <br />
                            In addition, when using paid services, delivery information (name, mobile phone number, address, business address, business address) is collected for delivery. If necessary for payment, etc. in the course of using paid services,
                            information necessary for payment, such as credit card information, user information for registering the Cash Management System (CMS), unique number and account information for issuing tax invoices, etc., and business license
                            information for certification may be collected. Service records, access logs, access IP information, sanctions and suspension records, and failure-related information can be generated and collected during the service use or business
                            process. These personal information are collected during the execution or use of the service program.
                          </li>
                        </ul>
                      </li>
                      <li>
                        The company can install and operate cookies that store and retrieve members' information from time to time through Internet service. Cookies are stored on the member's computer hard disk as string information (text files) that the web
                        server sends to the web browser and then sends back to the server when additional requests are made by the server. Cookies can contain information about the website you used and your personal information. When a member accesses the
                        website, he/she can read the contents of the cookie in the member's browser, find additional information, and provide the service without additional input such as the name of the member.
                      </li>
                      <li>
                        The member has the option to install cookies, and the member can set options on the web browser to allow all cookies, check each time the cookie is stored, or refuse to store all cookies. However, if you refuse to install cookies, it
                        may be difficult to provide the service.
                      </li>
                      <li>Cookies expire on browser exit or logout.</li>
                      <li>
                        The information that the company collects through cookies is limited to the member's unique number (User ID), and no other information is collected. The unique number of members that the company collects through cookies can be used for
                        the following purposes:
                        <ul>
                          <li>(1) Provide differentiated information based on individual interests</li>
                          <li>(2) Track service usage and use it as a measure of future personalized service delivery and service reorganization through analysis</li>
                          <li>(3) Information on the period of use when using paid services, etc</li>
                        </ul>
                      </li>
                    </ol>
                  </li>
                  <li className="list-item">
                    <h3 className="title">Article 5 ( Matters concerning the installation and operation of automatic personal information collection)</h3>
                    <ol>
                      <li>
                        Implementing a contract for service provision. Calculation of charges based on the provision of paid/free services.
                        <ul>
                          <li>- Provide paid/free services, charge for use of paid services, purchase and charge payment, self-certification, sending out bills, collecting charges, etc.</li>
                        </ul>
                      </li>
                      <li>
                        Member management
                        <ul>
                          <li>
                            - Individual identification, prevention of unauthorized use of substandard members, duplicate registration, confirmation of intention to join, age verification, business confirmation, record preservation for dispute settlement,
                            civil service handling, delivery of bills, etc.
                          </li>
                        </ul>
                      </li>
                      <li>
                        Used for marketing and advertising
                        <ul>
                          <li>
                            Development of new services and provision of customized services, provision of services according to statistical characteristics, and advertisement display, efficient linkage with partner companies, validation, access frequency
                            identification, statistics on member service use, event and advertisement information and participation opportunities
                          </li>
                        </ul>
                      </li>
                    </ol>
                  </li>
                  <li className="list-item">
                    <h3 className="title">Article 6 (Consignment of processing of collected personal information)</h3>
                    <p>
                      The company consigns and operates personal information to external specialized companies to provide smooth services to members, and agrees to the above-mentioned personal information processing policy and consent to the processing of
                      collected personal information.
                    </p>
                  </li>
                  <li className="list-item">
                    <h3 className="title">Article 7 (sharing and providing personal information)</h3>
                    <ol>
                      <li>
                        The company does not use the member's personal information beyond the scope specified in this privacy policy or provide it to third parties (others, other companies, and other organizations). However, with the consent of the member or
                        in any of the following cases, exceptions are made.
                        <ul>
                          <li>– If the member has agreed in advance</li>
                          <li>– When necessary for the settlement of fees according to service provision</li>
                          <li>– When there is a request from related organizations for investigation, trial or administrative purposes based on related laws and regulations</li>
                          <li>– In case of processing and providing in a form that cannot identify a specific individual for statistical preparation, academic research, or market research</li>
                          <li>
                            –「Act on Financial Real Name Transactions and Confidentiality」, 「Act on Use and Protection of Credit Information」, 「Basic Act on Telecommunications」, 「Telecommunications Business Act」, 「Local Tax Act」, 「Korea Bank Act」,
                            「Criminal Procedure Act」 When there is a request in accordance with procedures set forth in other relevant laws and regulations
                          </li>
                        </ul>
                      </li>
                      <li>
                        In the event that the service provider transfers all or part of the business to provide better service to the members, or transfers or inherits the rights or obligations of the service provider due to merger/inheritance, the member
                        shall be notified of such fact in order to guarantee the members' rights related to personal information protection.
                      </li>
                      <li>
                        In order to provide better services, if the company needs to provide the members' personal information to third parties, such as other companies, the company shall notify the members of who the affiliates are, what the personal
                        information items should be provided or shared in advance, the purpose of the use of the personal information provided or shared, and how long they are shared and protected and managed, and the company shall not provide information to
                        third parties or share it with third parties. In this case, the notification and consent method shall be notified at least seven days in advance through the notice on the website, and at the same time, the notification screen of the
                        app/web shall be notified individually at least once or more, or the consent shall be obtained through the website, written, telephone, or e-mail. In exceptional cases where prior notice is not possible for a short period of time,
                        provision and sharing may occur simultaneously with the announcement. The company will make utmost efforts to prevent the indiscriminate provision of members' personal information against the original purpose of collection and use as
                        stipulated in the Privacy Policy.
                      </li>
                    </ol>
                  </li>
                  <li className="list-item">
                    <h3 className="title">Article 8 (Duration of Storage and Utilization of Personal Information)</h3>
                    <ol>
                      <li>
                        The user's personal information is destroyed when the purpose of personal information collection or the purpose provided is achieved. If a member withdraws from the membership or is deleted due to false personal information, the
                        collected personal information will be completely deleted and will not be available for any purpose. However, due to the possibility of unwanted member withdrawal due to theft, personal information will be temporarily kept for a 30-day
                        grace period after applying for withdrawal, which will be notified to the user upon cancellation of use. After 30 days, it will be completely deleted from the company's membership database (DB).
                      </li>
                      <li>In the event of a dispute, such as identity theft, a copy of the ID submitted for identification shall be destroyed immediately after identification.</li>
                      <li>
                        When the purpose of collection and use of personal information is achieved, the personal information of the user is destroyed without delay, <br />
                        but in the case of each of the items below, it can be stored for the period and not used for other purposes.
                        <ul>
                          <li>
                            A. AIBworks website visit history (www.aibworks.com)
                            <br />
                            – Reason for preservation: Communication Secret Protection Act
                            <br />– Retention period: 3 months
                          </li>
                          <li>
                            B. Records of consumer complaints or disputes
                            <br />
                            – Reason for preservation: Act on Consumer Protection in Electronic Commerce
                            <br />– Retention period: 3 years
                          </li>
                          <li>
                            C. Records of contract or withdrawal of subscription
                            <br />
                            – Reasons for Conservation: Act on Consumer Protection in Electronic Commerce
                            <br />– Retention Period: 5 years
                          </li>
                          <li>
                            D. Records on payment and supply of goods
                            <br />
                            – Reasons for Conservation: Act on Consumer Protection in Electronic Commerce
                            <br />– Retention Period: 5 years
                          </li>
                          <li>
                            E. Markings, Records of Advertise
                            <br />
                            – Reason for preservation: Act on Consumer Protection in e-commerce
                            <br />– Retention period: 6 months
                          </li>
                        </ul>
                      </li>
                    </ol>
                  </li>
                  <li className="list-item">
                    <h3 className="title">Article 9 (procedures and methods of reading and correcting personal information)</h3>
                    <p>
                      When registering as a member, personal information according to the consent for collection and use of personal information and personal information according to the use and payment of free/free services are entitled to be viewed and
                      corrected by the member who provided the information, and the company reads the collected personal information, We provide the corrective means within the service. After the purpose of using the collected personal information is achieved,
                      the company destroys the information according to the storage period and usage period without delay. Destruction target, destruction procedure and method are as follows.
                    </p>
                    <ol>
                      <li>
                        Destruction target
                        <ul>
                          <li>(1) Member registration information</li>
                          <li>(2) Card, account information, etc. other than membership information collected for the purpose of making payments necessary for refund, etc.</li>
                        </ul>
                      </li>
                      <li>
                        Destruction procedure
                        <ul>
                          <li>
                            The personal information created by the member to sign up for the service will be stored and stored for a certain period of time (see the period of storage and use of personal information) according to the internal policy and other
                            relevant laws and regulations, after the purpose of use such as termination of the service is achieved . Then deleted or destroyed. If the retention period of the personal information agreed to by the data subject has elapsed, or if
                            the purpose of processing has been achieved, if the personal information needs to be preserved in accordance with other laws and regulations, the personal information will be transferred to a separate database or kept safe.
                          </li>
                        </ul>
                      </li>
                      <li>
                        Method of destruction
                        <ul>
                          <li>
                            Personal information printed on paper forms, such as copies of identification cards, or personal information printed on paper is shredded and destroyed, and personal information stored in the form of electronic files is deleted
                            using a technical method that cannot reproduce the records.
                          </li>
                        </ul>
                      </li>
                    </ol>
                  </li>
                  <li className="list-item">
                    <h3 className="title">Article 10 (obligation of notice)</h3>
                    <p>
                      If there is any addition, deletion, or modification of the current "Personal Information Processing Policy", it will be notified through the'Notice' column on the homepage at least 7 days before the revision or if the revision is
                      unfavorable to the user, it will be notified for 30 days. Inquiries about changes can be made directly to the person in charge of personal information management and the person in charge, or through the company customer center.
                    </p>
                  </li>
                  <li className="list-item">
                    <h3 className="title">Article 11 (Measures for Personal Information Protection)</h3>
                    <ol>
                      <li>
                        Technical measures
                        <br />
                        The company applies the following technical measures to ensure safety so that personal information is not lost, stolen, leaked, tampered with, or damaged in handling personal information of members.
                        <ul>
                          <li>(1) The member's personal information is managed using an internal network that cannot be accessed and intruded from the external network.</li>
                          <li>
                            (2) Members' personal information is protected by a password that only they can know, and personal information verification and change is possible only by the person who knows the password. In addition, in the case of personal
                            information change through the customer center, it is necessary to go through a method to confirm the identity.
                          </li>
                          <li>
                            (3) The company is taking measures to prevent damage caused by computer viruses by using the vaccine program. The vaccine program is periodically updated, and in the event of a sudden virus appearance, the vaccine is immediately
                            provided to prevent personal information from being infringed.
                          </li>
                          <li>
                            (4) In order to prevent the leakage of personal information of members in preparation for external intrusion such as hacking, the company is currently using a device that blocks intrusion from outside to prevent attacks and hacking
                            from the outside.
                          </li>
                        </ul>
                      </li>
                      <li>
                        관리적 대책
                        <ul>
                          <li>(1) The company prepares necessary procedures for accessing and managing members' personal information to ensure that its employees are aware of and comply with them.</li>
                          <li>
                            (2) The company limits the minimum number of people who can handle the members' personal information. The following are the people who can handle the members' personal information.
                            <ul>
                              <li>A. A person who directly or indirectly deals with members for marketing or consulting</li>
                              <li>B. Persons who perform protection tasks such as personal information protection officers</li>
                              <li>C.Any other person who is forced to process personal information in the course of performing his/her duties</li>
                            </ul>
                          </li>
                          <li>
                            (3) Regular in-house training and external consignment training are provided for employees who process personal information regarding the acquisition of new security technologies and the obligation to protect personal information.
                          </li>
                          <li>
                            (4) Employees who process personal information sign the pledge to protect information in advance to prevent information leakage by employees and to prepare and implement internal procedures for auditing compliance with personal
                            information processing policies and compliance with employees. The information protection pledge is kept by the company after each employee is stamped.
                          </li>
                          <li>
                            (5)The takeover of personal information managers is carried out thoroughly while security is maintained, and the responsibility for personal information infringement accidents is clearly defined after entering and leaving the
                            company. In addition, a person who processed personal information of a member by signing a statement that he or she would not disclose or disclose to any third party or outside after retiring any matters related to the company
                            directly or indirectly while working at the company when retiring. We do not damage, infringe, or leak personal information you have learned.
                          </li>
                          <li>
                            (6) When signing a contract for the use of a paid service, when collecting information about payment such as a member's credit card number and bank payment account, or providing paid services to a member, the member takes necessary
                            measures to verify the identity of the member.
                          </li>
                          <li>
                            (7) The company is not responsible for the leakage of personal information caused by the mistakes of members or the inherent dangers of the Internet. Members must properly manage their ID and password and take responsibility for
                            protecting their personal information.
                          </li>
                        </ul>
                      </li>
                    </ol>
                  </li>
                  <li className="list-item">
                    <h3 className="title">Article 12 (Personal Information Protection Officer)</h3>
                    <p>
                      The company attaches great importance to the protection of the personal information of members, and does its best to prevent the personal information of members from being damaged, infringed, or leaked. If you report any complaints
                      related to the protection of personal information arising from using the service to the person in charge of personal information protection or a person in charge, we will respond promptly.
                    </p>
                    <h4 className="fw_bold">[Personal Information Protection Officer]</h4>
                    <ul>
                      <li>– Name:Hwa-jin Shim</li>
                      <li>– Position: Planning Team Leader</li>
                      <li>– E-mail: admin@aiblab.co.kr</li>
                      <li>– Phone number: 070-8892-7160</li>
                    </ul>
                    <p>
                      (Effective Date) These terms and conditions will go into effect on August 1, 2019.
                      <br />
                      (Revision Date) August 1, 2019.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
