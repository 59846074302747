import React from 'react';
import { Link } from 'react-router-dom';

export default function EnAiBigdata() {
  return (
    <>
      <div className="content_heading bg_product">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="tit_xl">AI·Bigdata Platform</h2>
              <ol className="breadcrumb">
                <li>Home</li>
                <li>AIBLab Platform</li>
                <li className="active">AI·Bigdata Platform</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="tit_underline">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h3 className="tit_lg">AI·Bigdata Platform</h3>
                <p className="desc">
                  <span>AIBLab's AI·Bigdata Platform that combines </span>
                  <span>Internet of Things, big data, machine learning, and domain knowledge.</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="section bg_g">
          <div className="container">
            <div className="row">
              <div className="col-12 img_area">
                <img src="/images/main_img-aibigdata.jpg" alt="AI Big data Platform" />
              </div>
              <div className="col-12 row text_area">
                <h4 className="col-lg-4 tit_md">AI·Bigdata Platform</h4>
                <p className="col-lg-8 desc">AIBLab's ALA Platform enables easier service by developing End-to-End artificial intelligence commercial services quickly and easily.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="section">
          <div className="container">
            <div className="row">
              <div className="col-12 tit_underline">
                <h4 className="tit_md">Product specification</h4>
                <p className="desc">This is the product specification for ALA Platform in the AIBLab.</p>
              </div>
              <div className="col-12 img_area">
                <img src="/images/aibigdata_flow.png" alt="제품규격" />
              </div>
              <div className="col-12 row">
                <div className="col-md-6 text_area">
                  <h5 className="tit_sm">ALA component-based services</h5>
                  <ul className="circle_list">
                    <li>Load and execute application scenarios that use components that meet ALA component standards</li>
                  </ul>
                </div>
                <div className="col-md-6 text_area">
                  <h5 className="tit_sm">ALA composable components</h5>
                  <ul className="circle_list">
                    <li>Domain common dynamic configurable component module</li>
                    <li>Data/analysis/serving component</li>
                  </ul>
                </div>
                <div className="col-md-6 text_area">
                  <h5 className="tit_sm">ALA Core Framework</h5>
                  <ul className="circle_list">
                    <li>Workflow execution control and scheduling according to client requests</li>
                    <li>Configure and run the workflow execution engine</li>
                    <li>Common engine specification and message specification</li>
                    <li>Data/Model/Workflow Repository</li>
                  </ul>
                </div>
                <div className="col-md-6 text_area">
                  <h5 className="tit_sm">ALA Client and Developer SDK</h5>
                  <ul className="circle_list">
                    <li>Web-based client module for ALA application scenario development</li>
                    <li>ALA client module and developer SDK based on Scala</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section bg_g">
          <div className="container">
            <div className="row">
              <div className="col-12 tit_underline">
                <h4 className="tit_md">Main function</h4>
              </div>
              <div className="col-lg-3 col-6">
                <div className="img_area">
                  <img src="/images/aibigdata-icon_01.png" alt="Prediction and Analysis" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">Prediction and Analysis</h5>
                  <p className="desc">AI enables prediction and analysis</p>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="img_area">
                  <img src="/images/aibigdata-icon_02.png" alt="Environment Monitoring" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">Environment Monitoring</h5>
                  <p className="desc">Increased accuracy of environmental monitoring</p>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="img_area">
                  <img src="/images/aibigdata-icon_03.png" alt="Usage analysis" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">Usage analysis</h5>
                  <p className="desc">Accurate usage analysis is shown systematically</p>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="img_area">
                  <img src="/images/aibigdata-icon_04.png" alt="Knowledge provision" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">Knowledge provision</h5>
                  <p className="desc">Increased convenience by providing knowledge</p>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="img_area">
                  <img src="/images/aibigdata-icon_05.png" alt="Personalization Notification" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">Personalization Notification</h5>
                  <p className="desc">Systematic management through notification function</p>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="img_area">
                  <img src="/images/aibigdata-icon_06.png" alt="AI/ML/DL engine" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">AI/ML/DL engine</h5>
                  <p className="desc">Learning and prediction through original data, data processing, and non-identification processing</p>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="img_area">
                  <img src="/images/aibigdata-icon_07.png" alt="Data collection/storage" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">Data collection/storage</h5>
                  <p className="desc">
                    Convenient service is available through data collection <br />
                    and storage
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="img_area">
                  <img src="/images/aibigdata-icon_08.png" alt="Sensor/device management" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">Sensor/device management</h5>
                  <p className="desc">
                    Improved work efficiency <br />
                    by managing sensors <br />
                    and devices
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section">
          <div className="container">
            <div className="row">
              <div className="col-12 tit_underline">
                <h4 className="tit_md">Product Case</h4>
                <div className="desc">
                  <p>This is an example of AI products.</p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="thumb_gradient">
                  <Link to="/en/service/aibworks" state={{ activeTab: 'aibworks' }}>
                    <img src="/images/case_commute.png" alt="aibworks" />
                  </Link>
                </div>
              </div>
              <div className="col-md-4">
                <div className="thumb_gradient">
                  <Link to="/en/service/aibworks" state={{ activeTab: 'Smart Toilet' }}>
                    <img src="/images/case_toilet.png" alt="smartToilet" />
                  </Link>
                </div>
              </div>
              <div className="col-md-4">
                <div className="thumb_gradient">
                  <Link to="/en/service/aibworks" state={{ activeTab: 'monitoringEnv' }}>
                    <img src="/images/case_environment.png" alt="Monitoring Environment" />
                  </Link>
                </div>
              </div>
              <div className="col-md-4">
                <div className="thumb_gradient">
                  <p>
                    <img src="/images/case_waterflow.png" alt="Water Flow" />
                  </p>
                </div>
                <div className="text_area">
                  <h5 className="tit_sm">Daejeon city water supply analysis service</h5>
                  <ul className="circle_list">
                    <li>Water supply management using big data </li>
                    <li>Leakage and usage prediction model development</li>
                    <li>Development of prediction technology using deep learning techniques</li>
                    <li>Plans to apply to other regions</li>
                  </ul>
                </div>
              </div>
              <div className="col-md-4">
                <div className="thumb_gradient">
                  <p>
                    <img src="/images/case_smartcity.png" alt="smart city" />
                  </p>
                </div>
                <div className="text_area">
                  <h5 className="tit_sm">Busan smart city bigdata service</h5>
                  <ul className="circle_list">
                    <li>Service-related big data POC service derivation</li>
                    <li>Big data module design based on smart city IoT platform</li>
                    <li>Completion of POC and plan using big data</li>
                    <li>POC service advancement and optimization</li>
                  </ul>
                </div>
              </div>
              <div className="col-md-4">
                <div className="thumb_gradient">
                  <p>
                    <img src="/images/case_kamp.png" alt="AI Manufacturing Platform service" />
                  </p>
                </div>
                <div className="text_area">
                  <h5 className="tit_sm">AI Manufacturing Platform service</h5>
                  <ul className="circle_list">
                    <li>AI smart factory and AI platform development </li>
                    <li>Collection, storage and analysis platform, AI expert, service management</li>
                    <li>CNC, Data management measured by sensors such as machine vision</li>
                    <li>Standards-based technology development that can be standardized</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section pt-0">
          <div className="container">
            <div className="row">
              <div className="col-12 tit_underline">
                <h4 className="tit_md">Major client company</h4>
                <div className="desc">
                  <p>It is a major customer of the AIBLab IoT platform.</p>
                </div>
              </div>
              <div className="col-12">
                <div id="aiPartnerCarousel" className="carousel slide" data-bs-ride="true">
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <ul className="d-flex">
                        <li>
                          <img src="/images/partners/handysoft.jpg" alt="handysoft" />
                        </li>
                        <li>
                          <img src="/images/partners/dasannetworks.png" alt="dasannetworks" />
                        </li>
                        <li>
                          <img src="/images/partners/daejeon.png" alt="daejeon" />
                        </li>
                        <li>
                          <img src="/images/partners/busan.png" alt="busan" />
                        </li>
                      </ul>
                    </div>
                    <div className="carousel-item">
                      <ul className="d-flex">
                        <li>
                          <img src="/images/partners/gyeongnam.png" alt="gyeongnam" />
                        </li>
                        <li>
                          <img src="/images/partners/mss.png" alt="mss" />
                        </li>
                      </ul>
                    </div>
                  </div>
                  <ul className="carousel-indicators">
                    <li data-bs-target="#aiPartnerCarousel" data-bs-slide-to="0" className="active"></li>
                    <li data-bs-target="#aiPartnerCarousel" data-bs-slide-to="1"></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>        
      </div>
    </>
  );
}
