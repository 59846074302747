import React from 'react';

export default function AiVoucher() {
  return (
    <main id="main">
      <div className="content_heading bg_voucher">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="tit_xl">AI 바우처</h2>
              <ol className="breadcrumb">
                <li>Home</li>
                <li>지원사업</li>
                <li className="active">AI 바우처</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="section">
          <div className="container">
            <div className="row">
              <div className="col-12 img_area">
                <div className="voucher">
                  <img src="/images/voucher_01.png" alt="" />
                  <img src="/images/voucher_02.png" alt="" />
                  <img src="/images/voucher_03.png" alt="" />
                  <a href="http://aiblab.co.kr/file/Data_voucher-2022.pdf" target="_blank">
                    <img src="/images/voucher_04.png" alt="" />
                  </a>
                  <img src="/images/voucher_05.png" alt="" />
                  <img src="/images/voucher_06.png" alt="" />
                  <a href="https://docs.google.com/forms/d/1in4xwAHdzdkBfr-WvlKwDknU92Fv2hae2VoAcx5c6JU/edit" target="_blank">
                    <img src="/images/voucher_07.png" alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
