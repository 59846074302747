import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { apis } from '../../api/aiblab';
import { FaAngleRight } from 'react-icons/fa';
import { BiLink } from 'react-icons/bi';
import ReactPagination from '../../components/ReactPagination';

export default function Media() {
  const [keyword, setKeyword] = useState('');
  const [prList, setPrList] = useState([]);
  const [pageInfo, setPageInfo] = useState({
    pageNo: 1,
    pageSize: 10,
    totalRowCount: 10,
  });
  const { pageNo, pageSize, totalRowCount } = pageInfo;

  const loadPrList = () => {
    apis
      .getMediaList(pageNo, pageSize, keyword)
      .then((response) => {
        if (response.data) {
          setPrList(response.data.items);
          setPageInfo(response.data.pagination);
        }
      })
      .catch((error) => console.log(error.response));
  };

  const handleChangePage = (pageNo) => {
    setPageInfo((prev) => ({
      ...prev,
      pageNo,
    }));
  };
  const [pr, setPr] = useState({});

  const renderPrById = (e, item) => {
    let isExpanded = e.currentTarget.ariaExpanded;
    const { directLink, linkUrl, promotionId } = item;

    if (directLink === 1) {
      window.open('about:blank').location.href = linkUrl;
    }

    if (isExpanded === 'true') {
      apis
        .getMediaById(promotionId)
        .then(async (response) => {
          if (response.contents) {
            const myIframe = document.getElementById(`myIframe${response.promotionId}`);
            const iframeDocument = myIframe.contentDocument;

            iframeDocument.open();

            await iframeDocument.write(response.contents);

            // 이미지 스타일링
            let images = iframeDocument.querySelectorAll('img');
            images.forEach(function (img) {
              img.style.maxWidth = '100%';
            });
            // 9:16 비율 동영상 스타일링
            let iframes = iframeDocument.querySelectorAll('iframe');
            iframes.forEach(function (iframe) {
              iframe.parentNode.style.cssText = 'position: relative; width: 100%; max-width: 100%; padding-top: 56.25%';
              iframe.style.cssText = 'position: absolute; top: 0; left: 0; width: 100%; height: 100%;';
            });
            // body 마진 제거
            iframeDocument.body.style.margin = 0;
            // 높이 지정
            myIframe.style.height = `${iframeDocument.body.offsetHeight}px`;

            iframeDocument.close();
          }
          setPr(response);
        })
        .catch((error) => console.log(error.response));
    }
  };

  useEffect(() => {
    loadPrList();
  }, [pageNo]);

  return (
    <>
      <div className="content_heading pg_pr">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="tit_xl">언론홍보</h2>
              <ol className="breadcrumb">
                <li>Home</li>
                <li>PR</li>
                <li className="active">언론홍보</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="tit_underline">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2 className="tit_lg">언론홍보</h2>
                <p className="desc">아이브랩의 최신 기사입니다.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="notice">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div id="mediaAccordion">
                  {prList.length === 0 ? (
                    <div className="accordion-item">
                      <div className="accordion-header">
                        <p className="accordion-button none">게시물이 존재하지 않습니다.</p>
                      </div>
                    </div>
                  ) : (
                    prList.map((item, index) => (
                      <div className="accordion-item" key={item.promotionId}>
                        <div className="accordion-header">
                          <a className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target={`#mediaArticle_${item.promotionId}`} onClick={(e) => renderPrById(e, item)}>
                            <div className="notice_num">{totalRowCount - index - (pageNo - 1) * pageSize}</div>
                            <div className="notice_tit">
                              <span className="media">[{item.category}]</span>
                              <span>{item.title}</span>
                            </div>
                            <div className="notice_date">{item.createTime}</div>
                            {item.directLink === 1 ? <BiLink className="ico_link" /> : <FaAngleRight />}
                          </a>
                        </div>
                        <div id={`mediaArticle_${item.promotionId}`} className={`accordion-collapse collapse ${item.directLink === 1 ? 'd-none' : ''}`} data-bs-parent="#mediaAccordion">
                          <div className="accordion-body">
                            <iframe id={`myIframe${item.promotionId}`} width="100%" scrolling="no" />
                          </div>
                        </div>
                      </div>
                    ))
                  )}
                </div>
                <ReactPagination pageInfo={pageInfo} handleChangePage={handleChangePage} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
