import React from 'react';
import { Link } from 'react-router-dom';

export default function EnHistory() {
  return (
    <>
      <div className="content_heading bg_introduce">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="tit_xl">History</h2>
              <ol className="breadcrumb">
                <li>Home</li>
                <li>AIBLab</li>
                <li className="active">History</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="section">
          <div className="container">
            <div className="row">
              <div className="col-12 tit_underline">
                <h2 className="tit_lg">History</h2>
                <p className="desc">AIBLab has innovated and we will develop further in the future.</p>
              </div>
              <div className="col-12">
                <ul className="history_list">
                  <li>
                    <img className="item_img" src="/images/history_1.jpg" alt="2019" />
                    <dl className="item_text">
                      <dt className="tit_sm">
                        <span>2019</span>
                        <span className="sub_tit">Establishment of AIBLab</span>
                      </dt>
                      <dd className="desc">
                        <ul className="circle_list">
                          <li>Established technology-attached laboratory</li>
                          <li>AI, IoT, Bigdata technology company Development of intelligent platform for life safety prevention service</li>
                          <li>Handysoft IoT division spin-off</li>
                        </ul>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <img className="item_img" src="/images/history_2.jpg" alt="2018" />
                    <dl className="item_text">
                      <dt className="tit_sm">2018</dt>
                      <dd className="desc">
                        <ul className="circle_list">
                          <li>Smart Air Mat Commercialization (RayCop)</li>
                          <li>
                            Smart office Commercialization
                            <ul className="dash_list">
                              <li>Telkomsel Smart Office, Indonesia's state-run news agency</li>
                              <li>Innoton Shared Office</li>
                            </ul>
                          </li>
                          <li>smart scale (Seven Elec)</li>
                          <li>Internet of Things International Exhibition 2020</li>
                          <li>IoT AWARD 18 WINNER</li>
                        </ul>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <img className="item_img" src="/images/history_3.jpg" alt="2017" />
                    <dl className="item_text">
                      <dt className="tit_sm">2017</dt>
                      <dd className="desc">
                        <ul className="circle_list">
                          <li>Smart Health Commercialization - Race Maker(Good people)</li>
                          <li>Las Vegas CES Exhibition 2017</li>
                          <li>Internet of Things International Exhibition 2017</li>
                        </ul>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <img className="item_img" src="/images/history_4.jpg" alt="2016" />
                    <dl className="item_text">
                      <dt className="tit_sm">2016</dt>
                      <dd className="desc">
                        <ul className="circle_list">
                          <li>Smart bed commercialization (Funistem)</li>
                          <li>IoT international standard oneM2M certification</li>
                          <li>Securing base technologies through cooperation in ETRI technology development</li>
                          <li>HANDYPIA IoT Platform Internet service</li>
                          <li>Las Vegas CES Exhibition 2016</li>
                          <li>Internet of Things International Exhibition 2016</li>
                        </ul>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <img className="item_img" src="/images/history_5.jpg" alt="2015" />
                    <dl className="item_text">
                      <dt className="tit_sm">2015</dt>
                      <dd className="desc">
                        <ul className="circle_list">
                          <li>Smart office solutions release</li>
                          <li>Smart boiler release (Cricket boiler)</li>
                          <li>Smart Loss Prevention Solution release (Alton bicycle)</li>
                          <li>Las Vegas CES Exhibition 2015</li>
                          <li>Internet of Things International Exhibition 2015</li>
                        </ul>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <img className="item_img" src="/images/history_6.jpg" alt="2014" />
                    <dl className="item_text">
                      <dt className="tit_sm">2014</dt>
                      <dd className="desc">
                        <ul className="circle_list">
                          <li>Establishment of Handysoft Platform Laboratory</li>
                          <li>IoT Platform business</li>
                          <li>HANDYPIA IoT Platform launch</li>
                        </ul>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <img className="item_img" src="/images/history_7.jpg" alt="AIBLab" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
