import React from 'react';
import { Link } from 'react-router-dom';

export default function SmartFactory() {
  return (
    <>
      <div className="content_heading bg_product">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="tit_xl">스마트 팩토리</h2>
              <ol className="breadcrumb">
                <li>Home</li>
                <li>솔루션</li>
                <li className="active">스마트 팩토리</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="tit_underline">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2 className="tit_lg">Smart Factory</h2>
                <p className="desc">IoT 기반 스마트 팩토리</p>
              </div>
            </div>
          </div>
        </div>
        <div className="section bg_g">
          <div className="container">
            <div className="row">
              <div className="col-12 img_area">
                <img src="/images/main_img-factory.jpg" alt="Smart Factory" />
              </div>
              <div className="col-12 row text_area">
                <h3 className="col-lg-4 tit_md">Smart Factory</h3>
                <p className="col-lg-8 desc">설계·개발, 제조 및 유통·물류 등 생산과정에 디지털 자동화 솔루션이 결합된 정보통신기술(ICT)을 적용하여 공장 자동화와 정보화 사업을 바탕으로 지능형 공장을 제어할 수 있습니다.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="section landing">
          <div className="container">
            <div className="row">
              <div className="col-md-6 img_area">
                <img src="/images/factory_landing_01.jpg" alt="실시간 관리 및 대응" />
              </div>
              <div className="col-md-6 text_area">
                <h4 className="tit_area">
                  <p className="numbering">
                    <span>01</span>
                    <small>Features</small>
                  </p>
                  <p className="tit_lg">실시간 관리 및 대응</p>
                </h4>
                <ul className="dash_list">
                  <li>실시간으로 전체 생산 라인을 관리</li>
                  <li>빠르고 정확한 의사결정의 장</li>
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 img_area">
                <img src="/images/factory_landing_02.jpg" alt="안전한 작업 환경" />
              </div>
              <div className="col-md-6 text_area">
                <h4 className="tit_area">
                  <p className="numbering">
                    <span>02</span>
                    <small>Features</small>
                  </p>
                  <p className="tit_lg">안전한 작업 환경</p>
                </h4>
                <ul className="dash_list">
                  <li>여러 센서들의 분석으로 시설의 안전 확보</li>
                  <li>안전한 공장 환경에 따른 직원 안전 확보</li>
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 img_area">
                <img src="/images/factory_landing_03.jpg" alt="제조업의 혁신" />
              </div>
              <div className="col-md-6 text_area">
                <h4 className="tit_area">
                  <p className="numbering">
                    <span>03</span>
                    <small>Features</small>
                  </p>
                  <p className="tit_lg">제조업의 혁신</p>
                </h4>
                <ul className="dash_list">
                  <li>고객의 요구에 따른 품질 생산 및 고객 만족도를 향상시키는 지능형 생산공장</li>
                  <li>중장기적 관점의 공장 효율화 방안을 수립</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="section bg_g">
          <div className="container">
            <div className="row">
              <div className="col-12 tit_underline">
                <h2 className="tit_md">주요기능</h2>
              </div>
              <div className="col-lg-3 col-6">
                <div className="img_area">
                  <img src="/images/factory-icon_01.png" width="30%" alt="상태 모니터링" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">상태 모니터링</h5>
                  <p className="desc">
                    공장 고장 진단 및 소모품
                    <br />
                    교체 주기 알람
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="img_area">
                  <img src="/images/factory-icon_02.png" width="30%" alt="사고감지" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">사고감지</h5>
                  <p className="desc">
                    공장 사고 감지 및<br />
                    긴급상황 지원
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="img_area">
                  <img src="/images/factory-icon_03.png" width="30%" alt="맞춤형 생산" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">맞춤형 생산</h5>
                  <p className="desc">
                    고객이 원하는 디자인의
                    <br />
                    맞춤형 제품 생산
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="img_area">
                  <img src="/images/factory-icon_04.png" width="30%" alt="공장 운영 절감" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">공장 운영 절감</h5>
                  <p className="desc">자동화 시스템으로 운영 절감</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-6">
                <div className="thumb_gradient">
                  <Link to="/case/smartOffice">
                    <img src="/images/case_office.png" alt="스마트 오피스" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-6">
                <div className="thumb_gradient">
                  <Link to="/case/smartHome">
                    <img src="/images/case_home.png" alt="스마트 홈" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-6">
                <div className="thumb_gradient">
                  <Link to="/case/smartHealthcare">
                    <img src="/images/case_healthcare.png" alt="스마트 헬스케어" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-6">
                <div className="thumb_gradient">
                  <Link to="/case/smartBuilding">
                    <img src="/images/case_building.png" alt="스마트 빌딩" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-6">
                <div className="thumb_gradient">
                  <Link to="/case/smartCar">
                    <img src="/images/case_car.png" alt="스마트 카" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-6">
                <div className="thumb_gradient">
                  <Link to="/case/smartFactory">
                    <img src="/images/case_factory.png" alt="스마트 팩토리" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
