import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { apis } from '../../api/aiblab';
import { FaAngleRight } from 'react-icons/fa';
import ReactPagination from '../../components/ReactPagination';

export default function Recruitment() {
  const [keyword, setKeyword] = useState('');
  const [recruitList, setRecruitList] = useState([]);
  const [pageInfo, setPageInfo] = useState({
    pageNo: 1,
    pageSize: 10,
    totalRowCount: 10,
  });
  const { pageNo, pageSize, totalRowCount } = pageInfo;

  const loadRecruitList = () => {
    apis
      .getRecruitList(pageNo, pageSize, keyword)
      .then((response) => {
        if (response.data) {
          setRecruitList(response.data.items);
          setPageInfo(response.data.pagination);
        }
      })
      .catch((error) => console.log(error.response));
  };

  const handleChangePage = (pageNo) => {
    setPageInfo((prev) => ({
      ...prev,
      pageNo,
    }));
  };

  const [recruit, setRecruit] = useState({});

  const showRecruitById = (e, { announcementId }) => {
    let isExpanded = e.currentTarget.ariaExpanded;

    if (isExpanded === 'true') {
      apis
        .getRecruitById(announcementId)
        .then(async (response) => {
          if (response.contents) {
            const myIframe = document.getElementById(`myIframe${response.announcementId}`);
            const iframeDocument = myIframe.contentDocument;

            iframeDocument.open();

            await iframeDocument.write(response.contents);

            // 이미지 스타일링
            let images = iframeDocument.querySelectorAll('img');
            images.forEach(function (img) {
              img.style.maxWidth = '100%';
            });
            // 9:16 비율 동영상 스타일링
            let iframes = iframeDocument.querySelectorAll('iframe');
            iframes.forEach(function (iframe) {
              iframe.parentNode.style.cssText = 'position: relative; width: 100%; max-width: 100%; padding-top: 56.25%';
              iframe.style.cssText = 'position: absolute; top: 0; left: 0; width: 100%; height: 100%;';
            });
            // body 마진 제거
            iframeDocument.body.style.margin = 0;
            // 높이 지정
            myIframe.style.height = `${iframeDocument.body.offsetHeight}px`;

            iframeDocument.close();
          }
          setRecruit(response);
        })
        .catch((error) => console.log(error.response));
    }
  };

  useEffect(() => {
    loadRecruitList();
  }, [pageNo]);

  return (
    <>
      <div className="content_heading bg_crew">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="tit_xl">채용 공고</h2>
              <ol className="breadcrumb">
                <li>Home</li>
                <li>채용</li>
                <li className="active">채용 공고</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="tit_underline">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2 className="tit_lg">채용 공고</h2>
                <p className="desc">아이브랩의 현재 진행중인 공고입니다.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="notice">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div id="recruitmentAccordion">
                  {recruitList.length === 0 ? (
                    <div className="accordion-item">
                      <div className="accordion-header">
                        <p className="accordion-button none">게시물이 존재하지 않습니다.</p>
                      </div>
                    </div>
                  ) : (
                    recruitList.map((item, index) => (
                      <div className="accordion-item" key={item.announcementId}>
                        <div className="accordion-header">
                          <a id={item.announcementId} className="accordion-button collapsed" data-bs-target={`#recruitArticle_${item.announcementId}`} data-bs-toggle="collapse" onClick={(e) => showRecruitById(e, item)}>
                            <div className="notice_num">{totalRowCount - index - (pageNo - 1) * pageSize}</div>
                            <div className="notice_tit">
                              <span>[{item.category}]</span>
                              <span>{item.title}</span>
                              <span className="sub_tit">{item.status === 1 ? '채용종료' : '진행중'}</span>
                            </div>
                            <FaAngleRight />
                          </a>
                        </div>
                        <div id={`recruitArticle_${item.announcementId}`} className="accordion-collapse collapse" data-bs-parent="#recruitmentAccordion">
                          <div className="accordion-body">
                            <iframe id={`myIframe${item.announcementId}`} width="100%" scrolling="no" />
                          </div>
                        </div>
                      </div>
                    ))
                  )}
                </div>
                <ReactPagination pageInfo={pageInfo} handleChangePage={handleChangePage} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
