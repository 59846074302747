import React from 'react';
import { Link } from 'react-router-dom';

export default function EnSmartHealthcare() {
  return (
    <>
      <div className="content_heading bg_product">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="tit_xl">Smart Healthcare</h2>
              <ol className="breadcrumb">
                <li>Home</li>
                <li>Solution</li>
                <li className="active">Smart Healthcare</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="tit_underline">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2 className="tit_lg">Smart Healthcare</h2>
                <p className="desc">
                  <span>IoT-based healthcare system for body information monitoring </span>
                  <span>and health management services</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="section bg_g">
          <div className="container">
            <div className="row">
              <div className="col-12 img_area">
                <img src="/images/main_img-healthcare__eng.jpg" alt="Smart Healthcare" />
              </div>
              <div className="col-12 row text_area">
                <h3 className="col-lg-4 tit_md">Smart Healthcare</h3>
                <p className="col-lg-8 desc">
                  It provides condition index and health information services such as heart rate, body temperature, and exercise amount through smart wearables, and provides a scalable healthcare platform with various sensors and additional services.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="section landing">
          <div className="container">
            <div className="row">
              <div className="col-md-6 img_area">
                <img src="/images/healthcare_landing_01__eng.jpg" alt="Smart healthcare solutions" />
              </div>
              <div className="col-md-6 text_area">
                <h4 className="tit_area">
                  <p className="numbering">
                    <span>01</span>
                    <small>Features</small>
                  </p>
                  <p className="tit_lg">Smart healthcare solutions</p>
                </h4>
                <ul className="dash_list">
                  <li>Biometric sensing, monitoring, and health care using smart sportswear</li>
                  <li>Daily Smart Health Care with the User's Condition Index</li>
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 img_area">
                <img src="/images/healthcare_landing_02__eng.jpg" alt="Measure the amount of exercise and distance of exercise using smart" />
              </div>
              <div className="col-md-6 text_area">
                <h4 className="tit_area">
                  <p className="numbering">
                    <span>02</span>
                    <small>Features</small>
                  </p>
                  <p className="tit_lg">Measure the amount of exercise and distance of exercise using smart wearables</p>
                </h4>
                <ul className="dash_list">
                  <li>Smart exercise management through heart rate/workout/weight management</li>
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 img_area">
                <img src="/images/healthcare_landing_03__eng.jpg" alt="Provide smart healthcare ecosystem" />
              </div>
              <div className="col-md-6 text_area">
                <h4 className="tit_area">
                  <p className="numbering">
                    <span>03</span>
                    <small>Features</small>
                  </p>
                  <p className="tit_lg">Provide smart healthcare ecosystem</p>
                </h4>
                <ul className="dash_list">
                  <li>Application developers, smart health service providers, and content providers participate together to form a smart healthcare ecosystem</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="section bg_g">
          <div className="container">
            <div className="row">
              <div className="col-12 tit_underline">
                <h2 className="tit_md">Main function</h2>
              </div>
              <div className="col-lg-3 col-6">
                <div className="img_area">
                  <img src="/images/healthcare-icon_01.png" width="30%" alt="Smart clothing, sensors" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">Smart clothing, sensors</h5>
                  <p className="desc">
                    Functional body wear through <br />
                    ergonomic pattern design <br />
                    and ICT convergence
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="img_area">
                  <img src="/images/healthcare-icon_02.png" width="30%" alt="Smart device" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">Smart device</h5>
                  <p className="desc">
                    bio-sensors (ECG-heart, body temperature), <br />
                    NFC and BT-enabled batteries, <br />
                    charging modules
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="img_area">
                  <img src="/images/healthcare-icon_03.png" width="30%" alt="Healthcare Application" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">Healthcare Application</h5>
                  <p className="desc">
                    Heart rate, temperature, <br />
                    inner wear sensing monitoring, health information service
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="img_area">
                  <img src="/images/healthcare-icon_04.png" width="30%" alt="Healthcare Analysis Service" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">Healthcare Analysis Service</h5>
                  <p className="desc">Provide a daily condition index of 10 levels, health abnormalities notification service</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-6">
                <div className="thumb_gradient">
                  <Link to="/en/case/smartOffice">
                    <img src="/images/case_office.png" alt="Smart Office" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-6">
                <div className="thumb_gradient">
                  <Link to="/en/case/smartHome">
                    <img src="/images/case_home.png" alt="Smart Home" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-6">
                <div className="thumb_gradient">
                  <Link to="/en/case/smartHealthcare">
                    <img src="/images/case_healthcare.png" alt="Smart Healthcare" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-6">
                <div className="thumb_gradient">
                  <Link to="/en/case/smartBuilding">
                    <img src="/images/case_building.png" alt="Smart Building" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-6">
                <div className="thumb_gradient">
                  <Link to="/en/case/smartCar">
                    <img src="/images/case_car.png" alt="Smart Car" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-6">
                <div className="thumb_gradient">
                  <Link to="/en/case/smartFactory">
                    <img src="/images/case_factory.png" alt="Smart Factory" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
