import React from 'react';
import { useNavigate } from 'react-router-dom';
import error from './error.svg';
import styles from './NotFound.module.css';

export default function NotFound() {
  const navigate = useNavigate();
  return (
    <div className={styles.page}>
      <div className={styles.content}>
        <img src={error} className={styles.img} alt="404 에러" />
        <h2 className={styles.title}>요청하신 페이지를 찾을 수 없습니다</h2>
        <p className={styles.description}>
          방문하시려는 페이지의 주소가 잘못 입력되었거나, <br />
          페이지의 주소가 변경 혹은 삭제되어 요청하신 페이지를 찾을 수 없습니다.
        </p>
        <button className={styles.button} onClick={() => navigate('/')}>
          홈으로 이동
        </button>
      </div>
    </div>
  );
}
