import React from 'react';
import { Link } from 'react-router-dom';

export default function EnGreeting() {
  return (
    <>
      <div className="content_heading bg_introduce">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="tit_xl">CEO Greeting</h2>
              <ol className="breadcrumb">
                <li>Home</li>
                <li>AIBLab</li>
                <li className="active">CEO Greeting</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="section">
          <div className="container">
            <div className="row">
              <div className="col-12 tit_underline">
                <h2 className="tit_lg">CEO Greeting</h2>
                <p className="desc">Greeting from CEO of AIBlab</p>
              </div>
              <div className="col-12">
                <img src="/images/main_img-greeting.jpg" alt="Greeting from CEO of AIBlab" />
                <div className="desc">
                  <p>AIBLab is making efforts to make the current life richer and create a better future value by utilizing artificial intelligence, IoT, and big data technologies, which are the core technologies of the 4th Industrial Revolution.</p>
                  <p>To do so, we will develop the best human resources, secure technological superiority, create the best products and valuable services, enrich our present lives and contribute to the prosperity of humanity in the future.</p>
                </div>
                <p className="remark">
                  AIBLab CEO <strong>YoungJu Seo, YongWoo Kim</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
