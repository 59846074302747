import React from 'react';
import { Link } from 'react-router-dom';

export default function EnIot() {
  return (
    <>
      <div className="content_heading bg_product">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="tit_xl">IoT Platform</h2>
              <ol className="breadcrumb">
                <li>Home</li>
                <li>AIBLab Platform</li>
                <li className="active">IoT Platform</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="tit_underline">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h3 className="tit_lg">IoT Platform</h3>
                <p className="desc">
                  <span>AIBLab IoT platform that enables IoT services </span>
                  <span>easily and efficiently</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="section bg_g">
          <div className="container">
            <div className="row">
              <div className="col-12 img_area">
                <img src="/images/main_img-iot.jpg" alt="iot platform" />
              </div>
              <div className="col-12 row text_area">
                <h4 className="col-lg-4 tit_md">IoT Platform</h4>
                <p className="col-lg-8 desc">
                  All objects can be connected to the Internet to interact with each other, provide real-time sensing information services for users to easily access anytime, anywhere, and conduct service businesses using various devices.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="section">
          <div className="container">
            <div className="row">
              <div className="col-12 tit_underline">
                <h4 className="tit_md">Product specification</h4>
                <div className="desc">
                  <p>This is the specification of AIBLab product.</p>
                </div>
              </div>
              <div className="col-12">
                <ul className="nav nav-tabs">
                  <li className="nav-item">
                    <a className="nav-link active" data-bs-target="#iotTab_01" data-bs-toggle="tab">
                      IoT platform server
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" data-bs-target="#iotTab_02" data-bs-toggle="tab">
                      Device gateway
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" data-bs-target="#iotTab_03" data-bs-toggle="tab">
                      Device firmware
                    </a>
                  </li>
                </ul>
                <div className="tab-content">
                  <div id="iotTab_01" className="tab-pane fade show active">
                    <div className="d-flex">
                      <div className="img_area">
                        <img src="/images/iot-tab_1.png" width="70%" alt="IoT platform server" />
                      </div>
                      <div className="text_area">
                        <h5 className="tit_sm">IoT platform server</h5>
                        <ul className="desc">
                          <li>PaaS IoT platform server (multi-tenant)</li>
                          <li>OneM2M(IN) mounting support</li>
                          <li>NoSQL-based large data management</li>
                          <li>Open API support</li>
                          <li>Efficient Scale-out</li>
                          <hr />
                          <li>
                            IN (Infrastructure Node)
                            <br />
                          </li>
                          <li>IoT Server</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div id="iotTab_02" className="tab-pane fade">
                    <div className="d-flex">
                      <div className="img_area">
                        <img src="/images/iot-tab_2.png" width="70%" alt="Device gateway" />
                      </div>
                      <div className="text_area">
                        <h5 className="tit_sm">디바이스 게이트웨이</h5>
                        <ul className="desc">
                          <li>WiFi, BLE, LoRA support</li>
                          <li>Equipped with oneM2M(MN) or edgeX Open sw</li>
                          <li>HTTP/CoAP, MQTT support</li>
                          <li>Data collection and management Device connection management</li>
                          <hr />
                          <li>BLE (Bluetooth Low Energy), LoRa (Long Range), MN (Middle Node), M2M Gateway</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div id="iotTab_03" className="tab-pane fade">
                    <div className="d-flex">
                      <div className="img_area">
                        <img src="/images/iot-tab_3.png" width="70%" alt="Device firmware" />
                      </div>
                      <div className="text_area">
                        <h5 className="tit_sm">디바이스 펌웨어</h5>
                        <ul className="desc">
                          <li>LwM2M Specification Support</li>
                          <li>Development Library Support</li>
                          <li>Remote firmware update support</li>
                          <hr />
                          <li>LwM2M (Lightweight M2M)</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section bg_g">
          <div className="container">
            <div className="row">
              <div className="col-12 tit_underline">
                <h4 className="tit_md">Main function</h4>
              </div>
              <div className="col-lg-3 col-6">
                <div className="img_area">
                  <img src="/images/iot-icon_01.png" alt="Open API" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">Open API</h5>
                  <p className="desc">
                    Use open API's to easily develop <br />
                    additional services
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="img_area">
                  <img src="/images/iot-icon_02.png" alt="Security" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">Security</h5>
                  <p className="desc">
                    Safe maintenance/management <br />
                    of service data and devices
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="img_area">
                  <img src="/images/iot-icon_03.png" alt="Repository" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">Repository</h5>
                  <p className="desc">
                    Various IoT data collected/stored <br />
                    in cloud storage
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="img_area">
                  <img src="/images/iot-icon_04.png" alt="Pay per Use" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">Pay per Use</h5>
                  <p className="desc">
                    Number of users, number of devices, data usage <br />
                    flexible pricing policy
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="img_area">
                  <img src="/images/iot-icon_05.png" alt="Device" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">Device</h5>
                  <p className="desc">
                    Can register both standard <br />
                    and non-standard IoT devices
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="img_area">
                  <img src="/images/iot-icon_06.png" alt="User" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">User</h5>
                  <p className="desc">
                    Easily register <br />
                    and manage service users
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="img_area">
                  <img src="/images/iot-icon_07.png" alt="Report" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">Report</h5>
                  <p className="desc">
                    Monitoring registered devices <br />
                    and providing remote <br />
                    management service
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="img_area">
                  <img src="/images/iot-icon_08.png" alt="Notification" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">Notification</h5>
                  <p className="desc">
                    Create events and easily connect to external services <br />
                    by setting up service rules
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="img_area">
                  <img src="/images/iot-icon_09.png" alt="Mobile" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">Mobile</h5>
                  <p className="desc">
                    Easily deliver mobile-based services through <br />
                    registered devices
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="img_area">
                  <img src="/images/iot-icon_10.png" alt="Semantic Engine" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">Semantic Engine</h5>
                  <p className="desc">
                    Providing sophisticated knowledge services through ontology <br />
                    and reasoning engines
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="img_area">
                  <img src="/images/iot-icon_11.png" alt="Analytics" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">Analytics</h5>
                  <p className="desc">
                    Deliver optimal business <br />
                    decision-making solutions through <br />
                    big data analytics
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="img_area">
                  <img src="/images/iot-icon_12.png" alt="Process" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">Process</h5>
                  <p className="desc">Provide high-level convergence services by connecting various service functions</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section">
          <div className="container">
            <div className="row">
              <div className="col-12 tit_underline">
                <h4 className="tit_md">Product Case</h4>
                <div className="desc">
                  <p>This is an example of IoT products.</p>
                </div>
              </div>
              <div className="col-lg-4 col-6">
                <div className="thumb_gradient">
                  <Link to="/en/case/smartOffice">
                    <img src="/images/case_office.png" alt="Smart Office" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-6">
                <div className="thumb_gradient">
                  <Link to="/en/case/smartHome">
                    <img src="/images/case_home.png" alt="Smart Home" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-6">
                <div className="thumb_gradient">
                  <Link to="/en/case/smartHealthcare">
                    <img src="/images/case_healthcare.png" alt="Smart Healthcare" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-6">
                <div className="thumb_gradient">
                  <Link to="/en/case/smartBuilding">
                    <img src="/images/case_building.png" alt="Smart Building" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-6">
                <div className="thumb_gradient">
                  <Link to="/en/case/smartCar">
                    <img src="/images/case_car.png" alt="Smart Car" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-6">
                <div className="thumb_gradient">
                  <Link to="/en/case/smartFactory">
                    <img src="/images/case_factory.png" alt="Smart Factory" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section pt-0">
          <div className="container">
            <div className="row">
              <div className="col-12 tit_underline">
                <h4 className="tit_md">Major client company</h4>
                <div className="desc">
                  <p>It is a major customer of the AIBLab IoT platform.</p>
                </div>
              </div>
              <div className="col-12">
                <div id="iotPartnerCarousel" className="carousel slide" data-bs-ride="true">
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <ul className="d-flex">
                        <li>
                          <img src="/images/partners/goodpeople.png" alt="goodpeople" />
                        </li>
                        <li>
                          <img src="/images/partners/kiturami.png" alt="kiturami" />
                        </li>
                        <li>
                          <img src="/images/partners/raycop.png" alt="raycop" />
                        </li>
                        <li>
                          <img src="/images/partners/telcomsel.png" alt="telcomsel" />
                        </li>
                      </ul>
                    </div>
                    <div className="carousel-item">
                      <div className="d-flex">
                        <li>
                          <img src="/images/partners/furnistem.png" alt="furnistem" />
                        </li>
                        <li>
                          <img src="/images/partners/alton.png" alt="alton" />
                        </li>
                        <li>
                          <img src="/images/partners/dasannetworks.png" alt="dasannetworks" />
                        </li>
                        <li>
                          <img src="/images/partners/srbgas.png" alt="srbgas" />
                        </li>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <div className="d-flex">
                        <li>
                          <img src="/images/partners/nipa.png" alt="nipa" />
                        </li>
                        <li>
                          <img src="/images/partners/amosense.png" alt="amosense" />
                        </li>
                        <li>
                          <img src="/images/partners/amano.png" alt="amano" />
                        </li>
                        <li>
                          <img src="/images/partners/sktelink.png" alt="sktelink" />
                        </li>
                      </div>
                    </div>
                  </div>
                  <ul className="carousel-indicators">
                    <li data-bs-target="#iotPartnerCarousel" data-bs-slide-to="0" className="active"></li>
                    <li data-bs-target="#iotPartnerCarousel" data-bs-slide-to="1"></li>
                    <li data-bs-target="#iotPartnerCarousel" data-bs-slide-to="2"></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
