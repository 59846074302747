import React from 'react';
import { Link } from 'react-router-dom';

export default function EnLocation() {
  return (
    <>
      <div className="content_heading bg_introduce">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="tit_xl">Location Guide</h2>
              <ol className="breadcrumb">
                <li>Home</li>
                <li>AIBLab</li>
                <li className="active">Location Guide</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="section">
          <div className="container">
            <div className="row">
              <div className="col-12 tit_underline">
                <h2 className="tit_lg">Location Guide</h2>
              </div>
              <div className="col-12 branch_map">
                <table>
                  <tbody>
                    <tr>
                      <td className="border_box">
                        <a
                          href="https://map.naver.com/?searchCoord=c93a16e9ec40e9feecd1b8b7c5b8913f6876fd614d41a141512c9c1b9595b8d3&query=7JWE7J2067iM656p&tab=1&lng=6bcb8f43dd62644050550bce8ec8bb08&mapMode=0&mpx=d19b8fcec42af5a594e241cbc2a4846a983913930eb288e7578f0a37037e2b9417e7de207efa3bc09e95c3c4f60af6d06bd423a636a313632d61d0b8a3f49a1f&lat=661f50a5bcc0eb25fb4cec870ece1b8e&dlevel=8&enc=b64&menu=location"
                          target="_blank"
                        >
                          <img src="/images/aiblab_map.jpg" height="340" alt="지도" />
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="col-12 branch_info border_box_x">
                <h4 className="tit_sm">Address</h4>
                <div className="row">
                  <div className="col-lg-4">
                    <h5 className="tit_xs">AIBLab Inc.</h5>
                    <p>
                      <span>525ho, 54, Changeop-ro, Sujeong-gu,</span>
                      <span>Seongnam-si, Gyeonggi-do, Republic of Korea</span>
                    </p>
                  </div>
                  <div className="col-lg-4">
                    <h5 className="tit_xs">Public transport</h5>
                    <p>
                      <span>Shinbundang Line Pangyo Station East Side</span>
                      <span>→ bus 55</span>
                      <span>→ Corporate Growth Center Station</span>
                    </p>
                  </div>
                  <div className="col-lg-4">
                    <h5 className="tit_xs">Contact</h5>
                    <p>
                      <span>T. 070-8892-7160</span>
                      <span>F. 031-8063-9232</span>
                      <span>
                        E. <a href="mailto:sales@aiblab.co.kr">sales@aiblab.co.kr</a>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
