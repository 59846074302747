import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { apis } from '../../api/aiblab';
import { FaAngleRight } from 'react-icons/fa';
import ReactPagination from '../../components/ReactPagination';

export default function News() {
  const [keyword, setKeyword] = useState('');
  const [newsList, setNewsList] = useState([]);
  const [pageInfo, setPageInfo] = useState({
    pageNo: 1,
    pageSize: 10,
    totalRowCount: 10,
  });
  const { pageNo, pageSize, totalRowCount } = pageInfo;

  const loadNewsList = () => {
    apis
      .getNewsList(pageNo, pageSize, keyword)
      .then((response) => {
        if (response.data) {
          setNewsList(response.data.items);
          setPageInfo(response.data.pagination);
        }
      })
      .catch((error) => console.log(error.response));
  };

  const handleChangePage = (pageNo) => {
    setPageInfo((prev) => ({
      ...prev,
      pageNo,
    }));
  };

  const [news, setNews] = useState({});

  const showNewsById = (e, { newsId }) => {
    let isExpanded = e.currentTarget.ariaExpanded;

    if (isExpanded === 'true') {
      apis
        .getNewsById(newsId)
        .then(async (response) => {
          if (response.contents) {
            const myIframe = document.getElementById(`myIframe${response.newsId}`);
            const iframeDocument = myIframe.contentDocument;

            iframeDocument.open();

            await iframeDocument.write(response.contents);

            // 이미지 스타일링
            let images = iframeDocument.querySelectorAll('img');
            images.forEach(function (img) {
              img.style.maxWidth = '100%';
            });
            // 9:16 비율 동영상 스타일링
            let iframes = iframeDocument.querySelectorAll('iframe');
            iframes.forEach(function (iframe) {
              iframe.parentNode.style.cssText = 'position: relative; width: 100%; max-width: 100%; padding-top: 56.25%';
              iframe.style.cssText = 'position: absolute; top: 0; left: 0; width: 100%; height: 100%;';
            });
            // body 마진 제거
            iframeDocument.body.style.margin = 0;
            // 높이 지정
            myIframe.style.height = `${iframeDocument.body.offsetHeight}px`;

            iframeDocument.close();
          }
          setNews(response);
        })
        .catch((error) => console.log(error.response));
    }
  };

  useEffect(() => {
    loadNewsList();
  }, [pageNo]);

  return (
    <>
      <div className="content_heading pg_pr">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="tit_xl">아이브랩 소식</h2>
              <ol className="breadcrumb">
                <li>Home</li>
                <li>PR</li>
                <li className="active">아이브랩 소식</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="tit_underline">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2 className="tit_lg">아이브랩 소식</h2>
                <p className="desc">아이브랩의 최신 소식입니다.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="notice">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div id="newsAccordion">
                  {newsList.length === 0 ? (
                    <div className="accordion-item">
                      <div className="accordion-header">
                        <p className="accordion-button none">게시물이 존재하지 않습니다.</p>
                      </div>
                    </div>
                  ) : (
                    newsList.map((item, index) => (
                      <div className="accordion-item" key={item.newsId}>
                        <div className="accordion-header">
                          <a id={item.newsId} className="accordion-button collapsed" data-bs-target={`#newsArticle_${item.newsId}`} data-bs-toggle="collapse" onClick={(e) => showNewsById(e, item)}>
                            <p className="notice_num">{totalRowCount - index - (pageNo - 1) * pageSize}</p>
                            <p className="notice_tit">
                              <span>{item.title}</span>
                            </p>
                            <p className="notice_date">{item.createTime}</p>
                            <FaAngleRight />
                          </a>
                        </div>
                        <div id={`newsArticle_${item.newsId}`} className="accordion-collapse collapse" data-bs-parent="#newsAccordion">
                          <div className="accordion-body">
                            <iframe id={`myIframe${item.newsId}`} width="100%" scrolling="no" />
                          </div>
                        </div>
                      </div>
                    ))
                  )}
                </div>
                <ReactPagination pageInfo={pageInfo} handleChangePage={handleChangePage} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
