import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { apis } from '../../api/aiblab';
import { FaAngleLeft, FaAngleRight, FaPlus, FaArrowRight } from 'react-icons/fa';
import 'animate.css';

const CarouselItem = ({ newsId }) => {
  const [news, setNews] = useState('');
  const contents = news.contents ? news.contents : '';
  const summaryContents = contents.split('<p>');

  const loadNewsById = () => {
    apis
      .getNewsById(newsId)
      .then((response) => {
        setNews(response);
      })
      .catch((error) => console.log(error.response));
  };

  useEffect(() => {
    loadNewsById();
  }, []);

  return (
    <>
      <p>{news.title}</p>
      <p className="contents" dangerouslySetInnerHTML={{ __html: summaryContents[1] }} />
    </>
  );
};

export default function EnHome() {
  const [pageInfo, setPageInfo] = useState({
    pageNo: 1,
    pageSize: 10,
  });
  const { pageNo, pageSize } = pageInfo;
  const [keyword, setKeyword] = useState('');

  const [newsList, setNewsList] = useState([]);

  const loadNewsList = () => {
    apis
      .getNewsList(pageNo, pageSize, keyword)
      .then((response) => {
        setNewsList(response.data.items.slice(0, 3));
      })
      .catch((error) => console.log(error.response));
  };

  useEffect(() => {
    loadNewsList();
  }, []);

  return (
    <>
      <div id="homeCarousel" className="carousel slide home" data-bs-ride="true">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <div className="container">
              <div className="carousel-caption">
                <h1 className="animate__animated animate__lightSpeedInRight">Smart world! Smart AIBLab!</h1>
                <p className="animate__animated animate__bounceIn">
                  We use our skills and experience to build a variety of <br />
                  integrated services to provide the services you need for your life.
                </p>
                <Link to="/en/company/introduce" className="btn btn_primary animate__animated animate__bounceIn">
                  <strong>Learn more</strong>
                </Link>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <div className="container">
              <div className="carousel-caption">
                <h1 className="animate__animated animate__lightSpeedInRight">
                  Technology companies specializing <br />
                  in AI, IoT and Bigdata
                </h1>
                <p className="animate__animated animate__bounceIn">It's led by AIBLab, the leading role in future industry.</p>
                <Link to="/en/company/introduce" className="btn btn_primary animate__animated animate__bounceIn">
                  <strong>Learn more</strong>
                </Link>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <div className="container">
              <div className="carousel-caption">
                <h1 className="animate__animated animate__lightSpeedInRight">Start with AIBLab!</h1>
                <p className="animate__animated animate__bounceIn ">
                  Creating a smart environment <br />
                  through communication between people and people and things.
                </p>
                <Link to="/en/company/introduce" className="btn btn_primary animate__animated animate__bounceIn">
                  <strong>Learn more</strong>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#homeCarousel" data-bs-slide="prev">
          <FaAngleLeft />
          <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#homeCarousel" data-bs-slide="next">
          <FaAngleRight />
          <span className="visually-hidden">Next</span>
        </button>
        <ul className="carousel-indicators">
          <li data-bs-target="#homeCarousel" data-bs-slide-to="0" className="active"></li>
          <li data-bs-target="#homeCarousel" data-bs-slide-to="1"></li>
          <li data-bs-target="#homeCarousel" data-bs-slide-to="2"></li>
        </ul>
      </div>
      <div className="content">
        <div className="section introduce">
          <div className="container">
            <div className="row">
              <div className="col-12 tit_underline">
                <h2 className="tit_lg">AIBLab</h2>
                <p className="desc">AIBLab is a specialist in AI, IoT and Bigdata technologies.</p>
              </div>
              <div className="col-md-4">
                <div className="thumb_gradient circle">
                  <Link to="/en/company/vision">
                    <img src="/images/vision_ai.jpg" alt="AI" />
                  </Link>
                </div>
                <div className="summary">
                  <h3 className="tit_sm">AI</h3>
                  <div className="desc">
                    <p>
                      <span>AIBLab Artificial Intelligence </span>
                      <span>It provides the technology of expert system </span>
                    </p>
                    <p>for efficient management and provides the function of automatic design.</p>
                  </div>
                </div>
                <Link to="/en/company/vision" className="more">
                  <span>Learn more</span>
                  <FaAngleRight />
                </Link>
              </div>
              <div className="col-md-4">
                <div className="thumb_gradient circle">
                  <Link to="/en/company/vision">
                    <img src="/images/vision_iot.jpg" alt="IoT" />
                  </Link>
                </div>
                <div className="summary">
                  <h3 className="tit_sm">IoT</h3>
                  <div className="desc">
                    <p>
                      <span>Real-time sensing for easy use by users </span>
                      <span>We can provide information services </span>
                      <span>and conduct service business </span>
                    </p>
                    <p>utilizing various devices.</p>
                  </div>
                </div>
                <Link to="/en/company/vision" className="more">
                  <span>Learn more</span>
                  <FaAngleRight />
                </Link>
              </div>
              <div className="col-md-4">
                <div className="thumb_gradient circle">
                  <Link to="/en/company/vision">
                    <img src="/images/vision_bigdata.jpg" alt="Bigdata" />
                  </Link>
                </div>
                <div className="summary">
                  <h3 className="tit_sm">Bigdata</h3>
                  <div className="desc">
                    <p>
                      <span>AIBLab data shows that people’s behavior is </span>
                      <span>Of course, even location information and thoughts </span>
                    </p>
                    <p>and opinions via SNS You can analyze and predict.</p>
                  </div>
                </div>
                <Link to="/en/company/vision" className="more">
                  <span>Learn more</span>
                  <FaAngleRight />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="section product bg_g">
          <div className="container">
            <div className="row">
              <div className="col-12 tit_underline">
                <h2 className="tit_lg">AIBLab product</h2>
                <p className="desc">This is an example of AIBLab's product construction.</p>
              </div>
              <div className="col-md-4 col-6">
                <div className="thumb_plus">
                  <img src="/images/home_thumb_01.jpg" alt="smartOffice" />
                  <Link to="/en/case/smartOffice">
                    <FaPlus />
                  </Link>
                </div>
                <div className="summary">
                  <h3 className="tit_xs">Smart Office</h3>
                  <p className="desc">Handysoft, Innoton's smart office</p>
                </div>
              </div>
              <div className="col-md-4 col-6">
                <div className="thumb_plus">
                  <img src="/images/home_thumb_02__eng.jpg" alt="smartHome" />
                  <Link to="/en/case/smartHome">
                    <FaPlus />
                  </Link>
                </div>
                <div className="summary">
                  <h3 className="tit_xs">Smart Home</h3>
                  <p className="desc">Smart mat, Smart bed</p>
                </div>
              </div>
              <div className="col-md-4 col-6">
                <div className="thumb_plus">
                  <img src="/images/home_thumb_03.jpg" alt="smartHealthcare" />
                  <Link to="/en/case/smartHealthcare">
                    <FaPlus />
                  </Link>
                </div>
                <div className="summary">
                  <h3 className="tit_xs">Smart Wearable</h3>
                  <p className="desc">Good people's Race maker</p>
                </div>
              </div>
              <div className="col-md-4 col-6">
                <div className="thumb_plus">
                  <img src="/images/home_thumb_04.jpg" alt="smartBuilding" />
                  <Link to="/en/case/smartBuilding">
                    <FaPlus />
                  </Link>
                </div>
                <div className="summary">
                  <h3 className="tit_xs">Smart Building</h3>
                  <p className="desc">Telkomsel's Smart building</p>
                </div>
              </div>
              <div className="col-md-4 col-6">
                <div className="thumb_plus">
                  <img src="/images/home_thumb_05.jpg" alt="smartCar" />
                  <Link to="/en/case/smartCar">
                    <FaPlus />
                  </Link>
                </div>
                <div className="summary">
                  <h3 className="tit_xs">Smart Car</h3>
                  <p className="desc">Handycar, ETRI's Smart car</p>
                </div>
              </div>
              <div className="col-md-4 col-6">
                <div className="thumb_plus">
                  <img src="/images/home_thumb_06.jpg" alt="smartFactory" />
                  <Link to="/en/case/smartFactory">
                    <FaPlus />
                  </Link>
                </div>
                <div className="summary">
                  <h3 className="tit_xs">Smart Factory</h3>
                  <p className="desc">ETRI's Smart Factory</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section service">
          <div className="container">
            <div className="row">
              <div className="col-12 tit_underline">
                <h2 className="tit_lg">AIBLab's Service</h2>
                <p className="desc">Introducing AIBWorks, attendance management system</p>
              </div>
              <div className="col-md-4">
                <div className="thumb_plus">
                  <img src="/images/home_thumb_07.jpg" alt="Attendance management system" />
                  <Link to="/en/service/aibworks" state={{ activeTab: 'aibworks' }}>
                    <FaPlus />
                  </Link>
                </div>
                <div className="summary">
                  <h3 className="tit_xs">Attendance management</h3>
                  <p className="desc">
                    <span>It is possible to set a flexible work schedule </span>
                    <span>according to the company policy.</span>
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="thumb_plus">
                  <img src="/images/home_thumb_08__eng.jpg" alt="Smart toilet" />
                  <Link to="/en/service/aibworks" state={{ activeTab: 'smartToilet' }}>
                    <FaPlus />
                  </Link>
                </div>
                <div className="summary">
                  <h3 className="tit_xs">Smart toilet</h3>
                  <p className="desc">
                    <span>AIBWorks Toilet saves time.</span>
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="thumb_plus">
                  <img src="/images/home_thumb_09.jpg" alt="Environmental Information Monitoring" />
                  <Link to="/en/service/aibworks" state={{ activeTab: 'monitoringEnv' }}>
                    <FaPlus />
                  </Link>
                </div>
                <div className="summary">
                  <h3 className="tit_xs">Environmental Information Monitoring</h3>
                  <p className="desc">You can check the pleasant work space.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="banner aibworks">
          <div className="container">
            <div className="row">
              <p className="col-12 catchphrase">
                <span className="phrase_main">Start with AIBWorks!</span>
                <span className="phrase_sub">You can check the pleasant work space.</span>
              </p>
              <div className="col-12 btn_area">
                <Link to="/en/service/aibworks" className="btn btn_lg btn_outline btn_arrow">
                  <strong>Learn more</strong>
                  <FaArrowRight />
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="section pr">
          <div className="container">
            <div className="row">
              <div className="col-12 tit_underline">
                <h2 className="tit_lg">AIBLab News</h2>
                <div className="desc">What's new in AIBLab</div>
              </div>
              <div className="col-12">
                <div id="newsCarousel" className="carousel slide dashed" data-bs-ride="true">
                  <ul className="carousel-inner">
                    {newsList.map((item, index) => (
                      <li className={`carousel-item${index === 0 ? ' active' : ''}`} key={item.newsId}>
                        <Link to="/en/info/news" className="item_link">
                          <CarouselItem newsId={item.newsId} />
                        </Link>
                        <p className="remark">{item.createTime}</p>
                      </li>
                    ))}
                  </ul>
                  <ol className="carousel-indicators">
                    <li data-bs-target="#newsCarousel" data-bs-slide-to="0" className="active"></li>
                    <li data-bs-target="#newsCarousel" data-bs-slide-to="1"></li>
                    <li data-bs-target="#newsCarousel" data-bs-slide-to="2"></li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="section partner">
          <div className="container">
            <div className="row">
              <div className="col-12 tit_underline">
                <h2 className="tit_lg">AIBLab Partner</h2>
                <p className="desc">It's a partner company.</p>
              </div>
              <div className="col-lg-3 col-sm-4 col-6">
                <img src="/images/partners/handysoft.jpg" alt="handysoft" />
              </div>
              <div className="col-lg-3 col-sm-4 col-6">
                <img src="/images/partners/dasannetworks.png" alt="dasan networks" />
              </div>
              <div className="col-lg-3 col-sm-4 col-6">
                <img src="/images/partners/tta.jpg" alt="tta" />
              </div>
              <div className="col-lg-3 col-sm-4 col-6">
                <img src="/images/partners/rockwon-it.jpg" alt="rockwon it" />
              </div>
              <div className="col-lg-3 col-sm-4 col-6">
                <img src="/images/partners/bizflow.jpg" alt="bizflow" />
              </div>
              <div className="col-lg-3 col-sm-4 col-6">
                <img src="/images/partners/aicar.jpg" alt="aicar" />
              </div>
              <div className="col-lg-3 col-sm-4 col-6">
                <img src="/images/partners/amotech.jpg" alt="amotech" />
              </div>
              <div className="col-lg-3 col-sm-4 col-6">
                <img src="/images/partners/etri.jpg" alt="etri" />
              </div>
              <div className="col-lg-3 col-sm-4 col-6">
                <img src="/images/partners/ese.jpg" alt="ese" />
              </div>
              <div className="col-lg-3 col-sm-4 col-6">
                <img src="/images/partners/alps.jpg" alt="alps" />
              </div>
              <div className="col-lg-3 col-sm-4 col-6">
                <img src="/images/partners/csi.jpg" alt="csi" />
              </div>
              <div className="col-lg-3 col-sm-4 col-6">
                <img src="/images/partners/nhn.png" alt="nhn" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
