import React from 'react';
import { FaAngleDoubleDown } from 'react-icons/fa';
import { Link } from 'react-router-dom';

export default function EnIntroduce() {
  return (
    <>
      <div className="content_heading bg_introduce">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="tit_xl">Introduction</h2>
              <ol className="breadcrumb">
                <li>Home</li>
                <li>AIBLab</li>
                <li className="active">Introduction</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="section">
          <div className="container">
            <div className="row">
              <div className="col-12 tit_underline">
                <h2 className="tit_lg">Introduction</h2>
                <div className="desc">
                  <p>AIBLab uses artificial intelligence, </p>
                  <p>Internet of Things platform technology to help people enjoy a richer life.</p>
                  <p>To become a leading company in the development of convergence technology, we will prepare for the future.</p>
                </div>
              </div>
              <div className="col-12 img_area">
                <img src="/images/main_img-introduce.jpg" alt="회사소개 이미지" />
              </div>
              <div className="col-12 text_area">
                <h3 className="tit_md">AIBLab</h3>
                <div className="desc">
                  <p>AIBLab is an organization spun off by HandySoft's new Internet of Things division and specializes in IoT platform-based solution development and services.</p>
                  <p>
                    We intend to provide services essential to life by utilizing various convergence service building technologies and experiences such as Smart Office, Smart Health, Smart Home, Smart City, and Smart Factory based on the IoT platform developed
                    by AIBLab.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <table className="table_border_x">
                  <caption className="tit_md">COMPANY PROFILE</caption>
                  <tbody>
                    <tr>
                      <th scope="row">Company</th>
                      <td>AIBLab</td>
                    </tr>
                    <tr>
                      <th scope="row">CEO</th>
                      <td>YoungJu Seo, YongWoo Kim</td>
                    </tr>
                    <tr>
                      <th scope="row">Founded year</th>
                      <td>January 2019</td>
                    </tr>
                    <tr>
                      <th scope="row">Location</th>
                      <td>525ho, 54 Changeop-ro, Sujeong-gu, Seongnam-si, Gyeonggi-do, Republic of Korea</td>
                    </tr>
                    <tr>
                      <th scope="row">Major service</th>
                      <td>Internet of Things platform</td>
                    </tr>
                    <tr>
                      <th scope="row">Major business</th>
                      <td>Smart office, Internet platform-based solution development of things</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="row">
              <div className="col-12 ci">
                <h3 className="tit_md">CI</h3>
                <div className="img_area">
                  <p>
                    <img src="/images/ci__black.jpg" width="50%" alt="CI Black" />
                  </p>
                  <p>
                    <img src="/images/ci__cyan.jpg" width="50%" alt="CI Cyan" />
                  </p>
                </div>
                <div className="border_box_x">
                  <h4 className="tit_sm">AIBLab CI</h4>
                  <p className="desc">
                    AIBLab's AIB stands for AI, IoT, Big Data, BlockChain, and has a vision to make human life more convenient and affluent by using the core technology of the Fourth Industrial Revolution. AIBLab CI is designed to combine these brands with a
                    clean corporate image and the Cyan color, which is a visual element of a light and smart brand, to be easily used in various media.
                  </p>
                </div>
                <div className="btn_area">
                  <a href="http://aiblab.co.kr/file/AIBLab_CI.zip" className="btn btn_sm btn_primary">
                    <strong>AI Download</strong>
                    <FaAngleDoubleDown />
                  </a>
                  <a href="http://aiblab.co.kr/file/AIBLab_CI.png" className="btn btn_sm btn_primary">
                    <strong>PNG Download</strong>
                    <FaAngleDoubleDown />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
