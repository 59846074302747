import React from 'react';
import { Link } from 'react-router-dom';

export default function History() {
  return (
    <>
      <div className="content_heading bg_introduce">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="tit_xl">회사연혁</h2>
              <ol className="breadcrumb">
                <li>Home</li>
                <li>회사소개</li>
                <li className="active">회사연혁</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="section">
          <div className="container">
            <div className="row">
              <div className="col-12 tit_underline">
                <h2 className="tit_lg">아이브랩 연혁</h2>
                <p className="desc">아이브랩이 걸어온 길입니다.</p>
              </div>
              <div className="col-12">
                <ul className="history_list">
                  <li>
                    <img className="item_img" src="/images/history_2024.jpeg" alt="2024" />
                    <dl className="item_text">
                      <dt className="tit_sm">
                        <span>2024</span>
                      </dt>
                      <dd className="desc">
                        <ul className="circle_list">
                          <li>2024년 AI 바우처 및 데이터 바우처 사업 공급기업 선정 </li>
                          <li>SK텔링크 홈페이지 통합 유지보수 사업 수주</li>
                          <li>더편한샵 고도화 개발 용역 계약</li>
                          <li>엠서클 웰체크 다이어트 2차 개발용역 계약</li>
                          <li>전북대학교 환경보건 빅데이터 플랫폼 운영 및 유지보수 사업 수주</li>
                        </ul>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <img className="item_img" src="/images/history_2023.jpeg" alt="2023" />
                    <dl className="item_text">
                      <dt className="tit_sm">
                        <span>2023</span>
                      </dt>
                      <dd className="desc">
                        <ul className="circle_list">
                          <li>중기부 KAMP AI 서비스 사업자 선정</li>
                          <li>SK텔링크 홈페이지 통합 유지보수 사업 수주</li>
                          <li>대웅제약 뎌샵(온라인쇼핑몰) 고도화 사업 수주</li>
                          <li>대웅제약 웰체크 다이어트 서비스 개발 사업 수주</li>
                          <li>산림 미세먼지 측정넷 오류 대응 기능개선 시스템 구축사업 수주</li>
                          <li>삼성카드 마이데이터 고도화 신규 개발사업 수주 </li>
                          <li>스마트시티 시범사업 연계 리빙랩 운영 지원 사업 수주</li>
                          <li>전북대학교 AI교육지원도구 숙련자용 구축 및 운영사업 수주 </li>
                          <li>전북대학교 IoT 기반 환경유해인자 건강영향 빅데이터 수집 관리 연구 용역 사업 수주 </li>
                          <li>유무선 스마트센서기반 자산관리시스템 개발 용역 계약</li>
                          <li>데이터 기반 재정 디지털트윈 가시화 용역 계약</li>
                          <li>AIoT 국제 전시회 참가 / 안전산업박람회 참가</li>
                        </ul>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <img className="item_img" src="/images/history_2022.jpeg" alt="2022" />
                    <dl className="item_text">
                      <dt className="tit_sm">
                        <span>2022</span>
                      </dt>
                      <dd className="desc">
                        <ul className="circle_list">
                          <li>
                            특허 2건 등록
                            <ul className="dash_list">
                              <li>사용자 특성에 따른 정보 데이터 변환 방법 및 장치</li>
                              <li>배관 파손 진단 장치 및 방법</li>
                            </ul>
                          </li>
                          <li>근태관리 클라우드 서비스 인증 획득</li>
                          <li>숙박관리 클라우드 서비스 런칭</li>
                          <li>삼성화재 디지털 안전운전 습관 서비스 솔루션 2차 구축</li>
                          <li>인천스마트산단 빅데이터 시스템 구축 및 인공지능 분석 </li>
                          <li>한국전력연구원 데이터 분석 플랫폼 고도화 사업</li>
                          <li>SK텔링크(Online POC운영) 년간 운영 계약 체결</li>
                          <li>AIoT 국제 전시회/ SoftWave전시 참가</li>
                        </ul>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <img className="item_img" src="/images/history_2021.jpeg" alt="2021" />
                    <dl className="item_text">
                      <dt className="tit_sm">
                        <span>2021</span>
                      </dt>
                      <dd className="desc">
                        <ul className="circle_list">
                          <li>근태관리 클라우드 서비스 런칭</li>
                          <li>한국전력 연구원 데이터 분석 플랫폼 고도화 사업</li>
                          <li>
                            NIPA 민간지능정보서비스확산사업
                            <ul className="dash_list">
                              <li>상수도 분야 인공지능 사업 추진</li>
                            </ul>
                          </li>
                          <li>SK텔링크 홈페이지 통합 유지보수 사업</li>
                          <li>삼성화재 디지털 안전운전 습관 서비스 솔루션 구축</li>
                          <li>AIoT 국제 전시회/ SoftWave전시 참가</li>
                        </ul>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <img className="item_img" src="/images/history_2020.jpeg" alt="2020" />
                    <dl className="item_text">
                      <dt className="tit_sm">
                        <span>2020</span>
                      </dt>
                      <dd className="desc">
                        <ul className="circle_list">
                          <li>중기부 KAMP AI 서비스 사업자 선정</li>
                          <li>SK텔링크 홈페이지 통합 유지보수 사업</li>
                          <li>NIA 대전시 지능형 물관리 구축사업</li>
                          <li>AIoT 국제 전시회 참가</li>
                        </ul>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <img className="item_img" src="/images/history_1.jpg" alt="2019" />
                    <dl className="item_text">
                      <dt className="tit_sm">
                        <span>2019</span>
                        <span className="sub_tit">(주)아이브랩</span>
                      </dt>
                      <dd className="desc">
                        <ul className="circle_list">
                          <li>기술부설연구소 설립</li>
                          <li>AI, IoT, Bigdata 기술 전문 기업</li>
                          <li>생활 안전 예방 서비스를 위한 지능형 플랫폼 개발 사업 수주</li>
                          <li>핸디소프트 IoT 사업부 분사</li>
                        </ul>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <img className="item_img" src="/images/history_2.jpg" alt="2018" />
                    <dl className="item_text">
                      <dt className="tit_sm">2018</dt>
                      <dd className="desc">
                        <ul className="circle_list">
                          <li>스마트 에어매트 사업화(레이캅)</li>
                          <li>
                            스마트 오피스 사업화
                            <ul className="dash_list">
                              <li>인도네시아 텔콤셀 스마트오피스 구축</li>
                              <li>이노톤 공유오피스 구축</li>
                            </ul>
                          </li>
                          <li>스마트 체중계 (세븐일렉)</li>
                          <li>2018 사물 인터넷 국제 전시회</li>
                          <li>IoT 이노베이션 어워드 혁신 대상 수상</li>
                        </ul>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <img className="item_img" src="/images/history_3.jpg" alt="2017" />
                    <dl className="item_text">
                      <dt className="tit_sm">2017</dt>
                      <dd className="desc">
                        <ul className="circle_list">
                          <li>스마트 헬스 사업화 - 레이스 메이커(좋은사람들)</li>
                          <li>2017 라스베가스 CES 전시회</li>
                          <li>2017 사물 인터넷 국제 전시회</li>
                        </ul>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <img className="item_img" src="/images/history_4.jpg" alt="2016" />
                    <dl className="item_text">
                      <dt className="tit_sm">2016</dt>
                      <dd className="desc">
                        <ul className="circle_list">
                          <li>스마트 배드 사업화 (퍼니스템)</li>
                          <li>IoT 국제 표준 규격 oneM2M 인증 획득</li>
                          <li>ETRI 기술개발 협력을 통한 기반 기술 확보</li>
                          <li>HANDYPIA IoT Platform 인터넷 서비스</li>
                          <li>2016 라스베가스 CES 전시회</li>
                          <li>2016 사물 인터넷 국제 전시회</li>
                        </ul>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <img className="item_img" src="/images/history_5.jpg" alt="2015" />
                    <dl className="item_text">
                      <dt className="tit_sm">2015</dt>
                      <dd className="desc">
                        <ul className="circle_list">
                          <li>스마트 오피스 솔루션 출시</li>
                          <li>스마트 보일러 출시(귀뚜라미 보일러)</li>
                          <li>스마트 분실방지 솔루션 출시(알톤자전거)</li>
                          <li>2015 라스베가스 CES 전시회</li>
                          <li>2015 사물 인터넷 국제 전시회</li>
                        </ul>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <img className="item_img" src="/images/history_6.jpg" alt="2014" />
                    <dl className="item_text">
                      <dt className="tit_sm">2014</dt>
                      <dd className="desc">
                        <ul className="circle_list">
                          <li>(주)핸디소프트 플랫폼 연구소 설립</li>
                          <li>IoT 플랫폼 사업</li>
                          <li>HANDYPIA IoT 플랫폼 출시</li>
                        </ul>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <img className="item_img" src="/images/history_7.jpg" alt="아이브랩" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
