import React from 'react';
import { Link } from 'react-router-dom';

export default function Iot() {
  return (
    <>
      <div className="content_heading bg_product">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="tit_xl">IoT Platform</h2>
              <ol className="breadcrumb">
                <li>Home</li>
                <li>AIBLab Platform</li>
                <li className="active">IoT Platform</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="tit_underline">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h3 className="tit_lg">IoT Platform</h3>
                <p className="desc">
                  <span>간편하고 효율적으로 IoT 서비스를 가능하게 하는 </span>
                  <span>아이브랩 사물인터넷 플랫폼</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="section bg_g">
          <div className="container">
            <div className="row">
              <div className="col-12 img_area">
                <img src="/images/main_img-iot.jpg" alt="iot platform" />
              </div>
              <div className="col-12 row text_area">
                <h4 className="col-lg-4 tit_md">IoT Platform</h4>
                <p className="col-lg-8 desc">모든 사물을 인터넷에 연결하여 정보를 상호 소통하고, 사용자가 언제 어디서든지 쉽게 이용할 수 있도록 실시간 센싱 정보 서비스를 제공하며, 다양한 디바이스를 활용한 서비스 사업을 수행할 수 있습니다.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="section">
          <div className="container">
            <div className="row">
              <div className="col-12 tit_underline">
                <h4 className="tit_md">제품규격</h4>
                <div className="desc">
                  <p>아이브랩 제품 규격 사항입니다.</p>
                </div>
              </div>
              <div className="col-12">
                <ul className="nav nav-tabs">
                  <li className="nav-item">
                    <a className="nav-link active" data-bs-target="#iotTab_01" data-bs-toggle="tab">
                      IoT 플랫폼 서버
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" data-bs-target="#iotTab_02" data-bs-toggle="tab">
                      디바이스 게이트웨이
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" data-bs-target="#iotTab_03" data-bs-toggle="tab">
                      디바이스 펌웨어
                    </a>
                  </li>
                </ul>
                <div className="tab-content">
                  <div id="iotTab_01" className="tab-pane fade show active">
                    <div className="d-flex">
                      <div className="img_area">
                        <img src="/images/iot-tab_1.png" width="70%" alt="플랫폼" />
                      </div>
                      <div className="text_area">
                        <h5 className="tit_sm">IoT 플랫폼 서버</h5>
                        <ul className="desc">
                          <li>PaaS형 IoT 플랫폼 서버(멀티테넌트)</li>
                          <li>oneM2M(IN) 탑재 지원</li>
                          <li>NoSQL기반 대용량 데이터 관리</li>
                          <li>Open API 지원</li>
                          <li>효율적 Scale-out</li>
                          <hr />
                          <li>IN (Infrastructure Node)</li>
                          <li>IoT Server</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div id="iotTab_02" className="tab-pane fade">
                    <div className="d-flex">
                      <div className="img_area">
                        <img src="/images/iot-tab_2.png" width="70%" alt="게이트웨이" />
                      </div>
                      <div className="text_area">
                        <h5 className="tit_sm">디바이스 게이트웨이</h5>
                        <ul className="desc">
                          <li>WiFi, BLE, LoRA 지원</li>
                          <li>oneM2M(MN) 또는 edgeX Open sw 탑재</li>
                          <li>HTTP/CoAP, MQTT 지원</li>
                          <li>데이터 수집 및 관리 디바이스 연결 관리</li>
                          <hr />
                          <li>BLE (Bluetooth Low Energy), LoRa (Long Range), MN (Middle Node), M2M Gateway</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div id="iotTab_03" className="tab-pane fade">
                    <div className="d-flex">
                      <div className="img_area">
                        <img src="/images/iot-tab_3.png" width="70%" alt="펌웨어" />
                      </div>
                      <div className="text_area">
                        <h5 className="tit_sm">디바이스 펌웨어</h5>
                        <ul className="desc">
                          <li>LwM2M 규격지원</li>
                          <li>개발 라이브러리 지원</li>
                          <li>원격 펌웨어 업데이트 기능 지원</li>
                          <hr />
                          <li>LwM2M (Lightweight M2M)</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section bg_g">
          <div className="container">
            <div className="row">
              <div className="col-12 tit_underline">
                <h4 className="tit_md">주요기능</h4>
              </div>
              <div className="col-lg-3 col-6">
                <div className="img_area">
                  <img src="/images/iot-icon_01.png" alt="Open API" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">Open API</h5>
                  <p className="desc">
                    오픈 API를 활용 <br />
                    부가서비스를 쉽게 개발 가능
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="img_area">
                  <img src="/images/iot-icon_02.png" alt="Security" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">Security</h5>
                  <p className="desc">
                    서비스 데이터와 디바이스의 <br />
                    안전한 유지/관리
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="img_area">
                  <img src="/images/iot-icon_03.png" alt="Repository" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">Repository</h5>
                  <p className="desc">
                    다양한 IoT 데이터는 <br />
                    클라우드 저장소에 수집/저장
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="img_area">
                  <img src="/images/iot-icon_04.png" alt="Pay per Use" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">Pay per Use</h5>
                  <p className="desc">
                    사용자수, 디바이스수, 데이터 사용 <br />
                    용량에 따른 유연한 요금정책 제공
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="img_area">
                  <img src="/images/iot-icon_05.png" alt="Device" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">Device</h5>
                  <p className="desc">
                    표준 또는 비표준 IoT 디바이스를 <br />
                    모두 등록 사용가능
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="img_area">
                  <img src="/images/iot-icon_06.png" alt="User" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">User</h5>
                  <p className="desc">
                    서비스 사용자를 <br />
                    손쉽게 등록하여 관리 가능
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="img_area">
                  <img src="/images/iot-icon_07.png" alt="Report" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">Report</h5>
                  <p className="desc">
                    등록된 디바이스의 모니터링과 <br />
                    원격 관리 서비스 제공
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="img_area">
                  <img src="/images/iot-icon_08.png" alt="Notification" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">Notification</h5>
                  <p className="desc">
                    서비스 규칙을 설정하여 이벤트 생성
                    <br />및 외부 서비스에 쉽게 연결관리
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="img_area">
                  <img src="/images/iot-icon_09.png" alt="Mobile" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">Mobile</h5>
                  <p className="desc">
                    등록된 디바이스를 통해 <br />
                    모바일 기반의 서비스를 쉽게 제공
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="img_area">
                  <img src="/images/iot-icon_10.png" alt="Semantic Engine" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">Semantic Engine</h5>
                  <p className="desc">
                    온톨로지와 추론 엔진을 통한 <br />
                    정교한 지식 서비스 제공 기능
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="img_area">
                  <img src="/images/iot-icon_11.png" alt="Analytics" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">Analytics</h5>
                  <p className="desc">
                    빅데이터 분석을 통한 <br />
                    최적의 비즈니스 의사결정 솔루션 제공
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="img_area">
                  <img src="/images/iot-icon_12.png" alt="Process" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">Process</h5>
                  <p className="desc">
                    다양한 서비스기능을 연결하여 <br />
                    고수준 융합 서비스 제공가능
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section">
          <div className="container">
            <div className="row">
              <div className="col-12 tit_underline">
                <h4 className="tit_md">구축사례</h4>
                <div className="desc">
                  <p>IoT 제품 구축사례입니다.</p>
                </div>
              </div>
              <div className="col-lg-4 col-6">
                <div className="thumb_gradient">
                  <Link to="/case/smartOffice">
                    <img src="/images/case_office.png" alt="스마트 오피스" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-6">
                <div className="thumb_gradient">
                  <Link to="/case/smartHome">
                    <img src="/images/case_home.png" alt="스마트 홈" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-6">
                <div className="thumb_gradient">
                  <Link to="/case/smartHealthcare">
                    <img src="/images/case_healthcare.png" alt="스마트 헬스케어" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-6">
                <div className="thumb_gradient">
                  <Link to="/case/smartBuilding">
                    <img src="/images/case_building.png" alt="스마트 빌딩" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-6">
                <div className="thumb_gradient">
                  <Link to="/case/smartCar">
                    <img src="/images/case_car.png" alt="스마트 카" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-6">
                <div className="thumb_gradient">
                  <Link to="/case/smartFactory">
                    <img src="/images/case_factory.png" alt="스마트 팩토리" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section pt-0">
          <div className="container">
            <div className="row">
              <div className="col-12 tit_underline">
                <h4 className="tit_md">주요 고객사</h4>
                <div className="desc">
                  <p>아이브랩 IoT 플랫폼 주요 고객사입니다.</p>
                </div>
              </div>
              <div className="col-12">
                <div id="iotPartnerCarousel" className="carousel slide" data-bs-ride="true">
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <ul className="d-flex">
                        <li>
                          <img src="/images/partners/goodpeople.png" alt="좋은사람들" />
                        </li>
                        <li>
                          <img src="/images/partners/kiturami.png" alt="귀뚜라미" />
                        </li>
                        <li>
                          <img src="/images/partners/raycop.png" alt="레이콥" />
                        </li>
                        <li>
                          <img src="/images/partners/telcomsel.png" alt="텔콤셀" />
                        </li>
                      </ul>
                    </div>
                    <div className="carousel-item">
                      <div className="d-flex">
                        <li>
                          <img src="/images/partners/furnistem.png" alt="퍼니스템" />
                        </li>
                        <li>
                          <img src="/images/partners/alton.png" alt="알톤" />
                        </li>
                        <li>
                          <img src="/images/partners/dasannetworks.png" alt="다산네트웍스" />
                        </li>
                        <li>
                          <img src="/images/partners/srbgas.png" alt="서라벌도시가스" />
                        </li>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <div className="d-flex">
                        <li>
                          <img src="/images/partners/nipa.png" alt="정보통신산업진흥원" />
                        </li>
                        <li>
                          <img src="/images/partners/amosense.png" alt="아모센스" />
                        </li>
                        <li>
                          <img src="/images/partners/amano.png" alt="아마노" />
                        </li>
                        <li>
                          <img src="/images/partners/sktelink.png" alt="에스케이텔링크" />
                        </li>
                      </div>
                    </div>
                  </div>
                  <ul className="carousel-indicators">
                    <li data-bs-target="#iotPartnerCarousel" data-bs-slide-to="0" className="active"></li>
                    <li data-bs-target="#iotPartnerCarousel" data-bs-slide-to="1"></li>
                    <li data-bs-target="#iotPartnerCarousel" data-bs-slide-to="2"></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
