import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, redirect, RouterProvider } from 'react-router-dom';
import { AppProvider } from './context/AppContext';
import App from './App';
import NotFound from './components/notFound/NotFound';
import Home from './pages/Home';
import Introduce from './pages/company/Introduce';
import Vision from './pages/company/Vision';
import Greeting from './pages/company/Greeting';
import History from './pages/company/History';
import Location from './pages/company/Location';
import AiBigdata from './pages/platform/AiBigdata';
import Iot from './pages/platform/Iot';
import SmartOffice from './pages/case/SmartOffice';
import SmartHome from './pages/case/SmartHome';
import SmartHealthcare from './pages/case/SmartHealthcare';
import SmartBuilding from './pages/case/SmartBuilding';
import SmartCar from './pages/case/SmartCar';
import SmartFactory from './pages/case/SmartFactory';
import Aibworks from './pages/service/Aibworks';
import Aibcare from './pages/service/Aibcare';
import Maintenance from './pages/service/Maintenance';
import SmartWater from './pages/service/SmartWater';
import AiVoucher from './pages/voucher/AiVoucher';
import DataVoucher from './pages/voucher/DataVoucher';
import News from './pages/info/News';
import Media from './pages/info/Media';
import Culture from './pages/recruit/Culture';
import Recruitment from './pages/recruit/Recruitment';
import Ask from './pages/etc/Ask';
import TermsPrivacy from './pages/etc/TermsPrivacy';
import EnHome from './pages/_en/EnHome';
import EnIntroduce from './pages/_en/company/EnIntroduce';
import EnVision from './pages/_en/company/EnVision';
import EnGreeting from './pages/_en/company/EnGreeting';
import EnHistory from './pages/_en/company/EnHistory';
import EnLocation from './pages/_en/company/EnLocation';
import EnIot from './pages/_en/platform/EnIot';
import EnAiBigdata from './pages/_en/platform/EnAiBigdata';
import EnSmartOffice from './pages/_en/case/EnSmartOffice';
import EnSmartHome from './pages/_en/case/EnSmartHome';
import EnSmartHealthcare from './pages/_en/case/EnSmartHealthcare';
import EnSmartBuilding from './pages/_en/case/EnSmartBuilding';
import EnSmartCar from './pages/_en/case/EnSmartCar';
import EnSmartFactory from './pages/_en/case/EnSmartFactory';
import EnAibworks from './pages/_en/service/EnAibworks';
import EnAibcare from './pages/_en/service/EnAibcare';
import EnMaintenance from './pages/_en/service/EnMaintenance';
import EnNews from './pages/_en/info/EnNews';
import EnMedia from './pages/_en/info/EnMedia';
import EnAsk from './pages/_en/etc/EnAsk';
import EnTermsPrivacy from './pages/_en/etc/EnTermsPrivacy';

const root = ReactDOM.createRoot(document.getElementById('root'));

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <NotFound />,
    children: [
      { index: true, path: '/', element: <Home /> },
      { path: 'index.html', element: <Home /> },
      {
        path: 'company',
        children: [
          { path: 'introduce', element: <Introduce /> },
          { path: 'vision', element: <Vision /> },
          { path: 'greeting', element: <Greeting /> },
          { path: 'history', element: <History /> },
          { path: 'location', element: <Location /> },
        ],
      },
      {
        path: 'platform',
        children: [
          { path: 'iot', element: <Iot /> },
          { path: 'aiBigdata', element: <AiBigdata /> },
        ],
      },
      {
        path: 'case',
        children: [
          { path: 'smartOffice', element: <SmartOffice /> },
          { path: 'smartHome', element: <SmartHome /> },
          { path: 'smartHealthcare', element: <SmartHealthcare /> },
          { path: 'smartBuilding', element: <SmartBuilding /> },
          { path: 'smartCar', element: <SmartCar /> },
          { path: 'smartFactory', element: <SmartFactory /> },
        ],
      },
      {
        path: 'service',
        children: [
          { path: 'aibworks', element: <Aibworks /> },
          { path: 'aibcare', element: <Aibcare /> },
          { path: 'maintenance', element: <Maintenance /> },
          { path: 'smartWater', element: <SmartWater /> },
        ],
      },
      {
        path: 'voucher',
        children: [
          { path: 'aiVoucher', element: <AiVoucher /> },
          { path: 'dataVoucher', element: <DataVoucher /> },
        ],
      },
      {
        path: 'info',
        children: [
          { path: 'news', element: <News /> },
          { path: 'media', element: <Media /> },
        ],
      },
      {
        path: 'recruit',
        children: [
          { path: 'culture', element: <Culture /> },
          { path: 'recruitment', element: <Recruitment /> },
        ],
      },
      {
        path: 'etc',
        children: [
          { path: 'ask', element: <Ask /> },
          { path: 'termsPrivacy', element: <TermsPrivacy /> },
        ],
      },
    ],
  },
  {
    path: '/en',
    element: <App />,
    children: [
      { index: true, element: <EnHome /> },
      {
        path: 'company',
        children: [
          { path: 'introduce', element: <EnIntroduce /> },
          { path: 'vision', element: <EnVision /> },
          { path: 'greeting', element: <EnGreeting /> },
          { path: 'history', element: <EnHistory /> },
          { path: 'location', element: <EnLocation /> },
        ],
      },
      {
        path: 'platform',
        children: [
          { path: 'iot', element: <EnIot /> },
          { path: 'aiBigdata', element: <EnAiBigdata /> },
        ],
      },
      {
        path: 'case',
        children: [
          { path: 'smartOffice', element: <EnSmartOffice /> },
          { path: 'smartHome', element: <EnSmartHome /> },
          { path: 'smartHealthcare', element: <EnSmartHealthcare /> },
          { path: 'smartBuilding', element: <EnSmartBuilding /> },
          { path: 'smartCar', element: <EnSmartCar /> },
          { path: 'smartFactory', element: <EnSmartFactory /> },
        ],
      },
      {
        path: 'service',
        children: [
          { path: 'aibworks', element: <EnAibworks /> },
          { path: 'aibcare', element: <EnAibcare /> },
          { path: 'maintenance', element: <EnMaintenance /> },
        ],
      },
      {
        path: 'info',
        children: [
          { path: 'news', element: <EnNews /> },
          { path: 'media', element: <EnMedia /> },
        ],
      },
      {
        path: 'etc',
        children: [
          { path: 'ask', element: <EnAsk /> },
          { path: 'termsPrivacy', element: <EnTermsPrivacy /> },
        ],
      },
      { path: '*', element: <EnHome />, loader: () => redirect('/en') },
    ],
  },
]);

root.render(
  // <React.StrictMode>
  <AppProvider>
    <RouterProvider router={router}>
      <App />
    </RouterProvider>
  </AppProvider>
  // </React.StrictMode>
);
