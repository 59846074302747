import React from 'react';
import { Link } from 'react-router-dom';

export default function EnAibcare() {
  return (
    <>
      <div className="content_heading bg_service">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="tit_xl">AIBCare</h2>
              <ol className="breadcrumb">
                <li>Home</li>
                <li>Service</li>
                <li className="active">AIBCare</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="tit_underline">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h3 className="tit_lg">AIBCare</h3>
                <p className="desc">
                  <span>Go beyond one-time body temperature measurement </span>
                  <span>and manage non-face-to-face temperature changes with AIBCare</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="section bg_g">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="ratio ratio-16x9">
                  <iframe src="https://www.youtube.com/embed/geuOFNYdCfc" allowFullScreen></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section">
          <div className="container">
            <div className="row">
              <div className="col-12 tit_underline">
                <h3 className="tit_lg">AIBCare Service</h3>
                <p className="desc">Non-contact body temperature change management and artificial intelligence analysis service</p>
              </div>
              <div className="col-12">
                <img src="/images/aibcare.png" width="80%" alt="비접촉식 체온 변화관리" />
              </div>
            </div>
          </div>
        </div>
        <div className="section bg_g">
          <div className="container">
            <div className="row">
              <div className="col-12 tit_underline">
                <h3 className="tit_lg">AIBCare Service</h3>
                <p className="desc">
                  <span>AIBCare creates a safe and </span>
                  <span>comfortable environment in various fields</span>
                </p>
              </div>
              <div className="col-12 row">
                <div className="col-lg-3 col-md-6">
                  <img src="/images/aibcare_01.png" width="60%" alt="Office" />
                  <h4 className="tit_sm">Office</h4>
                </div>
                <div className="col-lg-3 col-md-6">
                  <img src="/images/aibcare_02.png" width="60%" alt="Cruise ship" />
                  <h4 className="tit_sm">Cruise ship</h4>
                </div>
                <div className="col-lg-3 col-md-6">
                  <img src="/images/aibcare_03.png" width="60%" alt="Manufacturing" />
                  <h4 className="tit_sm">Manufacturing</h4>
                </div>
                <div className="col-lg-3 col-md-6">
                  <img src="/images/aibcare_04.png" width="60%" alt="School" />
                  <h4 className="tit_sm">School</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
