import React from 'react';
import { Link } from 'react-router-dom';

export default function EnSmartOffice() {
  return (
    <>
      <div className="content_heading bg_product">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="tit_xl">Smart Office</h2>
              <ol className="breadcrumb">
                <li>Home</li>
                <li>Solution</li>
                <li className="active">Smart Office</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="tit_underline">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h3 className="tit_lg">Smart Office</h3>
                <p className="desc">IoT-based Smart Office Service</p>
              </div>
            </div>
          </div>
        </div>
        <div className="section bg_g">
          <div className="container">
            <div className="row">
              <div className="col-12 img_area">
                <img src="/images/main_img-office.jpg" alt="Smart Office" />
              </div>
              <div className="col-12 row text_area">
                <h4 className="col-lg-4 tit_md">Smart Office</h4>
                <p className="col-lg-8 desc">
                  Beacon-based automatic management of commuting time, mobile employee ID, unauthorized area access monitoring, smart meeting room reservation, office environment information monitoring, and whether to use the toilet to realize an intelligent
                  smart office.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="section landing">
          <div className="container">
            <div className="row">
              <div className="col-md-6 img_area">
                <img src="/images/office_landing_01.jpg" alt="Smart time and attendance management" />
              </div>
              <div className="col-md-6 text_area">
                <h4 className="tit_area">
                  <p className="numbering">
                    <span>01</span>
                    <small>Features</small>
                  </p>
                  <p className="tit_lg">Smart time and attendance management</p>
                </h4>
                <ul className="dash_list">
                  <li>Mobile employee ID</li>
                  <li>Beacon-based commuting management</li>
                  <li>Link to existing time and attendance management system</li>
                  <li>Mobile Service Delivery</li>
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 img_area">
                <img src="/images/office_landing_02__eng.jpg" alt="Smart collaboration" />
              </div>
              <div className="col-md-6 text_area">
                <h4 className="tit_area">
                  <p className="numbering">
                    <span>02</span>
                    <small>Features</small>
                  </p>
                  <p className="tit_lg">Smart collaboration</p>
                </h4>
                <ul className="dash_list">
                  <li>Provide recent location information of employees</li>
                  <li>Reservation</li>
                  <li>Meeting participation notification</li>
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 img_area">
                <img src="/images/office_landing_03.jpg" alt="Smart office environment" />
              </div>
              <div className="col-md-6 text_area">
                <h4 className="tit_area">
                  <p className="numbering">
                    <span>03</span>
                    <small>Features</small>
                  </p>
                  <p className="tit_lg">Smart office environment</p>
                </h4>
                <ul className="dash_list">
                  <li>Office environment information monitoring</li>
                  <li>Smart toilet service</li>
                  <li>Environmental Information Alarm Service</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="section bg_g">
          <div className="container">
            <div className="row">
              <div className="col-12 tit_underline">
                <h4 className="tit_md">Main function</h4>
              </div>
              <div className="col-lg-4 col-6">
                <div className="img_area">
                  <img src="/images/office-icon_01.png" width="20%" alt="Access attendance management" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">Access attendance management</h5>
                  <p className="desc">
                    Beacon-based automatic access/attendance <br />
                    check management, schedule and message interlocking when commuting
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-6">
                <div className="img_area">
                  <img src="/images/office-icon_02.png" width="20%" alt="Automatic control of meeting room environment" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">Automatic control of meeting room environment</h5>
                  <p className="desc">
                    Automatic control of facilities and <br />
                    automatic control of lighting and projectors when entering or leaving a conference room
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-6">
                <div className="img_area">
                  <img src="/images/office-icon_03.png" width="20%" alt="Meeting room reservation" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">Meeting room reservation</h5>
                  <p className="desc">Meeting room mobile app (reservation/attendance/notification/messenger), beacon-based attendance check</p>
                </div>
              </div>
              <div className="col-lg-4 col-6">
                <div className="img_area">
                  <img src="/images/office-icon_04.png" width="20%" alt="Work space environment management" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">Work space environment management</h5>
                  <p className="desc">
                    Maintain a comfortable office <br />
                    through interlocking <br />
                    environmental information
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-6">
                <div className="img_area">
                  <img src="/images/office-icon_05.png" width="20%" alt="Mobile Route-Based services" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">Mobile Route-Based services</h5>
                  <p className="desc">
                    Identifying the location <br />
                    and work patterns of Beacon-based <br />
                    workplaces
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-6">
                <div className="img_area">
                  <img src="/images/office-icon_06.png" width="20%" alt="Toilet usage management" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">Toilet usage management</h5>
                  <p className="desc">
                    Services to provide toilet <br />
                    availability using beacons, <br />
                    IR/contact sensors, etc.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-6">
                <div className="thumb_gradient">
                  <Link to="/en/case/smartOffice">
                    <img src="/images/case_office.png" alt="Smart Office" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-6">
                <div className="thumb_gradient">
                  <Link to="/en/case/smartHome">
                    <img src="/images/case_home.png" alt="Smart Home" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-6">
                <div className="thumb_gradient">
                  <Link to="/en/case/smartHealthcare">
                    <img src="/images/case_healthcare.png" alt="Smart Healthcare" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-6">
                <div className="thumb_gradient">
                  <Link to="/en/case/smartBuilding">
                    <img src="/images/case_building.png" alt="Smart Building" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-6">
                <div className="thumb_gradient">
                  <Link to="/en/case/smartCar">
                    <img src="/images/case_car.png" alt="Smart Car" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-6">
                <div className="thumb_gradient">
                  <Link to="/en/case/smartFactory">
                    <img src="/images/case_factory.png" alt="Smart Factory" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
