import React from 'react';
import { Link } from 'react-router-dom';

export default function EnSmartBuilding() {
  return (
    <>
      <div className="content_heading bg_product">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="tit_xl">Smart Building</h2>
              <ol className="breadcrumb">
                <li>Home</li>
                <li>Solution</li>
                <li className="active">Smart Building</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="tit_underline">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2 className="tit_lg">Smart Building</h2>
                <p className="desc">IoT-based smart building system</p>
              </div>
            </div>
          </div>
        </div>
        <div className="section bg_g">
          <div className="container">
            <div className="row">
              <div className="col-12 img_area">
                <img src="/images/main_img-building.jpg" alt="Smart Building" />
              </div>
              <div className="col-12 row text_area">
                <h3 className="col-lg-4 tit_md">Smart Building</h3>
                <p className="col-lg-8 desc">Integrate security, automatic control, and building management systems to enable smart building operations and management and reduce building operating costs.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="section landing">
          <div className="container">
            <div className="row">
              <div className="col-md-6 img_area">
                <img src="/images/building_landing_01.jpg" alt="Building automatic blind control service" />
              </div>
              <div className="col-md-6 text_area">
                <h4 className="tit_area">
                  <p className="numbering">
                    <span>01</span>
                    <small>Features</small>
                  </p>
                  <p className="tit_lg">Building automatic blind control service</p>
                </h4>
                <ul className="dash_list">
                  <li>Automatic control of in-building blind devices</li>
                  <li>Blind automatic control for in-office lighting, day lighting, security management</li>
                  <li>130 conference rooms can be controlled automatically with a single button</li>
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 img_area">
                <img src="/images/building_landing_02.jpg" alt="Building automatic temperature control service" />
              </div>
              <div className="col-md-6 text_area">
                <h4 className="tit_area">
                  <p className="numbering">
                    <span>02</span>
                    <small>Features</small>
                  </p>
                  <p className="tit_lg">Building automatic temperature control service</p>
                </h4>
                <ul className="dash_list">
                  <li>Support for maintaining a comfortable office environment by maintaining proper temperature and humidity</li>
                  <li>Automatic control function reduces operating costs</li>
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 img_area">
                <img src="/images/building_landing_03.jpg" alt="Convenient real-time monitoring" />
              </div>
              <div className="col-md-6 text_area">
                <h4 className="tit_area">
                  <p className="numbering">
                    <span>03</span>
                    <small>Features</small>
                  </p>
                  <p className="tit_lg">Convenient real-time monitoring</p>
                </h4>
                <ul className="dash_list">
                  <li>Control over 130 conference rooms simultaneously through the admin page</li>
                  <li>Near-field control via apps, tablets</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="section bg_g">
          <div className="container">
            <div className="row">
              <div className="col-12 tit_underline">
                <h2 className="tit_md">Main function</h2>
              </div>
              <div className="col-lg-3 col-6">
                <div className="img_area">
                  <img src="/images/building-icon_01.png" width="30%" alt="Real-time monitoring" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">Real-time monitoring</h5>
                  <p className="desc">
                    Convenient control <br />
                    via tablet, web and app
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="img_area">
                  <img src="/images/building-icon_02.png" width="30%" alt="Temperature and humidity control" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">Temperature and humidity control</h5>
                  <p className="desc">Pleasant working environment</p>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="img_area">
                  <img src="/images/building-icon_03.png" width="30%" alt="Automatic blind control" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">Automatic blind control</h5>
                  <p className="desc">One-button convenient control</p>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="img_area">
                  <img src="/images/building-icon_04.png" width="30%" alt="Building operation savings" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">Building operation savings</h5>
                  <p className="desc">Operational savings with automated systems</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-6">
                <div className="thumb_gradient">
                  <Link to="/en/case/smartOffice">
                    <img src="/images/case_office.png" alt="Smart Office" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-6">
                <div className="thumb_gradient">
                  <Link to="/en/case/smartHome">
                    <img src="/images/case_home.png" alt="Smart Home" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-6">
                <div className="thumb_gradient">
                  <Link to="/en/case/smartHealthcare">
                    <img src="/images/case_healthcare.png" alt="Smart Healthcare" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-6">
                <div className="thumb_gradient">
                  <Link to="/en/case/smartBuilding">
                    <img src="/images/case_building.png" alt="Smart Building" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-6">
                <div className="thumb_gradient">
                  <Link to="/en/case/smartCar">
                    <img src="/images/case_car.png" alt="Smart Car" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-6">
                <div className="thumb_gradient">
                  <Link to="/en/case/smartFactory">
                    <img src="/images/case_factory.png" alt="Smart Factory" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
