import React from 'react';
import { FaAngleDoubleDown } from 'react-icons/fa';
import { Link } from 'react-router-dom';

export default function SmartWater() {
  return (
    <>
      <div className="content_heading">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="tit_xl">스마트워터 AI 서비스용 학습데이터</h2>
              <ol className="breadcrumb">
                <li>Home</li>
                <li>서비스</li>
                <li className="active">스마트워터 AI 서비스용 학습데이터</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="section text-start">
          <div className="container">
            <div className="row">
              <h3 className="tit_lg">Ⅰ. 개요</h3>
              <div className="text_area">
                <h4 className="tit_md">1. 배경</h4>
                <ul className="check_list">
                  <li>민간정보에 AI 기술 개발을 활용한 데이터 처리 및 서비스 검증과 관련하여 수집/활용중인 데이터의 일부를 연구목적의 학습데이터로 처리하여 공개</li>
                  <li>공개 학습데이터를 활용하여 상수도 검침데이터를 활용한 AI 학습 모델 및 서비스 연구개발에 공익적으로 사용함으로써, 국가 연구개발에 기여</li>
                </ul>
              </div>
            </div>
            <div className="row">
              <h3 className="tit_lg">Ⅱ. 학습 데이터 셋</h3>
              <div className="text_area">
                <h4 className="tit_md">1. 공개 대상 데이터 셋</h4>
                <ul className="check_list">
                  <li>
                    지능형 생활편익 예측 서비스 시스템은 상수도 데이터와 같이 생활 밀착형 데이터를 수집, 전처리하여 생활에 편리한 AI 서비스를 제공하는 시스템으로, 학습데이터를 이용하여 AI 모델을 개발하고 이를 통해 진단/예측에 기반한 대시민 서비스를 제공함
                  </li>
                  <li>
                    본 스마트워터 서비스용 AI 학습데이터는 지능형 생활편익 예측 서비스 시스템에서 수집/활용 중인 데이터중, AI 학습 모델 개발에 활용되는 데이터셋 5종임
                    <table className="table_col">
                      <thead>
                        <tr>
                          <th>데이터 종류</th>
                          <th>데이터 명</th>
                          <th>설명</th>
                          <th>세부 항목</th>
                          <th>활용 방법</th>
                          <th>데이터 수</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="text-center">텍스트 (csv)</td>
                          <td>수용가 데이터</td>
                          <td>
                            <p>상수도 원격검침</p>
                            <p>수용가</p>
                          </td>
                          <td className="text-center">예시 참조</td>
                          <td>
                            <p>수용가별 학습 모델</p>
                            <p>개발에 활용</p>
                          </td>
                          <td>
                            <p>1,616건</p>
                          </td>
                        </tr>
                        <tr>
                          <td className="text-center">텍스트 (csv)</td>
                          <td>수전 데이터</td>
                          <td>
                            <p>수용가에 설치된</p>
                            <p>수전 정보</p>
                          </td>
                          <td className="text-center">예시 참조</td>
                          <td>
                            <p>수용가 , 수전을 고려한</p>
                            <p>학습모델 개발에 활용</p>
                          </td>
                          <td>
                            <p>1,616건</p>
                          </td>
                        </tr>
                        <tr>
                          <td className="text-center">텍스트 (csv)</td>
                          <td>월별 사용량 데이터</td>
                          <td>
                            <p>수용가의 과거 6 개월 월별</p>
                            <p>사용량 데이터</p>
                          </td>
                          <td className="text-center">예시 참조</td>
                          <td>
                            <p>수용가의 상수도 사용량</p>
                            <p>예측 모델 개발에 활용</p>
                          </td>
                          <td>
                            <p>9,696건 : 1,600</p>
                            <p>수용가 *6 개월</p>
                          </td>
                        </tr>
                        <tr>
                          <td className="text-center">텍스트 (csv)</td>
                          <td>원격 검침 데이터</td>
                          <td>
                            <p>수용가의 과거 6 개월</p>
                            <p>1 시간별 검침데이터</p>
                          </td>
                          <td className="text-center">예시 참조</td>
                          <td>
                            <p>수용가 대상 상수도 , 누수 , 독거자</p>
                            <p>이상 진단모델 개발에 활용</p>
                          </td>
                          <td>
                            <p>6,981,120건 :1,616</p>
                            <p>수용가, 24시간, 6 개월</p>
                          </td>
                        </tr>
                        <tr>
                          <td className="text-center">텍스트 (csv)</td>
                          <td>사용량 패턴 데이터</td>
                          <td>
                            <p>수용가의 과거 6 개월</p>
                            <p>주간 사용량 패턴 데이터</p>
                          </td>
                          <td className="text-center">예시 참조</td>
                          <td>
                            <p>수용가 특성을 반영한</p>
                            <p>학습 모델 개발에 활용</p>
                          </td>
                          <td>
                            <p>40,400건 : 1,616</p>
                            <p>수용가, 7일, 25주</p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </li>
                </ul>
              </div>
              <div className="text_area">
                <h4 className="tit_md">2. 학습데이터 명세</h4>
                <dl>
                  <dt>▶ 수용가 데이터</dt>
                  <dd>
                    <h5 className="tit_sm">설명</h5>
                    <ul className="check_list">
                      <li>수용가는 고객(customer)과 같은 의미이며, 각 지자체에서 상수도 서비스를 제공받고 요금을 부과하는 대상임</li>
                      <li>상수도에 대한 수용가별 AI 서비스 개발을 위해 필요한 수용가의 필요 속성만을 수용가 데이터에 포함함</li>
                      <li>개인정보 보호를 위해 실제 고객 번호는 가명처리 하였음</li>
                      <li>공개데이터 수는 1,616세대임</li>
                    </ul>
                  </dd>
                  <dd>
                    <h5 className="tit_sm">세부 속성</h5>
                    <ul className="check_list">
                      <li>속성 : SEQ(일련번호), CUSNUM(가명화된 고객번호), CGGCD(행정구역(구 단위) 코드, TMDCD(행정구역(행정동 단위) 코드),USEYN(사용 여부), RGSDT(등록 일자)</li>
                      <li>
                        예제 데이터
                        <table className="table_col text-center">
                          <thead>
                            <tr>
                              <th>SEQ</th>
                              <th>CUSNUM</th>
                              <th>CGGCD</th>
                              <th>TMDCD</th>
                              <th>USEYN</th>
                              <th>RGSDT</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>0</td>
                              <td>C10752282</td>
                              <td>1</td>
                              <td>1</td>
                              <td>Y</td>
                              <td>2021-10-12</td>
                            </tr>
                            <tr>
                              <td>1</td>
                              <td>A12179246</td>
                              <td>1</td>
                              <td>1</td>
                              <td>Y</td>
                              <td>2021-09-03</td>
                            </tr>
                            <tr>
                              <td>2</td>
                              <td>A87135147</td>
                              <td>1</td>
                              <td>1</td>
                              <td>Y</td>
                              <td>2021-09-03</td>
                            </tr>
                            <tr>
                              <td>3</td>
                              <td>A62131973</td>
                              <td>1</td>
                              <td>1</td>
                              <td>Y</td>
                              <td>2021-09-03</td>
                            </tr>
                            <tr>
                              <td>4</td>
                              <td>A87504218</td>
                              <td>1</td>
                              <td>1</td>
                              <td>Y</td>
                              <td>2021-09-03</td>
                            </tr>
                            <tr>
                              <td>5</td>
                              <td>C89945638</td>
                              <td>1</td>
                              <td>1</td>
                              <td>Y</td>
                              <td>2021-09-03</td>
                            </tr>
                            <tr>
                              <td>6</td>
                              <td>A56180015</td>
                              <td>1</td>
                              <td>1</td>
                              <td>Y</td>
                              <td>2021-09-03</td>
                            </tr>
                            <tr>
                              <td>7</td>
                              <td>A65751515</td>
                              <td>1</td>
                              <td>1</td>
                              <td>Y</td>
                              <td>2021-09-03</td>
                            </tr>
                            <tr>
                              <td>8</td>
                              <td>A37570139</td>
                              <td>1</td>
                              <td>1</td>
                              <td>Y</td>
                              <td>2021-09-03</td>
                            </tr>
                            <tr>
                              <td>9</td>
                              <td>C40889151</td>
                              <td>1</td>
                              <td>1</td>
                              <td>Y</td>
                              <td>2021-09-03</td>
                            </tr>
                            <tr>
                              <td>10</td>
                              <td>A38959250</td>
                              <td>1</td>
                              <td>1</td>
                              <td>Y</td>
                              <td>2021-09-03</td>
                            </tr>
                          </tbody>
                        </table>
                      </li>
                    </ul>
                  </dd>
                </dl>
                <dl>
                  <dt>▶ 수전 데이터</dt>
                  <dd>
                    <h5 className="tit_sm">설명</h5>
                    <ul className="check_list">
                      <li>수전(faucet/water tap)은 수용가가 상수도 서비스를 제공받기 위해 설치한 수도설비(수도 계량기)에 대한 정보임</li>
                      <li>상수도에 대한 수용가별 AI 서비스 개발을 위해 필요한 수용가 수전의 필요 속성만을 수전 데이터에 포함함</li>
                      <li>개인정보 보호를 위해 실제 수도 계량기 식별자인 기물 번호는 가명처리 하였음</li>
                      <li>공개데이터 수는 1,616세대 수전임</li>
                    </ul>
                  </dd>
                  <dd>
                    <h5 className="tit_sm">세부 속성</h5>
                    <ul className="check_list">
                      <li>속성 : SEQ(일련번호), CUSNUM(가명화된 고객번호), HDRCFCCD(상수도 구분 코드), WSMARTNUM(가명화된 기물번호), MTRPPSCD(배관 구경 코드), WSPDBTCD(상수도 업종코드))</li>
                      <li>
                        HDRCFCCD(상수도 구분 코드)는 상수도(100), 중수도(200), 지하수(300)으로 구분
                        <br />
                        MTRPPSCD(배관 구경 코드)는 계량기에 연결된 수도 배관의 구경으로 아래와 같이 정의함
                        <table className="table_col text-center">
                          <thead>
                            <tr>
                              <th>MTRPPSCD</th>
                              <th>구경(mm)</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>100</td>
                              <td>15</td>
                            </tr>
                            <tr>
                              <td>200</td>
                              <td>20</td>
                            </tr>
                            <tr>
                              <td>300</td>
                              <td>25</td>
                            </tr>
                            <tr>
                              <td>400</td>
                              <td>32</td>
                            </tr>
                            <tr>
                              <td>500</td>
                              <td>40</td>
                            </tr>
                            <tr>
                              <td>600</td>
                              <td>50</td>
                            </tr>
                            <tr>
                              <td>700</td>
                              <td>80</td>
                            </tr>
                            <tr>
                              <td>800</td>
                              <td>100</td>
                            </tr>
                            <tr>
                              <td>900</td>
                              <td>150</td>
                            </tr>
                            <tr>
                              <td>1000</td>
                              <td>200</td>
                            </tr>
                            <tr>
                              <td>1100</td>
                              <td>250</td>
                            </tr>
                            <tr>
                              <td>1200</td>
                              <td>300</td>
                            </tr>
                          </tbody>
                        </table>
                      </li>
                      <li>
                        WSPDBTCD(상수도 업종 코드)은 다음과 같이 정의함
                        <table className="table_col text-center">
                          <thead>
                            <tr>
                              <th>WSPDBTCD</th>
                              <th>업종 코드</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>0100</td>
                              <td>가정용</td>
                            </tr>
                            <tr>
                              <td>0101</td>
                              <td>가정(공용)</td>
                            </tr>
                            <tr>
                              <td>0102</td>
                              <td>가정(사회복지시설)</td>
                            </tr>
                            <tr>
                              <td>0200</td>
                              <td>일반용</td>
                            </tr>
                            <tr>
                              <td>0201</td>
                              <td>일반(소방시설)</td>
                            </tr>
                            <tr>
                              <td>0202</td>
                              <td>일반(학교)</td>
                            </tr>
                            <tr>
                              <td>0203</td>
                              <td>일반(특구)</td>
                            </tr>
                            <tr>
                              <td>0204</td>
                              <td>일반(수영장)</td>
                            </tr>
                            <tr>
                              <td>0205</td>
                              <td>일반(보육시설)</td>
                            </tr>
                            <tr>
                              <td>0206</td>
                              <td>일반(유치원)</td>
                            </tr>
                            <tr>
                              <td>0400</td>
                              <td>목욕용</td>
                            </tr>
                            <tr>
                              <td>0600</td>
                              <td>공업용</td>
                            </tr>
                            <tr>
                              <td>0601</td>
                              <td>공업(소방시설)</td>
                            </tr>
                          </tbody>
                        </table>
                      </li>
                      <li>
                        예제 데이터
                        <table className="table_col text-center">
                          <thead>
                            <tr>
                              <th>SEQ</th>
                              <th>CUSNUM</th>
                              <th>HDRCFCCD</th>
                              <th>WSMARTNUM</th>
                              <th>MTRPPSCD</th>
                              <th>WSPDBTCD</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>0</td>
                              <td>C10752282</td>
                              <td>100</td>
                              <td>EUY-1709118</td>
                              <td>100</td>
                              <td>200</td>
                            </tr>
                            <tr>
                              <td>1</td>
                              <td>A12179246</td>
                              <td>100</td>
                              <td>INV-1709122</td>
                              <td>100</td>
                              <td>200</td>
                            </tr>
                            <tr>
                              <td>2</td>
                              <td>A87135147</td>
                              <td>100</td>
                              <td>QGH-1708975</td>
                              <td>100</td>
                              <td>200</td>
                            </tr>
                            <tr>
                              <td>3</td>
                              <td>A62131973</td>
                              <td>100</td>
                              <td>OEE-1708963</td>
                              <td>100</td>
                              <td>200</td>
                            </tr>
                            <tr>
                              <td>4</td>
                              <td>A87504218</td>
                              <td>100</td>
                              <td>WHE-1709128</td>
                              <td>100</td>
                              <td>200</td>
                            </tr>
                            <tr>
                              <td>5</td>
                              <td>C89945638</td>
                              <td>100</td>
                              <td>BJL-1709113</td>
                              <td>100</td>
                              <td>200</td>
                            </tr>
                            <tr>
                              <td>6</td>
                              <td>A56180015</td>
                              <td>100</td>
                              <td>OVS-1709110</td>
                              <td>100</td>
                              <td>200</td>
                            </tr>
                            <tr>
                              <td>7</td>
                              <td>A65751515</td>
                              <td>100</td>
                              <td>HDL-1708956</td>
                              <td>100</td>
                              <td>200</td>
                            </tr>
                            <tr>
                              <td>8</td>
                              <td>A37570139</td>
                              <td>100</td>
                              <td>SES-1708954</td>
                              <td>100</td>
                              <td>200</td>
                            </tr>
                            <tr>
                              <td>9</td>
                              <td>C40889151</td>
                              <td>100</td>
                              <td>ZWD-1708965</td>
                              <td>100</td>
                              <td>200</td>
                            </tr>
                            <tr>
                              <td>10</td>
                              <td>A38959250</td>
                              <td>100</td>
                              <td>SZF-1708957</td>
                              <td>100</td>
                              <td>200</td>
                            </tr>
                          </tbody>
                        </table>
                      </li>
                    </ul>
                  </dd>
                </dl>
                <dl>
                  <dt>▶ 원격 검침 데이터</dt>
                  <dd>
                    <h5 className="tit_sm">설명</h5>
                    <ul className="check_list">
                      <li>원격검침 데이터는 수용가의 디지털 계량기로부터 주기적으로 통신망을 통해 수집된 데이터임</li>
                      <li>검침 주기는 지자체별로 상이할 수 있으며, 본 데이터는 1시간 단위 검침데이터를 포함함</li>
                      <li>공개데이터 수는 대상 수용가별로 1일 24회, 6개월 간의 검침 데이터임</li>
                    </ul>
                  </dd>
                  <dd>
                    <h5 className="tit_sm">세부 속성</h5>
                    <ul className="check_list">
                      <li>속성 : MTR_TIME(검침시간), CUSNUM: 고객번호, WSMARTNUM: 기물번호, MTR_READ: 검침량(톤)</li>
                      <li>개인정보 보호를 위해 실제 고객 번호는 가명처리 하였음</li>
                      <li>
                        예제 데이터
                        <table className="table_col text-center">
                          <thead>
                            <tr>
                              <th>MTR_TIME</th>
                              <th>CUSNUM</th>
                              <th>WSMARTNUM</th>
                              <th>MTR_READ</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>2022-01-01 0:00</td>
                              <td>C10752282</td>
                              <td>EUY-1709118</td>
                              <td>0</td>
                            </tr>
                            <tr>
                              <td>2022-01-01 1:00</td>
                              <td>C10752282</td>
                              <td>EUY-1709118</td>
                              <td>0.014</td>
                            </tr>
                            <tr>
                              <td>2022-01-01 2:00</td>
                              <td>C10752282</td>
                              <td>EUY-1709118</td>
                              <td>0.024</td>
                            </tr>
                            <tr>
                              <td>2022-01-01 3:00</td>
                              <td>C10752282</td>
                              <td>EUY-1709118</td>
                              <td>0.030</td>
                            </tr>
                            <tr>
                              <td>2022-01-01 4:00</td>
                              <td>C10752282</td>
                              <td>EUY-1709118</td>
                              <td>0.044</td>
                            </tr>
                            <tr>
                              <td>2022-01-01 5:00</td>
                              <td>C10752282</td>
                              <td>EUY-1709118</td>
                              <td>0.047</td>
                            </tr>
                            <tr>
                              <td>2022-01-01 6:00</td>
                              <td>C10752282</td>
                              <td>EUY-1709118</td>
                              <td>0.060</td>
                            </tr>
                            <tr>
                              <td>2022-01-01 7:00</td>
                              <td>C10752282</td>
                              <td>EUY-1709118</td>
                              <td>0.064</td>
                            </tr>
                            <tr>
                              <td>2022-01-01 8:00</td>
                              <td>C10752282</td>
                              <td>EUY-1709118</td>
                              <td>0.078</td>
                            </tr>
                          </tbody>
                        </table>
                      </li>
                    </ul>
                  </dd>
                </dl>
                <dl>
                  <dt>▶ 사용량 패턴 데이터</dt>
                  <dd>
                    <h5 className="tit_sm">설명</h5>
                    <ul className="check_list">
                      <li>사용량 패턴데이터는 수용가의 주별로 처리된 사용량 데이터임</li>
                      <li>개인정보 보호를 위해 실제 고객 번호, 기물 번호는 가명처리 하였음</li>
                      <li>공개데이터 수는 수용가별 6개월간의 주별 데이터임</li>
                    </ul>
                  </dd>
                  <dd>
                    <h5 className="tit_sm">세부 속성</h5>
                    <ul className="check_list">
                      <li>속성 : WEEK(검침 주간 단위), CUSNUM: 고객번호, WSMARTNUM: 기물번호, DAY_0 ~ DAY_6: 연속적인 요일</li>
                      <li>
                        예제 데이터
                        <table className="table_col text-center">
                          <thead>
                            <tr>
                              <th></th>
                              <th>CUSNUM</th>
                              <th>WSMARTNUM</th>
                              <th>DAY_0</th>
                              <th>DAY_1</th>
                              <th>DAY_2</th>
                              <th>DAY_3</th>
                              <th>DAY_4</th>
                              <th>DAY_5</th>
                              <th>DAY_6</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>0</td>
                              <td>A10090619</td>
                              <td>QYT-1702302</td>
                              <td>0.738</td>
                              <td>0.697</td>
                              <td>0.682</td>
                              <td>0.626</td>
                              <td>0.641</td>
                              <td>0.702</td>
                              <td>0.683</td>
                            </tr>
                            <tr>
                              <td>1</td>
                              <td>A10090619</td>
                              <td>QYT-1702302</td>
                              <td>0.638</td>
                              <td>0.623</td>
                              <td>0.721</td>
                              <td>0.805</td>
                              <td>0.725</td>
                              <td>0.64</td>
                              <td>0.742</td>
                            </tr>
                            <tr>
                              <td>2</td>
                              <td>A10090619</td>
                              <td>QYT-1702302</td>
                              <td>0.823</td>
                              <td>0.925</td>
                              <td>0.721</td>
                              <td>0.769</td>
                              <td>0.731</td>
                              <td>0.656</td>
                              <td>0.665</td>
                            </tr>
                            <tr>
                              <td>3</td>
                              <td>A10090619</td>
                              <td>QYT-1702302</td>
                              <td>0.723</td>
                              <td>0.992</td>
                              <td>0.704</td>
                              <td>0.745</td>
                              <td>0.874</td>
                              <td>0.671</td>
                              <td>0.691</td>
                            </tr>
                            <tr>
                              <td>4</td>
                              <td>A10090619</td>
                              <td>QYT-1702302</td>
                              <td>0.757</td>
                              <td>0.821</td>
                              <td>0.892</td>
                              <td>1.534</td>
                              <td>1.126</td>
                              <td>1.062</td>
                              <td>1.207</td>
                            </tr>
                            <tr>
                              <td>5</td>
                              <td>A10090619</td>
                              <td>QYT-1702302</td>
                              <td>1.236</td>
                              <td>1.411</td>
                              <td>1.247</td>
                              <td>1.214</td>
                              <td>1.186</td>
                              <td>1.442</td>
                              <td>1.365</td>
                            </tr>
                            <tr>
                              <td>6</td>
                              <td>A10090619</td>
                              <td>QYT-1702302</td>
                              <td>1.263</td>
                              <td>1.289</td>
                              <td>1.45</td>
                              <td>1.1</td>
                              <td>1.212</td>
                              <td>1.208</td>
                              <td>1.185</td>
                            </tr>
                            <tr>
                              <td>7</td>
                              <td>A10090619</td>
                              <td>QYT-1702302</td>
                              <td>1.438</td>
                              <td>1.272</td>
                              <td>1.034</td>
                              <td>1.011</td>
                              <td>1.009</td>
                              <td>1.304</td>
                              <td>1.004</td>
                            </tr>
                            <tr>
                              <td>8</td>
                              <td>A10090619</td>
                              <td>QYT-1702302</td>
                              <td>1.21</td>
                              <td>1.21</td>
                              <td>1.268</td>
                              <td>0.75</td>
                              <td>0.696</td>
                              <td>0.649</td>
                              <td>0.654</td>
                            </tr>
                          </tbody>
                        </table>
                      </li>
                    </ul>
                  </dd>
                </dl>
              </div>
            </div>
            <div className="row">
              <h3 className="tit_lg">Ⅲ. 학습 데이터 활용</h3>
              <div className="text_area">
                <h3 className="tit_md">1. 개요</h3>
                <ul className="check_list">
                  <li>본 학습데이터를 활용한 AI 학습모델로는 사용량/누진예측, 누수/독거자 이상진단, 동파 예측 및 상권 진단 등임</li>
                  <li>본 학습데이터는 AI 모델 학습/재학습, 모델 성능 검증 그리고 모델을 통한 진단/예측 서빙(serving) 등에 다양하게 활용 가능함</li>
                </ul>
              </div>
              <div className="text_area">
                <h3 className="tit_md">2. 사용량 및 누진 예측 모델 개발</h3>
                <dl>
                  <dd>
                    <h5 className="tit_sm">모델 개요</h5>
                    <ul className="check_list">
                      <li>과거 실시간 검침 데이터, 수용가 특성 등을 사용하여 각 수용가별 단기/장기 사용량과 월말 누진 단계를 예측하는 모델임</li>
                      <li>시계열 데이터의 이상치 처리, 패턴화와 같은 전처리 단계를 거친 후 RNN계열 예측모델을 사용하여 사용량과 누진 단계를 예측 가능함</li>
                    </ul>
                  </dd>
                </dl>
                <dl>
                  <dd>
                    <h5 className="tit_sm">활용 방법</h5>
                    <img src="/images/waterflow_01.png" alt="사용량/누진 예측 모델 흐름 예시" />
                    <ul className="check_list">
                      <li>실시간 검침량 : 시간 단위 사용량을 1일(24시간)단위로 변환</li>
                      <li>수용가 정보 : 수용가의 특성에 따른 모델 구축을 위해 업종코드 추출</li>
                      <li>기상 데이터 : 최고&최저 온도, 강수량, 습도 등의 외부 기상데이터 활용</li>
                      <li>사용량 예측 : 과거 데이터의 활용 범위에 따라 향후 단기(1일), 장기(1주일) 수용가별 사용량을 예측</li>
                      <li>누진 단계 예측 : 사용량 예측결과를 토대로 해당 월말의 누진세 단계 및 요금 예측</li>
                    </ul>
                  </dd>
                </dl>
                <dl>
                  <dd>
                    <h5 className="tit_sm">참고 사항</h5>
                    <ul className="check_list">
                      <li>순환 신경망(RNN)은 LSTM(Long-Short Term Memory), GRU(Gated Recurrent Units) 등 다양한 모델이 존재하며 모델에 따라 성능의 차이가 있을 수 있음</li>
                      <li>정확도 평가 :사용량 예측모델은 예측 사용량과 실제 사용량 사이의 차이를 계산하는 MAPE, 누진 단계 예측의 경우 Accuracy를 적용 가능</li>
                    </ul>
                  </dd>
                </dl>
              </div>
              <div className="text_area">
                <h3 className="tit_md">3. 독거자/누수 이상 모델 개발</h3>
                <dl>
                  <dd>
                    <h5 className="tit_sm">모델 개요</h5>
                    <ul className="check_list">
                      <li>실시간 검침 데이터, 기상 데이터 등을 활용하여 독거자의 이상 상황, 누수 발생 등을 예측하는 모델</li>
                      <li>이상치에 대한 데이터들이 충분히 주어지지 않았을 경우, 비지도학습 중 오토인코더(AutoEncoder)를 활용한 예측으로 진행 가능</li>
                    </ul>
                  </dd>
                </dl>
                <dl>
                  <dd>
                    <h5 className="tit_sm">활용 방법</h5>
                    <img src="/images/waterflow_02.png" alt="독거자/누수 이상 진단 모델 흐름 예시" style={{ marginBottom: '20px' }} />
                    <ul className="check_list">
                      <li>실시간 검침량 : 시간 단위 사용량을 시간평균, 일 평균 사용량으로 변환</li>
                      <li>기상 데이터 : 최고&최저 온도, 강수량, 습도 등의 외부 기상데이터 활용</li>
                      <li>학습용 데이터셋 전처리 : 데이터 분석에 따른 검침 데이터 변동 정의 및 독거자 및 누수의 진단 주기 정의</li>
                    </ul>
                  </dd>
                </dl>
                <dl>
                  <dd>
                    <h5 className="tit_sm">참고 사항</h5>
                    <ul className="check_list">
                      <li>오토인코더(AutoEncoder)는 Vanila Autoencoder(AE), Linear Autoencoder 등 다양한 모델이 존재하며 모델에 따라 성능의 차이가 있을 수 있음</li>
                      <li>정확도 평가 : 이상치 진단의 경우 Accuracy를 활용하여 정확도를 판별 가능</li>
                    </ul>
                  </dd>
                </dl>
              </div>
              <div className="text_area">
                <h3 className="tit_md">4. 동파 예측 모델 개발</h3>
                <dl>
                  <dd>
                    <h5 className="tit_sm">모델 개요</h5>
                    <ul className="check_list">
                      <li>실시간 검침량과 기상 데이터(외부 데이터)를 결합하여 야간 계량기 동파 발생 위험성을 예측하는 모델</li>
                      <li>실시간 검침량을 통해 가구별 사용 패턴을 산출, 당일 특정 시점까지의 사용량을 기반으로 패턴 매칭으로 야간 사용량을 추론, 최종적으로 기상 데이터와 결합하여 계량기 동파 발생 가능성 예측 수행</li>
                    </ul>
                  </dd>
                </dl>
                <dl>
                  <dd>
                    <h5 className="tit_sm">활용 방법</h5>
                    <img src="/images/waterflow_03.png" alt="동파 예측 모델 흐름 예시" />
                    <ul className="check_list">
                      <li>실시간 검침량 : 주간 사용량 기반으로 야간 사용량을 추론하기 위해 24시간 단위로 패턴화</li>
                      <li>검침기 정보 : 동파 발생에 영향을 미치는 배관 넓이 정보 추출</li>
                      <li>기상 데이터 : 동파 발생에 직접적으로 영향을 미치는 요소로, 필수적으로 수집 및 활용 필요</li>
                      <li>동파 예측 기준 : 검침량 패턴 클러스터링 및 배관 넓이 별, 기온 별 동파 예측 레벨 다각화</li>
                      <li>동파 예측 수행 : 주간 실시간 검침량, 야간 최저 기온 정보를 수집하여 동파 예측 기준에 따른 판단 수행</li>
                    </ul>
                  </dd>
                </dl>
                <dl>
                  <dd>
                    <h5 className="tit_sm">참고 사항</h5>
                    <ul className="check_list">
                      <li>정확도 평가 : 주간 실시간 검침량을 기반으로 패턴 예측 결과 나온 야간 검침량과 실제 야간 검침량간의 유사성 기반 정확도 평가 가능</li>
                      <li>검침량 패턴화는 일정 주기에 따라 다시 수행하여 최신 사용 패턴을 반영할 수 있도록 하여야 함</li>
                    </ul>
                  </dd>
                </dl>
              </div>
              <div className="text_area">
                <h3 className="tit_md">5. 상권 진단 모델 개발</h3>
                <dl>
                  <dd>
                    <h5 className="tit_sm">모델 개요</h5>
                    <ul className="check_list">
                      <li>동일 지역에 속한 수용가들의 사용량 패턴 데이터와 월별 사용량 데이터를 기반으로 지역 내 일정 기간의 전체 사용량을 추론하여 이를 기반으로 상수도 사용량의 변동을 파악하여 해당 지역 상권 상태의 변화를 진단하는 모델</li>
                      <li>지역 내 일정 기간의 상수도 사용량 및 외부 데이터(유동인구, 거주인구, 기상)를 활용하여 상권 활성화 상태 진단 및 예측 수행 가능</li>
                    </ul>
                  </dd>
                </dl>
                <dl>
                  <dd>
                    <h5 className="tit_sm">활용 방법</h5>
                    <img src="/images/waterflow_04.png" alt="상권 진단 모델 흐름 예시" />
                    <ul className="check_list">
                      <li>사용량 패턴 : 월별 사용량을 사용량 패턴을 활용하여 상권 진단 주기에 맞게 변환</li>
                      <li>기상/인구/유동인구 데이터 : 상권 변동에 영향을 미칠 수 있는 요소로 선택적으로 수집 및 활용</li>
                      <li>
                        상권 진단 : 상권 진단 주기별 사용량에 따라 클러스터링 기반 상권 상태 진단 수행
                        <br />
                        <span className="text-small">** 상권 상태 분류 예시 : 상승, 활성, 유지, 하강, 침체</span>
                      </li>
                      <li>상권 예측 : 지역 내 사용량을 기준으로, 기상/인구/유동인구 데이터를 활용하여 다음 상권 진단 주기의 상권 상태를 예측</li>
                    </ul>
                  </dd>
                </dl>
                <dl>
                  <dd>
                    <h5 className="tit_sm">참고 사항</h5>
                    <ul className="check_list">
                      <li>정확도 평가 : 상권 진단의 경우 클러스터링 기반의 진단이므로 정확도 평가는 고려하지 않으며, 상권 예측의 경우에는 Accuracy를 기준으로 예측 정확도 평가</li>
                      <li>실시간 검침량을 통해 기존 월별 사용량을 선형 회귀나 이동 평균 등 예측 기법을 사용하여 실시간 사용량을 추론하여 실시간 상권 진단으로 사용할 수도 있음</li>
                    </ul>
                  </dd>
                </dl>
              </div>
            </div>
            <div className="row">
              <h3 className="tit_lg">Ⅳ. 학습 데이터 공개</h3>
              <div className="text_area">
                <h3 className="tit_md">1. 학습데이터 다운로드</h3>
                <ul className="check_list flex-row">
                  <li>
                    수용가 데이터
                    <a href="https://drive.google.com/file/d/1dVzBSGVMxS9JUd_rXb_PqDqyq_JpNWfo/view?usp=share_link" target="_blank" className="btn btn_sm btn_primary">
                      <strong>다운로드</strong>
                      <FaAngleDoubleDown />
                    </a>
                  </li>
                  <li>
                    수전 데이터
                    <a href="https://drive.google.com/file/d/1wpyr10J_ql7CDMN0mTimpSOM7HuJk0j0/view?usp=share_link" target="_blank" className="btn btn_sm btn_primary">
                      <strong>다운로드</strong>
                      <FaAngleDoubleDown />
                    </a>
                  </li>
                  <li>
                    월별 사용량 데이터
                    <a href="https://drive.google.com/file/d/1RQB7aBm3nM80cS5w3MmLnGcjssKHgvu7/view?usp=share_link" target="_blank" className="btn btn_sm btn_primary">
                      <strong>다운로드</strong>
                      <FaAngleDoubleDown />
                    </a>
                  </li>
                  <li>
                    원격 검침 데이터
                    <a href="https://drive.google.com/file/d/1MO42DcY1utLzre8d_Bumi8d1OIUY80Mt/view?usp=share_link" target="_blank" className="btn btn_sm btn_primary">
                      <strong>다운로드</strong>
                      <FaAngleDoubleDown />
                    </a>
                  </li>
                  <li>
                    사용량 패턴 데이터
                    <a href="https://drive.google.com/file/d/1uDD56cKiAqmM0d_y1WfkFoOo8pcvM2Kt/view?usp=share_link" target="_blank" className="btn btn_sm btn_primary">
                      <strong>다운로드</strong>
                      <FaAngleDoubleDown />
                    </a>
                  </li>
                </ul>
              </div>
              <div className="text_area">
                <h3 className="tit_md">2. 학습데이터 활용 문의</h3>
                <ul className="check_list">
                  <li>융합기술 연구소/ DnA팀</li>
                  <li>이메일 : junux@aiblab.co.kr</li>
                  <li>전화번호 : 070-8892-7165</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
