import React from 'react';
import { useEffect } from 'react';
import styles from './Popup.module.css';

export default function Popup({ visible, closable, onClose }) {
  // 하루동안 팝업 닫기
  const dayClose = (e) => {
    if (onClose) {
      onClose(e);
      const expiry = new Date();
      const expiryDate = expiry.getDate() + 1; // +1일 계산
      localStorage.setItem('VisitCookie', expiryDate); // 로컬스토리지 저장
    }
  };

  const close = (e) => {
    if (onClose) {
      onClose(e);
    }
  };

  const VISITED_BEFORE_DATE = localStorage.getItem('VisitCookie'); // 이전방문 날짜
  const VISITED_NOW_DATE = Math.floor(new Date().getDate()); // 현재 날짜

  useEffect(() => {
    // 오늘 하루동안 보지 않기 체크
    if (VISITED_BEFORE_DATE !== null) {
      if (VISITED_BEFORE_DATE === VISITED_NOW_DATE) {
        localStorage.removeItem('VisitCookie');
        onClose(true);
      }
      if (VISITED_BEFORE_DATE !== VISITED_NOW_DATE) {
        onClose(false);
      }
    }
  }, [VISITED_BEFORE_DATE]);

  return (
    <div className={styles.popup_container} visible={visible}>
      <div className={styles.popup_body}>
        <img src="/images/popup/popup_230421.png" alt="팝업 컨텐츠" />
      </div>
      <div className={styles.popup_footer}>
        {closable && (
          <>
            <button type="button" onClick={dayClose}>
              오늘 하루동안 보지 않기
            </button>
            <button type="button" onClick={close}>
              닫기
            </button>
          </>
        )}
      </div>
    </div>
  );
}
