import React from 'react';
import { Link } from 'react-router-dom';

export default function EnVision() {
  return (
    <>
      <div className="content_heading bg_introduce">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="tit_xl">Vision</h2>
              <ol className="breadcrumb">
                <li>Home</li>
                <li>AIBLab</li>
                <li className="active">Vision</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="section">
          <div className="container">
            <div className="row">
              <div className="col-12 tit_underline">
                <h2 className="tit_lg">Vision</h2>
                <p className="desc">
                  <span>Big data analysis, artificial intelligence, </span>
                  <span>IoT technology innovation company that enriches future life</span>
                </p>
              </div>
              <div className="col-md-4">
                <div className="thumb_gradient circle">
                  <p>
                    <img src="/images/vision_ai.jpg" alt="AI" />
                  </p>
                </div>
                <div className="summary">
                  <h3 className="tit_sm">AI</h3>
                  <div className="desc">
                    <p>
                      <span>AIBLab Artificial Intelligence </span>
                      <span>It provides the technology of expert system </span>
                    </p>
                    <p>for efficient management and provides the function of automatic design.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="thumb_gradient circle">
                  <p>
                    <img src="/images/vision_iot.jpg" alt="IoT" />
                  </p>
                </div>
                <div className="summary">
                  <h3 className="tit_sm">IoT</h3>
                  <div className="desc">
                    <p>
                      <span>Real-time sensing for easy use by users </span>
                      <span>We can provide information services </span>
                      <span>and conduct service business </span>
                    </p>
                    <p>utilizing various devices.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="thumb_gradient circle">
                  <p>
                    <img src="/images/vision_bigdata.jpg" alt="Bigdata" />
                  </p>
                </div>
                <div className="summary">
                  <h3 className="tit_sm">Bigdata</h3>
                  <div className="desc">
                    <p>
                      <span>AIBLap data shows that people’s behavior is </span>
                      <span>Of course, even location information </span>
                    </p>
                    <p>and thoughts and opinions via SNS You can analyze and predict.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <img src="/images/main_img-vision.jpg" alt="vision" />
                <p className="desc">We will create the highest added value by utilizing artificial intelligence technology, IoT platform basic technology and experience of solution construction in various fields.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
