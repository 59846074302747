import React from 'react';
import { Link } from 'react-router-dom';

export default function EnMaintenance() {
  return (
    <>
      <div className="content_heading bg_product">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="tit_xl">IT·Operation service</h2>
              <ol className="breadcrumb">
                <li>Home</li>
                <li>Service</li>
                <li className="active">IT·Operation service</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="tit_underline">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h3 className="tit_lg">IT·Operation service</h3>
                <p className="desc">AIBLab professional operation service</p>
              </div>
            </div>
          </div>
        </div>
        <div className="section bg_g">
          <div className="container">
            <div className="row">
              <div className="col-12 img_area">
                <img src="/images/main_img-maintenance.jpg" alt="IT·Operation service" />
              </div>
              <div className="col-12 row text_area">
                <h4 className="col-lg-4 tit_md">IT·Operation service</h4>
                <p className="col-lg-8 desc">
                  We provide AI/IoT-based IT maintenance services and professional IT solutions. <br />
                  We will improve our customers' business and increase corporate value and competitiveness through professional technology.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="section landing">
          <div className="container">
            <div className="row">
              <div className="col-md-6 img_area">
                <img src="/images/maintenance_landing_01.png" alt="SK telink" />
              </div>
              <div className="col-md-6 text_area">
                <h4 className="tit_area">
                  <p className="numbering">
                    <span>01</span>
                    <small>Features</small>
                  </p>
                  <p className="tit_lg">SK telink</p>
                </h4>
                <ul className="dash_list">
                  <li>SK7mobile website construction and maintenance</li>
                  <li>SK7mobile mobile service construction and maintenance</li>
                  <li>SK 00700 mobile service construction and maintenance</li>
                  <li>SK telink event </li>
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 img_area">
                <img src="/images/maintenance_landing_02.png" alt="Handy ICS" />
              </div>
              <div className="col-md-6 text_area">
                <h4 className="tit_area">
                  <p className="numbering">
                    <span>02</span>
                    <small>Features</small>
                  </p>
                  <p className="tit_lg">Handy ICS</p>
                </h4>
                <ul className="dash_list">
                  <li>Handysoft groupware maintenance</li>
                  <li>Ondago system provided</li>
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 img_area">
                <img src="/images/maintenance_landing_03.png" alt="Web, mobile service" />
              </div>
              <div className="col-md-6 text_area">
                <h4 className="tit_area">
                  <p className="numbering">
                    <span>03</span>
                    <small>Features</small>
                  </p>
                  <p className="tit_lg">Web, mobile service</p>
                </h4>
                <ul className="dash_list">
                  <li>Amano Korea mobile EIS system provided</li>
                  <li>OTD/Thingul mobile EIS system provision </li>
                  <li>Provides Seorabeol city gas mobile meter reading system</li>
                  <li>Provides CESCO mobile groupware system</li>
                  <li>KDB Development Bank mobile groupware system provision</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="section bg_g">
          <div className="container">
            <div className="row">
              <div className="col-12 tit_underline">
                <h4 className="tit_md">Main service</h4>
              </div>
              <div className="col-md-4">
                <div className="img_area">
                  <img src="/images/maintenance-icon_01.png" width="20%" alt="App, Web operation service" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">App, Web operation service</h5>
                  <p className="desc">
                    <span>Operate/maintain applications </span>
                    <span>and web systems with AI/IoT expertise</span>
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="img_area">
                  <img src="/images/maintenance-icon_02.png" width="20%" alt="AI/IoT/Bigdata" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">AI/IoT/Bigdata-based operation</h5>
                  <p className="desc">
                    <span>World-class technology </span>
                    <span>using AI/IoT/Bigdata technology</span>
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="img_area">
                  <img src="/images/maintenance-icon_03.png" width="20%" alt="Premium service" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">Premium service</h5>
                  <p className="desc">
                    <span>Analysis and consulting customized </span>
                    <span>service reflecting the latest trends</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
