import React from 'react';
import { Link } from 'react-router-dom';

export default function SmartCar() {
  return (
    <>
      <div className="content_heading bg_product">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="tit_xl">스마트 차량관리</h2>
              <ol className="breadcrumb">
                <li>Home</li>
                <li>솔루션</li>
                <li className="active">스마트 차량관리</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="tit_underline">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2 className="tit_lg">Smart Car</h2>
                <p className="desc">같은 차, 새로운 경험 당신의 차를 더욱 똑똑하게!</p>
              </div>
            </div>
          </div>
        </div>
        <div className="section bg_g">
          <div className="container">
            <div className="row">
              <div className="col-12 img_area">
                <img src="/images/main_img-car.jpg" alt="Smart Car" />
              </div>
              <div className="col-12 row text_area">
                <h3 className="col-lg-4 tit_md">Smart Car</h3>
                <p className="col-lg-8 desc">아이브랩과 함께 하는 편리하고 스마트한 카 라이프. 아이브랩은 당신에게 필요한 커넥티드카 서비스의 핵심 기능을 제공합니다.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="section landing">
          <div className="container">
            <div className="row">
              <div className="col-md-6 img_area">
                <img src="/images/car_landing_01.jpg" alt="운전습관 분석" />
              </div>
              <div className="col-md-6 text_area">
                <h4 className="tit_area">
                  <p className="numbering">
                    <span>01</span>
                    <small>Features</small>
                  </p>
                  <p className="tit_lg">에코 드라이빙을 위한 운전습관 분석</p>
                </h4>
                <ul className="dash_list">
                  <li>급가속, 급정거 등의 정보를 통한 나의 운전습관 점검</li>
                  <li>드라이빙 스코어 제공</li>
                  <li>연비 관리</li>
                  <li>NO₂배출량 점검 (지원 예정)</li>
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 img_area">
                <img src="/images/car_landing_02.jpg" alt="차량 진단" />
              </div>
              <div className="col-md-6 text_area">
                <h4 className="tit_area">
                  <p className="numbering">
                    <span>02</span>
                    <small>Features</small>
                  </p>
                  <p className="tit_lg">당신의 가상 정비사, 차량 진단 기능</p>
                </h4>
                <ul className="dash_list">
                  <li>차량 고장 진단 및 고장 이력 조회</li>
                  <li>소모품 교체 주기 알람</li>
                  <li>차량 원격 진단 및 정비소 연계 (지원 예정)</li>
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 img_area">
                <img src="/images/car_landing_03.jpg" alt="실시간 차량 위치 추적" />
              </div>
              <div className="col-md-6 text_area">
                <h4 className="tit_area">
                  <p className="numbering">
                    <span>03</span>
                    <small>Features</small>
                  </p>
                  <p className="tit_lg">실시간 차량 위치 추적</p>
                </h4>
                <ul className="dash_list">
                  <li>차량의 현재 위치 조회</li>
                  <li>도난 방지 알림</li>
                  <li>차량 사용 중인 가족의 위치 파악 및 안전 확인</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="section bg_g">
          <div className="container">
            <div className="row">
              <div className="col-12 tit_underline">
                <h2 className="tit_md">주요기능</h2>
              </div>
              <div className="col-lg-3 col-6">
                <div className="img_area">
                  <img src="/images/car-icon_01.png" width="30%" alt="상태 모니터링" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">상태 모니터링</h5>
                  <p className="desc">
                    차량 고장 진단 및 소모품 <br />
                    교체 주기 알람
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="img_area">
                  <img src="/images/car-icon_02.png" width="30%" alt="사고감지" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">사고감지</h5>
                  <p className="desc">
                    차량 사고 감지 및 <br />
                    긴급상황 지원
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="img_area">
                  <img src="/images/car-icon_03.png" width="30%" alt="도난추적" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">도난추적</h5>
                  <p className="desc">도난차량 위치 추적 기능</p>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="img_area">
                  <img src="/images/car-icon_04.png" width="30%" alt="긴급출동" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">긴급출동</h5>
                  <p className="desc">
                    고장차 지원을 위한 <br />
                    긴급출동 서비스
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="img_area">
                  <img src="/images/car-icon_05.png" width="30%" alt="운전습관" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">운전습관</h5>
                  <p className="desc">운전습관 상세 분석</p>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="img_area">
                  <img src="/images/car-icon_06.png" width="30%" alt="맞춤형 혜택" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">맞춤형 혜택</h5>
                  <p className="desc">운전자 맞춤형 서비스 혜택 제시</p>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="img_area">
                  <img src="/images/car-icon_07.png" width="30%" alt="차량 가치" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">차량 가치</h5>
                  <p className="desc">
                    차량 진단 및 이력 조회를 통해 <br />
                    정확한 차량 가치 파악
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="img_area">
                  <img src="/images/car-icon_08.png" width="30%" alt="차량 위치" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">차량 위치</h5>
                  <p className="desc">차량위치 및 운행 이력 조회</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-6">
                <div className="thumb_gradient">
                  <Link to="/case/smartOffice">
                    <img src="/images/case_office.png" alt="스마트 오피스" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-6">
                <div className="thumb_gradient">
                  <Link to="/case/smartHome">
                    <img src="/images/case_home.png" alt="스마트 홈" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-6">
                <div className="thumb_gradient">
                  <Link to="/case/smartHealthcare">
                    <img src="/images/case_healthcare.png" alt="스마트 헬스케어" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-6">
                <div className="thumb_gradient">
                  <Link to="/case/smartBuilding">
                    <img src="/images/case_building.png" alt="스마트 빌딩" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-6">
                <div className="thumb_gradient">
                  <Link to="/case/smartCar">
                    <img src="/images/case_car.png" alt="스마트 카" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-6">
                <div className="thumb_gradient">
                  <Link to="/case/smartFactory">
                    <img src="/images/case_factory.png" alt="스마트 팩토리" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
