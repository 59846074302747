import React from 'react';
import { Link } from 'react-router-dom';

export default function Maintenance() {
  return (
    <>
      <div className="content_heading bg_product">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="tit_xl">IT·운영서비스</h2>
              <ol className="breadcrumb">
                <li>Home</li>
                <li>서비스</li>
                <li className="active">IT·운영서비스</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="tit_underline">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h3 className="tit_lg">IT·운영서비스</h3>
                <p className="desc">아이브랩 전문 운영서비스</p>
              </div>
            </div>
          </div>
        </div>
        <div className="section bg_g">
          <div className="container">
            <div className="row">
              <div className="col-12 img_area">
                <img src="/images/main_img-maintenance.jpg" alt="IT·운영서비스" />
              </div>
              <div className="col-12 row text_area">
                <h4 className="col-lg-4 tit_md">IT·운영서비스</h4>
                <p className="col-lg-8 desc">AI/IoT 기반의 IT 유지보수 서비스를 진행하고 전문 IT 솔루션을 제공합니다. 전문 기술력을 통해 고객의 비즈니스를 향상시키고 기업가치와 경쟁력을 높여 드립니다.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="section landing">
          <div className="container">
            <div className="row">
              <div className="col-md-6 img_area">
                <img src="/images/maintenance_landing_01.png" alt="SK 텔링크 스크린샷" />
              </div>
              <div className="col-md-6 text_area">
                <h4 className="tit_area">
                  <p className="numbering">
                    <span>01</span>
                    <small>Features</small>
                  </p>
                  <p className="tit_lg">SK 텔링크</p>
                </h4>
                <ul className="dash_list">
                  <li>SK7mobile 웹사이트 구축 및 유지보수</li>
                  <li>SK7mobile 모바일 서비스 구축 및 유지보수</li>
                  <li>SK 00700 모바일 서비스 구축 및 유지 보수</li>
                  <li>SK 텔링크 이벤트 </li>
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 img_area">
                <img src="/images/maintenance_landing_02.png" alt="핸디 ICS 스크린샷" />
              </div>
              <div className="col-md-6 text_area">
                <h4 className="tit_area">
                  <p className="numbering">
                    <span>02</span>
                    <small>Features</small>
                  </p>
                  <p className="tit_lg">핸디 ICS</p>
                </h4>
                <ul className="dash_list">
                  <li>핸디소프트 그룹웨어 유지보수</li>
                  <li>온다고 시스템 제공</li>
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 img_area">
                <img src="/images/maintenance_landing_03.png" alt="웹, 모바일 서비스 스크린샷" />
              </div>
              <div className="col-md-6 text_area">
                <h4 className="tit_area">
                  <p className="numbering">
                    <span>03</span>
                    <small>Features</small>
                  </p>
                  <p className="tit_lg">웹, 모바일 서비스</p>
                </h4>
                <ul className="dash_list">
                  <li>아마노코리아 모바일 EIS 시스템 제공</li>
                  <li>OTD/띵굴 모바일 EIS 시스템 제공 </li>
                  <li>서라벌 도시가스 모바일 검침 시스템 제공</li>
                  <li>세스코 모바일 그룹웨어 시스템 제공</li>
                  <li>KDB 산업은행 모바일 그룹웨어 시스템 제공</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="section bg_g">
          <div className="container">
            <div className="row">
              <div className="col-12 tit_underline">
                <h4 className="tit_md">주요 서비스</h4>
              </div>
              <div className="col-md-4">
                <div className="img_area">
                  <img src="/images/maintenance-icon_01.png" width="20%" alt="App, Web 운영서비스" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">App, Web 운영서비스</h5>
                  <p className="desc">
                    <span>AI/IoT 전문 기술력으로</span>
                    <span>애플리케이션, 웹 시스템을 운영/유지</span>
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="img_area">
                  <img src="/images/maintenance-icon_02.png" width="20%" alt="AI/IoT/Bigdata" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">AI/IoT/Bigdata 기반 운영 서비스</h5>
                  <p className="desc">
                    <span>AI/IoT/Bigdata 기술을 활용한</span>
                    <span>세계적인 기술의 유지보수</span>
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="img_area">
                  <img src="/images/maintenance-icon_03.png" width="20%" alt="프리미엄 서비스" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">프리미엄 서비스</h5>
                  <p className="desc">
                    <span>최신 트랜드를 반영한 분석 및 컨설팅</span>
                    <span>고객 맞춤형 서비스</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
