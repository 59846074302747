import React from 'react';
import { Link } from 'react-router-dom';

export default function Culture() {
  return (
    <>
      <div className="content_heading bg_crew">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="tit_xl">아이브랩 문화</h2>
              <ol className="breadcrumb">
                <li>Home</li>
                <li>채용</li>
                <li className="active">아이브랩 문화</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="section bg_g">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="tit_underline">
                  <h2 className="tit_lg">아이브랩 문화</h2>
                  <p className="desc">아이브랩 인재는 업무에 최선을 다할 뿐만 아니라, 최고의 결과를 보여드립니다.</p>
                </div>
              </div>
            </div>
            <div className="row text-start">
              <div className="col-xl-3">
                <h3 className="tit_sm">1. 아이디어는 누구나!</h3>
                <p className="border_box desc">누구나 아이디어를 제시할 수 있으며 더 좋은 업무방식과 회사생활을 위하여 의견을 공유합니다.</p>
              </div>
              <div className="col-xl-3">
                <h3 className="tit_sm">2. 유연한 근무제도</h3>
                <p className="border_box desc">9시에서 9시 30분 전 후의 유연한 출퇴근으로 자신의 업무에 책임을 다하고 업무의 결과로 평가합니다.</p>
              </div>
              <div className="col-xl-3">
                <h3 className="tit_sm">3. 일정은 철저히!</h3>
                <p className="border_box desc">업무의 일정은 중요한 부분으로 일정은 철저히 지키며, 지연 될 때에는 미리 회의를 통해 상의합니다.</p>
              </div>
              <div className="col-xl-3">
                <h3 className="tit_sm">4. 수평적 문화</h3>
                <p className="border_box desc">수직적인 문화가 아닌 수평적 문화로 서로의 의견을 존중하며 누구나 발언할 수 있습니다.</p>
              </div>
              <div className="col-xl-3">
                <h3 className="tit_sm">5. 배려하는 문화</h3>
                <p className="border_box desc">직원들은 서로의 업무방식이 다르고 소통하는 방법이 다르기에 존중하고 배려하며 업무를 진행합니다.</p>
              </div>
              <div className="col-xl-3">
                <h3 className="tit_sm">6. 평등한 기회</h3>
                <p className="border_box desc">성별, 나이, 연차에 관계없이 실력에 따라 평등한 기회가 주어지는 문화를 지향합니다.</p>
              </div>
              <div className="col-xl-3">
                <h3 className="tit_sm">7. 빠르게 실행해보기</h3>
                <p className="border_box desc">해보지 않은 일은 누구도 알 수 없습니다. 완벽하지 않아도 최선의 결정을 내리고 빠르게 실행합니다.</p>
              </div>
              <div className="col-xl-3">
                <h3 className="tit_sm">8. 정직한 문화</h3>
                <p className="border_box desc">정직한 보고, 거래 기록의 법규를 준수하고 부정방지를 피하며 회사 내 법규 및 윤리강령을 준수합니다.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="section">
          <div className="tit_underline">
            <h2 className="tit_lg">아이브랩 복지</h2>
            <p className="desc">아이브랩은 여러 복지를 통해 즐거운 회사생활을 만들어갑니다.</p>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-12 border_box_x">
                <h3 className="tit_sm fw-bold">기본 복리후생</h3>
                <ul className="circle_list">
                  <li>연금·보험: 국민연금, 고용보험, 산재보험, 건강보험</li>
                  <li>주5일근무, 연차, 월차, 정기휴가, 반차, Refresh휴가</li>
                  <li>퇴직금, 명절 선물</li>
                  <li>중식, 석식, 회식비 지원</li>
                  <li>건강검진</li>
                </ul>
              </div>
              <div className="col-12 border_box_x">
                <h3 className="tit_sm fw-bold">든든한 아이브랩</h3>
                <ul className="circle_list">
                  <li>매 달 간식 지원</li>
                  <li>명절 선물 지급</li>
                  <li>자녀 학자금 지원</li>
                  <li>1년에 한번 제주도 워크샵</li>
                  <li>경조휴가, 부조금, 경조화환 지원</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
