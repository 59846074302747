import React from 'react';
import { Link } from 'react-router-dom';

export default function Vision() {
  return (
    <>
      <div className="content_heading bg_introduce">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="tit_xl">기업비전</h2>
              <ol className="breadcrumb">
                <li>Home</li>
                <li>회사소개</li>
                <li className="active">기업비전</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="section">
          <div className="container">
            <div className="row">
              <div className="col-12 tit_underline">
                <h2 className="tit_lg">아이브랩 비전</h2>
                <p className="desc">
                  <span>미래의 삶을 풍요롭게 만드는 </span>
                  <span>빅데이터분석, 인공지능, 사물인터넷 기술혁신기업</span>
                </p>
              </div>
              <div className="col-md-4">
                <div className="thumb_gradient circle">
                  <p>
                    <img src="/images/vision_ai.jpg" alt="AI" />
                  </p>
                </div>
                <div className="summary">
                  <h3 className="tit_sm">AI</h3>
                  <div className="desc">
                    <p>
                      <span>아이브랩 인공지능은 거대한 공간정보의</span>
                      <span>효율적 관리를 위한 전문가 체계기법을 제공하며 </span>
                    </p>
                    <p>자동설계의 기능을 제공합니다.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="thumb_gradient circle">
                  <p>
                    <img src="/images/vision_iot.jpg" alt="IoT" />
                  </p>
                </div>
                <div className="summary">
                  <h3 className="tit_sm">IoT</h3>
                  <div className="desc">
                    <p>
                      <span>사용자가 쉽게 이용할 수 있도록</span>
                      <span>실시간 센싱 정보 서비스를 제공하며 </span>
                      <span>다양한 디바이스를 활용한 </span>
                    </p>
                    <p>서비스 사업을 수행할 수 있습니다.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="thumb_gradient circle">
                  <p>
                    <img src="/images/vision_bigdata.jpg" alt="Bigdata" />
                  </p>
                </div>
                <div className="summary">
                  <h3 className="tit_sm">Bigdata</h3>
                  <div className="desc">
                    <p>
                      <span>아이브랩의 데이터는 사람들의 행동은</span>
                      <span>물론 위치정보와 SNS를 통해 생각과 의견까지 </span>
                    </p>
                    <p>분석하고 예측할 수 있습니다.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <img src="/images/main_img-vision.jpg" alt="비전 이미지" />
                <p className="desc">인공지능 기술, IoT 플랫폼 원천 기술과 다양한 분야에서의 솔루션 구축경험을 활용하여 최고의 부가가치를 만들어 내겠습니다.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
