import React from 'react';
import { BiDownload, BiEditAlt } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import { useLangContext } from '../../context/AppContext';
import styles from './QuickMenu.module.css';

export default function QuickMenu() {
  const { lang } = useLangContext();

  return (
    <ul className={styles.quick_menu}>
      <li>
        <a href="/file/AIBLab소개서(202307).pdf" download={true} title="회사소개서 다운로드">
          <BiDownload />
        </a>
      </li>
      <li>
        <Link to={lang === 'ko-KR' ? '/etc/ask' : '/en/etc/ask'} title="문의하기">
          <BiEditAlt />
        </Link>
      </li>
    </ul>
  );
}
