import { createContext, useContext, useState } from 'react';

const AppContext = createContext();

export function AppProvider({ children }) {
  const [loading, setLoading] = useState(false);
  const [popupVisible, setPopupVisible] = useState(false);
  const [lang, setLang] = useState('ko-KR');

  const value = {
    isLoading: { loading, setLoading },
    isVisiblePopup: { popupVisible, setPopupVisible },
    handleLang: { lang, setLang },
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}
// 앱 최상단에 <AppProvider></AppProvider> 로 감싸면
// useContext로 만들어진 함수를 원하는 위치에서 임포트하여 사용 가능

export const useLoadingContext = () => useContext(AppContext).isLoading;
export const usePopupContext = () => useContext(AppContext).isVisiblePopup;
export const useLangContext = () => useContext(AppContext).handleLang;
