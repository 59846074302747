import React from 'react';
import { Link } from 'react-router-dom';

export default function AiBigdata() {
  return (
    <>
      <div className="content_heading bg_product">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="tit_xl">AI·Bigdata Platform</h2>
              <ol className="breadcrumb">
                <li>Home</li>
                <li>AIBLab Platform</li>
                <li className="active">AI·Bigdata Platform</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="tit_underline">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h3 className="tit_lg">AI·Bigdata Platform</h3>
                <p className="desc">
                  <span>사물인터넷, 빅데이터, 기계학습 및 </span>
                  <span>도메인 지식을 융합한 AIBLab의 인공지능 플랫폼</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="section bg_g">
          <div className="container">
            <div className="row">
              <div className="col-12 img_area">
                <img src="/images/main_img-aibigdata.jpg" alt="AI Big data Platform" />
              </div>
              <div className="col-12 row text_area">
                <h4 className="col-lg-4 tit_md">AI·Bigdata Platform</h4>
                <p className="col-lg-8 desc">아이브랩의 ALA Platform은 쉽고 빠르게 End-To-End 인공지능 상용 서비스 개발을 통해 보다 편리한 서비스를 지원합니다.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="section">
          <div className="container">
            <div className="row">
              <div className="col-12 tit_underline">
                <h4 className="tit_md">제품규격</h4>
                <p className="desc">아이브랩의 ALA Platform 제품 규격 사항입니다.</p>
              </div>
              <div className="col-12 img_area">
                <img src="/images/aibigdata_flow.png" alt="제품규격" />
              </div>
              <div className="col-12 row">
                <div className="col-md-6 text_area">
                  <h5 className="tit_sm">ALA component-based services</h5>
                  <ul className="circle_list">
                    <li>ALA 컴퍼넌트 규격을 만족하는 컴퍼넌트를 이용한 응용 시나리오 탑재 및 실행</li>
                  </ul>
                </div>
                <div className="col-md-6 text_area">
                  <h5 className="tit_sm">ALA composable components</h5>
                  <ul className="circle_list">
                    <li>도메인 공통의 동적구성 가능한 컴퍼넌트 모듈</li>
                    <li>데이터/분석/서빙 컴퍼넌트</li>
                  </ul>
                </div>
                <div className="col-md-6 text_area">
                  <h5 className="tit_sm">ALA Core Framework</h5>
                  <ul className="circle_list">
                    <li>클라언트 요청에 따른 워크플로우 실행제어 및 스케쥴링</li>
                    <li>워크플로우 실행 엔진을 구성 및 실행</li>
                    <li>공통 엔진 규격 및 메시지 규격</li>
                    <li>데이터/모델/워크플로우 저장소</li>
                  </ul>
                </div>
                <div className="col-md-6 text_area">
                  <h5 className="tit_sm">ALA 클라이언트 및 개발자 SDK</h5>
                  <ul className="circle_list">
                    <li>ALA 응용 시나리오 개발을 위한 웹기반 클라이언트 모듈</li>
                    <li>Scala 기반의 ALA 클라이언트 모듈 및 개발자 SDK</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section bg_g">
          <div className="container">
            <div className="row">
              <div className="col-12 tit_underline">
                <h4 className="tit_md">주요기능</h4>
              </div>
              <div className="col-lg-3 col-6">
                <div className="img_area">
                  <img src="/images/aibigdata-icon_01.png" alt="근태 예측/분석" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">근태 예측/분석</h5>
                  <p className="desc">AI를 통해 예측 및 분석이 가능</p>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="img_area">
                  <img src="/images/aibigdata-icon_02.png" alt="환경 모니터링" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">환경 모니터링</h5>
                  <p className="desc">환경 모니터링의 정확성이 높아짐</p>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="img_area">
                  <img src="/images/aibigdata-icon_03.png" alt="사용량 분석" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">사용량 분석</h5>
                  <p className="desc">확한 사용량 분석이 체계적으로 보여짐</p>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="img_area">
                  <img src="/images/aibigdata-icon_04.png" alt="지식 제공" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">지식 제공</h5>
                  <p className="desc">지식 제공에 따른 편리함의 증대</p>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="img_area">
                  <img src="/images/aibigdata-icon_05.png" alt="개인화 알림" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">개인화 알림</h5>
                  <p className="desc">알림 기능을 통한 체계적인 관리</p>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="img_area">
                  <img src="/images/aibigdata-icon_06.png" alt="인공지능/ML/DL 엔진" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">인공지능/ML/DL 엔진</h5>
                  <p className="desc">
                    본데이터, 데이터처리, 비식별 <br />
                    처리를 통한 학습 및 예측이 가능
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="img_area">
                  <img src="/images/aibigdata-icon_07.png" alt="데이터 수집/저장" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">데이터 수집/저장</h5>
                  <p className="desc">
                    데이터 수집과 저장을 통해 <br />
                    편리한 서비스를 사용 가능
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="img_area">
                  <img src="/images/aibigdata-icon_08.png" alt="센서/디바이스 관리" />
                </div>
                <div className="summary">
                  <h5 className="tit_sm">센서/디바이스 관리</h5>
                  <p className="desc">
                    센서와 디바이스를 관리하여 <br />
                    업무 효율성의 향상
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section">
          <div className="container">
            <div className="row">
              <div className="col-12 tit_underline">
                <h4 className="tit_md">구축사례</h4>
                <div className="desc">
                  <p>AI·Bigdata 제품 구축사례입니다.</p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="thumb_gradient">
                  <Link to="/service/aibworks" state={{ activeTab: 'aibworks' }}>
                    <img src="/images/case_commute.png" alt="스마트 오피스" />
                  </Link>
                </div>
              </div>
              <div className="col-md-4">
                <div className="thumb_gradient">
                  <Link to="/service/aibworks" state={{ activeTab: 'smartToilet' }}>
                    <img src="/images/case_toilet.png" alt="스마트 화장실" />
                  </Link>
                </div>
              </div>
              <div className="col-md-4">
                <div className="thumb_gradient">
                  <Link to="/service/aibworks" state={{ activeTab: 'monitoringEnv' }}>
                    <img src="/images/case_environment.png" alt="환경정보 모니터링" />
                  </Link>
                </div>
              </div>
              <div className="col-md-4">
                <div className="thumb_gradient">
                  <p>
                    <img src="/images/case_waterflow.png" alt="상수도 분석 서비스" />
                  </p>
                </div>
                <div className="text_area">
                  <h5 className="tit_sm">대전시 상수도 분석 서비스</h5>
                  <ul className="circle_list">
                    <li>빅데이터를 활용해 대전광역시 상수도 관리 </li>
                    <li>상수관 누수 및 사용량 예측 모델 개발 완료</li>
                    <li>복합 딥러닝 기법을 활용한 예측기술 개발</li>
                    <li>타지자체 적용 및 검증을 지속적으로 확대 예정</li>
                  </ul>
                </div>
              </div>
              <div className="col-md-4">
                <div className="thumb_gradient">
                  <p>
                    <img src="/images/case_smartcity.png" alt="스마트시티" />
                  </p>
                </div>
                <div className="text_area">
                  <h5 className="tit_sm">부산 스마트시티 빅데이터 서비스 설계 </h5>
                  <ul className="circle_list">
                    <li>실증서비스 관련 빅데이터 POC 서비스 도출</li>
                    <li>스마트시티 IoT 플랫폼 기반의 빅데이터 모듈 설계</li>
                    <li>빅데이터 적용 POC 구축 및 확대방안 수립</li>
                    <li>POC 서비스 고도화 및 최적화</li>
                  </ul>
                </div>
              </div>
              <div className="col-md-4">
                <div className="thumb_gradient">
                  <p>
                    <img src="/images/case_kamp.png" alt="인공지능 제조 플랫폼" />
                  </p>
                </div>
                <div className="text_area">
                  <h5 className="tit_sm">인공지능 제조 플랫폼(KAMP) 서비스 </h5>
                  <ul className="circle_list">
                    <li>AI 스마트공장화와 AI 플랫폼개발 </li>
                    <li>수집·저장·분석 플랫폼, AI전문가, 실증서비스 관리</li>
                    <li>CNC, 머신비전등의 센서에서 측정되는 데이터 관리</li>
                    <li>정규화·표준화 할 수 있는 표준기반의 기술 개발</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section pt-0">
          <div className="container">
            <div className="row">
              <div className="col-12 tit_underline">
                <h4 className="tit_md">주요 고객사</h4>
                <div className="desc">
                  <p>아이브랩 AI·Bidata 플랫폼 주요 고객사입니다.</p>
                </div>
              </div>
              <div className="col-12">
                <div id="aiPartnerCarousel" className="carousel slide" data-bs-ride="true">
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <ul className="d-flex">
                        <li>
                          <img src="/images/partners/handysoft.jpg" alt="핸디소프트" />
                        </li>
                        <li>
                          <img src="/images/partners/dasannetworks.png" alt="다산네트웍스" />
                        </li>
                        <li>
                          <img src="/images/partners/daejeon.png" alt="대전광역시 상수도사업본부" />
                        </li>
                        <li>
                          <img src="/images/partners/busan.png" alt="부산광역시" />
                        </li>
                      </ul>
                    </div>
                    <div className="carousel-item">
                      <ul className="d-flex">
                        <li>
                          <img src="/images/partners/gyeongnam.png" alt="경상남도" />
                        </li>
                        <li>
                          <img src="/images/partners/mss.png" alt="중소벤처기업부" />
                        </li>
                      </ul>
                    </div>
                  </div>
                  <ul className="carousel-indicators">
                    <li data-bs-target="#aiPartnerCarousel" data-bs-slide-to="0" className="active"></li>
                    <li data-bs-target="#aiPartnerCarousel" data-bs-slide-to="1"></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
