import React from 'react';
import { useLoadingContext } from '../../context/AppContext';
import styles from './loading.module.css';

export default function Loading() {
  const { loading } = useLoadingContext();

  return (
    loading && (
      <div className={styles.loading}>
        <div className={styles.rotate_ring}></div>
      </div>
    )
  );
}
