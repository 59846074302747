import React from 'react';
import { Link } from 'react-router-dom';

export default function Greeting() {
  return (
    <>
      <div className="content_heading bg_introduce">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="tit_xl">CEO Greeting</h2>
              <ol className="breadcrumb">
                <li>Home</li>
                <li>회사소개</li>
                <li className="active">CEO Greeting</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="section">
          <div className="container">
            <div className="row">
              <div className="col-12 tit_underline">
                <h2 className="tit_lg">CEO Greeting</h2>
                <p className="desc">아이브랩 대표이사 인사말</p>
              </div>
              <div className="col-12">
                <img src="/images/main_img-greeting.jpg" alt="대표이사 인사말 이미지" />
                <div className="desc">
                  <p>
                    ㈜아이브랩은 4차산업혁명의 핵심 기술인 인공지능, 사물인터넷, 빅데이터 기술을 활용하여 현재의 삶이 더욱더 풍요로워지고 더 나은 미래의 가치를 만들어 가도록 노력하고 있습니다. 임직원 모두가 노력하여 최고의 제품과 가치있는 서비스 창출하여
                    인류사회에 공헌하는 것이 아이브랩이 추구하는 최종목표입니다.
                  </p>
                  <p>이를 위해서, 최고의 인재를 육성하며 기술우위를 확보하고 최고의 제품과 가치있는 서비스를 창출하여 현재의 삶을 윤택하게 하고 미래 인류의 번영에 기여하겠습니다.</p>
                </div>
                <p className="remark">
                  주식회사 아이브랩 대표이사 <strong>서영주, 김용우</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
